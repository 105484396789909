import React, { Component } from 'react';

import Page from 'components/Page';

import Top from './sections/Top';
import Video from './sections/Video';
import AtelierSection from 'src/pages/About/sections/Atelier';
import Feature from './sections/Feature';
import Social from './sections/Social';
import Form from './sections/Form';

export default class Home extends Component {
  render() {
    return (
      <Page>
        <Video />
        <Top />
        <AtelierSection />
        <Feature />
        <Social />
        <Form />
      </Page>
    );
  }
}
