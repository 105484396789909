import React, { Component } from 'react';
import styled from 'styled-components';
import scrollToElement from 'scroll-to-element';

import Button from 'components/Button';
import Spacing from 'components/Spacing';

export default class CTA extends Component {
  handleClick = () => {
    scrollToElement('#atelier-form');
  };

  render() {
    return (
      <Root>
        <Spacing value={60} />
        <h3>
          See Brent Comber Work at Gryphon Musée
          <br />
          Join our mailing list for more details
        </h3>
        <Spacing value={40} />
        <Button onClick={this.handleClick}>Keep me informed</Button>
        <Spacing value={40} />
      </Root>
    );
  }
}

const Root = styled.div`
  text-align: center;
  h3 {
    font-family: 'Gotham';
    font-weight: normal;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.primary};
    line-height: 1.65;
  }
`;
