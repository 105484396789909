import React, { Component } from 'react';
import styled from 'styled-components';
import scrollToElement from 'scroll-to-element';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import VideoPlayer from 'components/VideoPlayer';
import Spacing from 'components/Spacing';

// import Social from '../../sections/Social';

export default class VideoSection extends Component {
  state = {
    showVideo: false,
  };

  handleClickPlay = () => {
    this.setState({
      showVideo: true,
    });
  };

  constructor(props) {
    super(props);
    this.$videoPlayer = React.createRef();
  }

  handleClickWatchVideoText = () => {
    this.$videoPlayer.current.play();
    scrollToElement('#atelier-video-player');
  };

  componentDidMount() {}

  render() {
    return (
      <>
        <WatchVideo>
          <p onClick={this.handleClickWatchVideoText}>Now Featuring</p>
          <Spacing value={5} />
          <h3>Brent Comber</h3>
          <Spacing value={60} mobile={50} />
        </WatchVideo>
        <Root id="atelier-video-player" abTest={this.props.abTest}>
          <VideoPlayer
            url="https://player.vimeo.com/video/338353605"
            background={require('src/assets/images/atelier/video_thumb.jpg')}
            videoPlayerRef={this.$videoPlayer}
            ref={this.$videoPlayer}
          />
          <SocialContainer>{/* <Social noSpacing /> */}</SocialContainer>
        </Root>
      </>
    );
  }
}

const Root = styled.div`
  &::after {
    content: '';
    display: ${props => (props.abTest ? 'none' : 'block')};
    position: absolute;
    left: 50%;
    bottom: ${vw(0)};
    transform: translateX(-50%) translateY(50%);
    width: ${vw(1)};
    min-width: 1px;
    height: ${vw(80)};
    background: ${({ theme }) => theme.color.primary};
    margin: 0 auto;
    margin-top: ${vw(15)};
  }
  @media (max-width: ${media.md}) {
    &::after {
      display: none;
    }
  }
`;

const WatchVideo = styled.div`
  text-transform: uppercase;
  text-align: center;
  position: relative;
  z-index: 9999;
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: ${vw(45)};
    transform: translateX(-50%);
    width: ${vw(1)};
    min-width: 1px;
    height: ${vw(110)};
    background: ${({ theme }) => theme.color.primary};
    margin: 0 auto;
    margin-top: ${vw(15)};
  }

  p {
    color: #92a8b6;
    font-size: ${vw(13)};
    letter-spacing: ${vw(2)};
  }
  h3 {
    font-size: ${vw(18)};
    font-weight: 500;
    letter-spacing: ${vw(3)};
  }
  @media (max-width: ${media.md}) {
    p {
      font-size: ${vwMobile(13)};
      letter-spacing: ${vwMobile(2)};
    }
    h3 {
      font-size: ${vwMobile(18)};
      letter-spacing: ${vwMobile(3)};
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: ${vwMobile(40)};
      transform: translateX(-50%);
      width: ${vwMobile(1)};
      min-width: 1px;
      height: ${vwMobile(65)};
      background: ${({ theme }) => theme.color.primary};
      margin: 0 auto;
      margin-top: ${vwMobile(15)};
    }
  }
`;

const SocialContainer = styled.div`
  position: absolute;
  bottom: -7%;
  right: 2%;
  @media (max-width: ${media.md}) {
    display: none;
  }
`;
