import React, { Component } from 'react';
import styled from 'styled-components';
import scrollToElement from 'scroll-to-element';

import sendData from 'src/utils/sendData';

import Container from 'components/Container';
import GradientText from 'components/GradientText';
import Spacing from 'components/Spacing';
import Button from 'components/Button';
import Input from 'components/Form/elements/Input';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

export default class FormSection extends Component {
  state = {
    formSuccess: false,
  };

  constructor(props) {
    super(props);
    this.$nameField = React.createRef();
    this.$emailField = React.createRef();
  }

  handleSubmitForm = () => {
    this.setState({ formSuccess: true });
    scrollToElement('#atelier-form');
    window.gtag('event', 'Sign Up', {
      event_category: 'CampaignRegistration',
      event_label: 'General_Event_Sign_Up',
      value: 1,
    });

    sendData(this.$nameField.current.value, this.$emailField.current.value);
  };

  render() {
    return (
      <>
        <Root>
          <Container>
            <iframe
              title="atelierdummyframe"
              width="0"
              height="0"
              border="0"
              name="atelierdummyframe"
              id="atelierdummyframe"
              style={{
                width: 0,
                height: 0,
                display: 'block',
                visibility: 'hidden',
                margin: 0,
                position: 'absolute',
              }}
            />
            <Form
              id="atelier-form"
              accept-charset="UTF-8"
              method="post"
              target="atelierdummyframe"
              action="https://spark.re/gryphon-development/gryphon/register/atelier_registration"
              // action="https://bamcommunications.ca/test"
              onSubmit={this.handleSubmitForm}
            >
              <input name="utf8" type="hidden" value="&#x2713;" />
              <input
                type="hidden"
                name="source"
                id="source"
                value="General Signup - Atelier Campaign - Brent Comber"
              />
              <input
                type="hidden"
                name="redirect_success"
                id="redirect_success"
                value=""
              />
              <input
                type="hidden"
                name="redirect_error"
                id="redirect_error"
                value=""
              />
              <input
                type="text"
                name="are_you_simulated"
                id="are_you_simulated"
                placeholder="Leave this field blank"
                style={{ display: 'none' }}
              />
              <input
                type="hidden"
                name="answers[5906][answers]"
                value="General Events"
              />
              <Content>
                <Spacing value={80} />
                {(this.state.formSuccess || this.props.isFormModalSuccess) && (
                  <>
                    <h2>
                      <GradientText>
                        Thank you for joining our mailing list.
                      </GradientText>
                    </h2>
                    <Spacing value={30} mobile={30} />
                    <p>
                      We deeply appreciate your interest in Gryphon Atelier. We
                      will keep you informed about our art initiatives and
                      upcoming events.
                    </p>
                  </>
                )}
                <FormWrapper show={!this.state.formSuccess}>
                  <h2>
                    <GradientText>
                      Be the first to know about our next events and
                      collaborations.
                    </GradientText>
                  </h2>
                  <Spacing value={80} mobile={30} />
                  <Input
                    name="contact[first_name]"
                    placeholder="Name *"
                    required
                    refDOM={this.$nameField}
                  />
                  <Input
                    name="contact[email]"
                    type="email"
                    placeholder="Email *"
                    required
                    refDOM={this.$emailField}
                  />
                  <Spacing value={25} />
                  <Button type="submit" fill>
                    Join List
                  </Button>
                </FormWrapper>

                <Spacing value={100} mobile={80} />
                <Social>
                  <GradientText>Follow Us</GradientText>
                  <a
                    href="https://www.facebook.com/gryphondevelopmentca/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require('src/assets/images/icons/facebook.svg')}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/gryphondevelopment/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require('src/assets/images/icons/instagram.svg')}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://twitter.com/gryphon_dev"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require('src/assets/images/icons/twitter.svg')}
                      alt=""
                    />
                  </a>
                </Social>
                <Spacing value={60} />
              </Content>
              <FeaturedImageDesktop
                src={require('src/assets/images/atelier/gryphon.jpg')}
                alt=""
              />
              <FeaturedVideo>
                <video
                  src="https://player.vimeo.com/external/338351028.hd.mp4?s=0cec7cddf3af24b13ad460f4414a2c1e081414e1&profile_id=175"
                  loop
                  autoPlay
                  muted
                  playsInline
                />
              </FeaturedVideo>
            </Form>
          </Container>
        </Root>
        <FeaturedImage
          src={require('src/assets/images/atelier/gryphon.jpg')}
          alt=""
        />
      </>
    );
  }
}

const Root = styled.div`
  background: #ccc;
  background: url(${require('src/assets/images/atelier/form-bg.jpg')});
  overflow: hidden;
  background-size: cover;
  &::after {
    width: 100%;
    height: 1px;
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    background: ${({ theme }) => theme.color.background};
  }
  @media (max-width: ${media.md}) {
  }
`;

const Form = styled.form`
  width: ${vw(375)};
  /* background: rgba(16, 30, 39, 0.9); */
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: -5px;
    bottom: -5px;
    right: 0;
    background: rgba(16, 30, 39, 0.9);
    width: 50vw;
    filter: blur(3px);
  }
  h2 {
    font-weight: 500;
    font-size: ${vw(40)};
  }
  input {
    display: block;
    width: 80%;
    margin-bottom: ${vw(15)};
  }
  @media (max-width: ${media.md}) {
    width: 100%;
    text-align: center;
    &::before {
      width: 200vw;
      left: -50%;
      right: unset;
    }
    input {
      width: 100%;
      margin-bottom: ${vwMobile(20)};
    }
    h2 {
      font-size: ${vwMobile(25)};
      text-align: center;
    }
  }
`;

const FormWrapper = styled.div`
  height: ${props => (props.show ? '100%' : '0')};
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
  overflow: hidden;
`;

const Content = styled.div`
  padding-right: ${vw(35)};
  position: relative;
  height: ${vw(841)};
  p {
    line-height: 1.5;
  }
  @media (max-width: ${media.md}) {
    height: unset;
  }
`;

const Social = styled.div`
  font-size: ${vw(14)};
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: ${vw(3)};
  display: flex;
  span {
    margin-right: ${vw(10)};
  }
  a {
    margin: 0 ${vw(10)};
  }
  @media (max-width: ${media.md}) {
    display: block;
    text-align: center;
    span {
      margin-right: 0;
      display: block;
      margin-bottom: ${vwMobile(10)};
    }
    a {
      margin: 0 ${vwMobile(10)};
    }
    font-size: ${vwMobile(14)};
    letter-spacing: ${vwMobile(3)};
  }
`;

const FeaturedImage = styled.img`
  width: 100%;
  display: none;
  @media (max-width: ${media.md}) {
    display: block;
  }
`;

const FeaturedImageDesktop = styled.img`
  position: absolute;
  top: 0;
  left: calc(100% - 5px);
  height: 100%;
  @media (max-width: ${media.md}) {
    display: none;
  }
`;

const FeaturedVideo = styled.div`
  position: absolute;
  top: 0;
  left: calc(100% - 5px);
  height: 100%;
  video {
    height: 100%;
    object-fit: cover;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.45);
  }
  @media (max-width: ${media.md}) {
    display: none;
  }
`;
