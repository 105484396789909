import React from 'react';

export default [
  {
    title: 'Above The Trees',
    subTitle: 'FEATURING: Brent Comber',
    date: 'July 19th - October 16th, 2019',
    description:
      'Brent Comber’s newest exhibit celebrates the connection to our past that has been forged through the legacy of material and industry, and examines the evolution of the material as we recognize its part in the future of our built environments.',
    aside: [
      {
        title: 'Brent Comber',
        description: (
          <>
            Brent Comber Originals is an art and design studio that creates
            sculpted objects, functional pieces and designed environments.
            <br />
            <br />
            Brent, a fourth generation resident of the Pacific Northwest, has a
            body of work that is deeply rooted in the knowledge of, and respect
            for, place. His work has been shown as far as Paris, London, Japan,
            Bora Bora and beyond.
          </>
        ),
        link: 'https://www.brentcomber.com',
      },
    ],
    images: [
      require('src/assets/images/musee/past/gryphon_musee_abovethetrees_1.jpg'),
      require('src/assets/images/musee/past/gryphon_musee_abovethetrees_2.jpg'),
      require('src/assets/images/musee/past/gryphon_musee_abovethetrees_3.jpg'),
      require('src/assets/images/musee/past/gryphon_musee_abovethetrees_4.jpg'),
      require('src/assets/images/musee/past/gryphon_musee_abovethetrees_5.jpg'),
    ],
  },
  {
    title: 'Every Art Original',
    subTitle: 'FEATURING: Fiona Tang and Caitlin Reid',
    date: 'June - September, 2018',
    description:
      'We believe that art lives in our everyday life. It is meant to be original and emotional. This is a stage for artists to share and for audience to respond, a public space yet also a personal space.',
    aside: [
      {
        title: 'Fiona Tang',
        description:
          'Vancouver-based artist who completed her BFA at Emily Carr University in May 2014 with a Drawing Major.',
        link: 'https://fiona-tang.tumblr.com/',
      },
      {
        title: 'Caitlin Reid',
        description:
          'Self-taught contemporary artist born in Vancouver and raised on Vancouver Island.',
        link: 'https://www.caitreidart.com/',
      },
    ],
    images: [
      require('src/assets/images/musee/past/gryphon_musee_everyartoriginal_1.jpg'),
      require('src/assets/images/musee/past/gryphon_musee_everyartoriginal_2.jpg'),
      require('src/assets/images/musee/past/gryphon_musee_everyartoriginal_3.jpg'),
      require('src/assets/images/musee/past/gryphon_musee_everyartoriginal_4.jpg'),
      require('src/assets/images/musee/past/gryphon_musee_everyartoriginal_5.jpg'),
    ],
  },
  {
    title: 'Urban Landscapes',
    subTitle: 'FEATURING: Andrew McDermott and Jeff Wilson',
    date: 'January - April, 2018',
    description:
      'Collection of urban landscape painting as seen through the eyes of two Vancouver’s renowned painters. In this exhibition, you will find glimpse of familiar street corner presented in paintings by Jeff and Andrew.',
    aside: [
      {
        title: 'Andrew McDermott',
        description:
          'Andrew is the past President and a senior member of the Federation of Canadian Artists.',
        link: 'http://www.mcdermott-art.com/',
      },
      {
        title: 'Jeff Wilson',
        description:
          'Jeff produces painting collections that exhibit in public galleries in BC, Alberta and WA State.',
        link: 'https://www.jeffwilsonart.org/',
      },
    ],
    images: [
      require('src/assets/images/musee/past/gryphon_musee_urbanlandscape_1.jpg'),
      require('src/assets/images/musee/past/gryphon_musee_urbanlandscape_2.jpg'),
      require('src/assets/images/musee/past/gryphon_musee_urbanlandscape_3.jpg'),
      require('src/assets/images/musee/past/gryphon_musee_urbanlandscape_4.jpg'),
      require('src/assets/images/musee/past/gryphon_musee_urbanlandscape_5.jpg'),
    ],
  },
  {
    title: 'North Van Arts',
    subTitle: 'North Vancouver Art Rental Program',
    date: 'September, 2017 - January, 2018',
    description:
      'North Van Arts, founded in 1969 as The North Vancouver Community Arts Council, is a grassroots, social-profit, charitable cultural organization dedicated to maximizing the intrinsic value of the arts in all media.',
    aside: [
      {
        description:
          'Rental program represents approximately 150 artists with over 450 original works in a wide variety of styles and subjects.',
        link: 'https://northvanarts.ca/',
      },
    ],
    images: [
      require('src/assets/images/musee/past/gryphon_musee_northvanarts.jpg'),
      require('src/assets/images/musee/past/gryphon_musee_northvanarts_2.jpg'),
      require('src/assets/images/musee/past/gryphon_musee_northvanarts_3.jpg'),
    ],
  },
];
