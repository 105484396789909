import React, { useState } from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwMobile320 } from 'src/styles/utils';
import media from 'src/styles/media';

const Join = () => {
  const [isSubmit, setIsSubmit] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();

    // const form = document.getElementById('form');

    const data = new FormData(e.target);

    const name = data.get('contact[full_name]');
    const email = data.get('contact[email]');
    const utf8 = data.get('utf8');
    const authenticity_token = data.get('authenticity_token');

    data.set('contact[full_name]', name);
    data.set('contact[email]', email);
    data.set('utf8', utf8);
    data.set('authenticity_token', authenticity_token);

    fetch(
      'https://spark.re/gryphon-development/gryphon/register/musee-registration',
      {
        method: 'POST',
        body: data,
        mode: 'no-cors',
      }
    )
      .then(res => setIsSubmit(true))
      .catch(e => console.log('issue', e));
  };

  return (
    <Root>
      <Wrapper>
        <h1>
          {isSubmit
            ? 'Thank you for joining our mailing list.'
            : 'Sign up and never miss an event'}
        </h1>
        <Container>
          <p>
            {isSubmit
              ? 'We deeply appreciate your interest in Gryphon Musee. We will keep you informed about our art initiatives and upcoming events.'
              : 'Stay up to date with our special events and exhibitions'}
          </p>
          <Form
            id="form"
            // action="https://spark.re/gryphon-development/gryphon/register"
            // method="post"
            onSubmit={handleSubmit}
            isSubmit={isSubmit}
          >
            <input name="utf8" type="hidden" value="&#x2713;" />
            <input
              type="hidden"
              name="authenticity_token"
              value="LBApwLZCpzI8K5FoEmTSJeIrMhZCWtgLBZqdW15bdFGVd+uQnXKZV04+XoBdF87S5qayvnkcaN8yviAgXi6PhA=="
            />
            <input
              name="contact[full_name]"
              placeholder="Name"
              type="text"
              pattern="[a-zA-Z ]+"
            />
            <input
              name="contact[email]"
              placeholder="Email"
              type="email"
              required
            />
            <button type="submit">Subscribe</button>
          </Form>
        </Container>
      </Wrapper>
      <img
        src={require('src/assets/images/musee/join/join_bottom_photo.jpg')}
        alt="Gryphon Musee"
        className="bottom-photo"
      />
    </Root>
  );
};

export default Join;

const Root = styled.div`
  color: ${({ theme }) => theme.color.background};
  h1 {
    font-family: ${({ theme }) => theme.font.header};
    font-size: ${vwMobile320(18)};
    font-weight: 500;
    line-height: 1.67;
    color: #003345;
    margin-bottom: ${vwMobile320(30)};
    max-width: ${vwMobile320(200)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(35)};
      width: ${vwTablet(366)};
      line-height: 1.43;
      margin-bottom: ${vwTablet(40)};
      max-width: none;
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(35)};
      width: ${vw(366)};
      line-height: 1.53;
      margin-bottom: 0;
    }
  }
  form {
    display: flex;
    flex-direction: column;
  }
  .bottom-photo {
    width: 100%;
    height: ${vwMobile320(141)};
    margin-bottom: ${vwMobile320(-120)};
    margin-top: ${vwMobile320(60)};
    @media (min-width: ${media.xsup}) {
      height: ${vwTablet(332)};
      margin-bottom: ${vwTablet(-320)};
      margin-top: ${vwTablet(100)};
    }
    @media (min-width: ${media.mdup}) {
      height: ${vw(500)};
      margin-bottom: ${vw(-480)};
      margin-top: ${vw(120)};
    }
  }
`;

const Form = styled.form`
  opacity: ${props => (props.isSubmit ? '0' : '1')};
  max-height: ${props => (props.isSubmit ? 0 : '1000px')};
  overflow: hidden;
  transition: 0.3s ease-out;
`;

const Wrapper = styled.div`
  margin: 0 ${vwMobile320(18)};
  @media (min-width: ${media.xsup}) {
    margin: 0;
  }
  @media (min-width: ${media.mdup}) {
    display: flex;
  }
`;

const Container = styled.div`
  @media (min-width: ${media.xsup}) {
    margin-left: ${vwTablet(145)};
  }
  @media (min-width: ${media.mdup}) {
    margin-left: ${vw(142)};
  }
  p {
    @media (min-width: ${media.xsup}) {
      max-width: ${vwTablet(297)};
      margin-bottom: ${vwTablet(40)};
    }
    @media (min-width: ${media.mdup}) {
      max-width: ${vw(297)};
      margin-bottom: ${vw(40)};
    }
  }
  button {
    background-color: ${({ theme }) => theme.color.background};
    color: #fedd00;
    border-radius: ${vwMobile320(50)};
    padding: ${vwMobile320(14.5)} ${vwMobile320(53)};
    font-size: ${vwMobile320(11)};
    font-weight: bold;
    letter-spacing: ${vwMobile320(2.75)};
    text-align: center;
    text-transform: uppercase;
    margin-top: ${vwMobile320(48)};
    max-width: ${vwMobile320(197)};
    @media (min-width: ${media.xsup}) {
      border-radius: ${vwTablet(50)};
      padding: ${vwTablet(14.5)} ${vwTablet(53)};
      font-size: ${vwTablet(11)};
      letter-spacing: ${vwTablet(2.75)};
      margin-top: ${vwTablet(40)};
      max-width: ${vwTablet(197)};
    }
    @media (min-width: ${media.mdup}) {
      border-radius: ${vw(50)};
      padding: ${vw(14.5)} ${vw(53)};
      font-size: ${vw(11)};
      letter-spacing: ${vw(2.75)};
      margin-top: ${vw(40)};
      max-width: ${vw(197)};
    }
  }
  input {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid ${({ theme }) => theme.color.background};
    padding: ${vwMobile320(8)} 0;
    font-size: ${vwMobile320(12)};
    font-family: ${({ theme }) => theme.font.body};
    line-height: 2.08;
    margin-top: ${vwMobile320(32)};
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(406)};
      padding: ${vwTablet(9)} 0;
      font-size: ${vwTablet(14)};
      line-height: 1.79;
      margin-top: 0;
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(406)};
      padding: ${vw(9)} 0;
      font-size: ${vw(14)};
    }
  }
  input::placeholder {
    color: ${({ theme }) => theme.color.background};
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    transition: background-color 100000s ease-in-out 0s;
    /* background-color: transparent; */
  }
`;
