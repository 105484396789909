import React from 'react';
import styled from 'styled-components';

import PopupNav from '../elements/PopupNav';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

const Popup = props => {
  const {
    isOpen,
    activeService,
    data,
    handleClose,
    innerRef,
    scrollRef,
    handleNext,
    handlePrev,
  } = props;

  return (
    <Root
      style={{
        opacity: isOpen ? 1 : 0,
        pointerEvents: isOpen ? 'all' : 'none',
      }}
      id="popup"
      ref={innerRef}
    >
      <Close
        src={require('src/assets/images/icons/close-icon-white.svg')}
        alt="Close"
        onClick={handleClose}
      />
      <Section ref={scrollRef}>
        <Content>
          {data &&
            data.map((item, index) => {
                return (
                <div
                  key={index}
                  style={{
                    position: index === activeService ? 'relative' : 'absolute',
                    opacity: index === activeService ? 1 : 0,
                    pointerEvents:
                      index === activeService && isOpen ? 'all' : 'none',
                  }}
                >
                  <Title>
                    <Main src={item.image} alt="" />
                    <div>
                      <h3>{item.category}</h3>
                      <h1>{item.title}</h1>
                      {typeof item.subTitle !== 'undefined' && <h2>{item.subTitle}</h2>}
                      {typeof item.address !== 'undefined' && <p className='contact'>{item.address}</p>}
                      {typeof item.phone !== 'undefined' && <p className='contact'><a href={`tel:${item.phone}`}>{item.phone}</a></p>}
                      {typeof item.website !== 'undefined' && <p className='contact'><a href={item.website} target="_blank" rel="noreferrer noopener" className='website'>Website</a></p>}
                      
                      <p className="body desktop">
                        {item.text}
                        <br/>
                        <br/>
                        <span className='offer'>{item.offer}</span>
                      </p>
                    </div>
                  </Title>
                  <p className="body mobile">
                    {item.text}
                    <br/><br/>
                    <span className='offer'>{item.offer}</span>
                  </p>
                  <PopupNav 
                        handleNext={item.listIndex === 0 || item.listIndex === 19 ? () => handleNext(true) : () => handleNext()} 
                        handlePrev={item.listIndex === 6 || item.listIndex === 24 ? () => handlePrev(true) : () => handlePrev()} />

                  <PopupNav 
                        handleNext={item.listIndex === 0 || item.listIndex === 19 ? () => handleNext(true) : () => handleNext()} 
                        handlePrev={item.listIndex === 6 || item.listIndex === 24 ? () => handlePrev(true) : () => handlePrev()} mobile/>
                </div>
              )
            })}
        </Content>
      </Section>
    </Root>
  );
};

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.color.backgroundDark};
  z-index: 10000;
  transition: 0.5s ease-in-out;
  h1,
  h2,
  h3 {
    font-family: ${({ theme }) => theme.font.curator};
    font-weight: normal;
  }
  h1 {
    font-size: ${vwMobile(20)};
    line-height: 1.2;
    letter-spacing: ${vwMobile(4)};
    text-transform: uppercase;
    color: white;
  }
  h2 {
    font-size: ${vwMobile(14)};
    line-height: 1.71;
    letter-spacing: ${vwMobile(2)};
    color: ${({ theme }) => theme.color.pinkBrown};
  }
  h3 {
    font-size: ${vwMobile(10.5)};
    letter-spacing: ${vwMobile(3)};
    text-transform: uppercase;
    margin-bottom: ${vwMobile(20)};
    color: ${({ theme }) => theme.color.pinkBrown};
  }
  p {
    font-family: ${({ theme }) => theme.font.body};
    font-size: ${vwMobile(13)};
    font-weight: normal;
    line-height: 1.54;
    letter-spacing: ${vwMobile(0.5)};
    color: white;
  }
  .contact {
    color: ${({ theme }) => theme.color.pinkBrown};
    &:first-of-type {
      margin-top: ${vwMobile(20)};
    }
    a {
      color: ${({ theme }) => theme.color.pinkBrown};
      &.website {
        text-decoration: underline;
      }
    }
  }
  .body {
    margin-top: ${vwMobile(40)};
  }
  .offer {
    font-style: italic;
    margin: 0;
  }
  .desktop {
    display: none;
  }
  .white-text {
    color: white;
  }
  @media (min-width: ${media.xsup}) {
    h1 {
      font-size: ${vwTablet(25)};
      line-height: 0.96;
      letter-spacing: ${vwTablet(7)};
    }
    h2 {
      font-size: ${vwTablet(14)};
      line-height: 1.71;
      letter-spacing: ${vwTablet(4)};
      text-transform: uppercase;
      color: white;
    }
    h3 {
      font-size: ${vwTablet(14)};
      line-height: 1.71;
      letter-spacing: ${vwTablet(4)};
      margin-bottom: ${vwTablet(40)};
    }
    p {
      font-size: ${vwTablet(14)};
      line-height: 1.71;
      letter-spacing: ${vwTablet(0.5)};
    }
    .contact {
      &:first-of-type {
        margin-top: ${vwTablet(20)};
      }
    }
    .body {
      margin-top: ${vwTablet(40)};
    }
    .offer {
      margin: 0;
    }
  }
  @media (min-width: ${media.mdup}) {
    min-height: ${vw(700)};
    h1 {
      display: block;
      font-size: ${vw(25)};
      line-height: 1.12;
      letter-spacing: ${vw(5)};
    }
    h2 {
      font-size: ${vw(14)};
      line-height: 1.71;
      letter-spacing: ${vw(4)};
      text-transform: uppercase;
      color: white;
    }
    h3 {
      font-size: ${vw(14)};
      letter-spacing: ${vw(4)};
      margin-bottom: ${vw(40)};
    }
    h4 {

    }
    p {
      font-size: ${vw(14)};
      line-height: 1.71;
      letter-spacing: ${vw(0.5)};
      margin: 0;
    }
    .contact {
      &:first-of-type {
        margin-top: ${vw(20)};
      }
    }
    .body {
      margin-top: ${vw(40)};
      width: ${vw(565)};
      padding-right: ${vw(50)};
      overflow-y: auto;
      height: 100%;
      &::-webkit-scrollbar {
        width: ${vw(5)};
        padding-left: ${vw(50)};
      }
      &::-webkit-scrollbar-track {
        background-color: rgba(255,255,255,0.1);
        border-radius:  ${vw(2.5)};
        border: solid 50px transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.color.pinkBrown};
        border-radius:  ${vw(2.5)};
        border: solid 50px transparent;
      }
    }
    .offer {
      margin: 0;
    }
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
    }
  }
  @media (min-width: ${media.textbreak}) {
    h1 {
      font-size: 32px;
      line-height: 1.16;
      letter-spacing: 9px;
    }
    h2 {
      font-size: 24px;
      line-height: 1.33;
      letter-spacing: 4px;
    }
    h3 {
      font-size: 18px;
      line-height: 1.78;
      letter-spacing: 5px;
    }
    p {
      font-size: 18px;
      line-height: 2;
      letter-spacing:1.3px;
    }
  }
`;

const Close = styled.img`
  position: absolute;
  top: ${vwMobile(15)};
  right: ${vwMobile(15)};
  width: ${vwMobile(15)};
  height: ${vwMobile(15)};
  z-index: 5;
  @media (min-width: ${media.xsup}) {
    top: ${vwTablet(30)};
    right: ${vwTablet(30)};
    width: ${vwTablet(15)};
    height: ${vwTablet(15)};
  }
  @media (min-width: ${media.mdup}) {
    top: ${vw(55)};
    right: ${vw(50)};
    width: ${vw(30)};
    height: ${vw(30)};
    cursor: pointer;
  }
`;

const Main = styled.img`
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: ${vwMobile(20)};
  @media (min-width: ${media.xsup}) {
    margin-bottom: 0;
    width: ${vwTablet(284)};
    height: ${vwTablet(386)};
    object-fit: cover;
    object-position: center;
    margin-right: ${vwTablet(19)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(405)};
    height: ${vw(550)};
    margin-right: ${vw(141)};
  }
`;

const Content = styled.div`
  position: relative;
  > div {
    top: 0;
    transition: 0.5s ease-in-out;
  }
`;

const Section = styled.div`
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: ${vwMobile(40)} ${vwMobile(37)};
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(120)} ${vwTablet(90)} ${vwTablet(40)};
    position: relative;
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(109)} ${vw(189)};
    overflow-y: hidden;
    display: flex;
    align-items: center;
  }
`;

const Title = styled.div`
  @media (min-width: ${media.xsup}) {
    display: flex;
    align-items: flex-start;
  }
  @media (min-width: ${media.mdup}) {
    align-items: center;
    height: 100%;
    > div {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      height: ${vw(552)};
      overflow: hidden;
    }
  }
`;

export default Popup;
