import React, { Component } from 'react';
import styled from 'styled-components';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import Container from 'components/Container';
import Spacing from 'components/Spacing';
import Button from 'components/Button';

const gallery = [
  require('src/assets/images/about/gallery/1.jpg'),
  require('src/assets/images/about/gallery/2.jpg'),
  require('src/assets/images/about/gallery/3.jpg'),
  require('src/assets/images/about/gallery/4.jpg'),
  require('src/assets/images/about/gallery/5.jpg'),
  require('src/assets/images/about/gallery/6.jpg'),
];

export default class Story extends Component {
  render() {
    return (
      <Root>
        <Spacing value={120} />
        <h1>
          A Story,
          <br />
          20 Years in
          <br />
          the Making
        </h1>
        <Spacing value={30} />
        <p>
          With a rich legacy of creating inspired living experiences in Taiwan,
          our parent company Jinray has laid a global foundation for design,
          luxury and craftsmanship. Through a long tradition that places an
          emphasis on artistic creativity, a "Jinray home" is more than a
          beautiful space, it's a place where dreams come true.
        </p>
        <Spacing value={25} />
        <p>
          Now - the next generation spreads its wings, set on finding new forms
          of inspiration across the ocean - driven by the same respect for the
          creative spirit and a passion for excellence.
        </p>
        <Spacing value={75} />
        <Container>
          <Gallery>
            {gallery.map((item, index) => (
              <Image src={item} key={index} />
            ))}
            <JinrayLogo src={require('src/assets/images/about/jinray.svg')} />
          </Gallery>
          <Spacing value={175} mobile={150} />
          <Button href="http://jinray.com.tw/" target="_blank" rel="noreferrer">
            Visit Website
          </Button>
          <Spacing value={100} />
        </Container>
      </Root>
    );
  }
}

const Root = styled.div`
  text-align: center;
  h1 {
    color: ${({ theme }) => theme.color.primary};
    font-size: ${vw(100)};
    font-weight: 400;
    line-height: 1;
  }
  p {
    max-width: ${vw(520)};
    margin: 0 auto;
    line-height: 1.625;
    font-weight: 200;
  }
  @media (max-width: ${media.md}) {
    h1 {
      font-size: ${vwMobile(44)};
    }
    p {
      max-width: 90%;
    }
  }
`;

const Gallery = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`;

const Image = styled.div`
  width: 33.33%;
  background: url(${props => props.src});
  background-size: cover;
  background-position: center;
  &::before {
    content: '';
    display: block;
    padding-top: 66.6015625%;
  }
  @media (max-width: ${media.md}) {
    width: 50%;
    &::before {
      padding-top: 45%;
    }
  }
`;

const JinrayLogo = styled.img`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -30%);
  height: ${vw(152)};
  @media (max-width: ${media.md}) {
    height: ${vwMobile(100)};
    transform: translate(-50%, -20%);
  }
`;
