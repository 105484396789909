import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import Container from 'components/Container';
import Spacing from 'components/Spacing';

export default class PageContentBanner extends Component {
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
  };

  render() {
    const { title, subtitle, heightRatio } = this.props;
    return (
      <Root heightRatio={heightRatio}>
        <Container>
          {subtitle && (
            <SubtitleWrapper>
              <Subtitle>{subtitle}</Subtitle>
              <Spacing value={50} mobile={20} />
            </SubtitleWrapper>
          )}
          <Wrapper>
            <Header>
              {title && (
                <>
                  <Title>{title}</Title>
                  <Spacing value={0} mobile={50} />
                </>
              )}
            </Header>
            <Content>{this.props.children}</Content>
          </Wrapper>
        </Container>
      </Root>
    );
  }
}

const animationFadeInRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-${vw(40)});
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const animationFadeInRightMobile = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-${vwMobile(10)});
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const Root = styled.div`
  min-height: ${props =>
    props.heightRatio ? props.heightRatio * 56.25 : 56.25}vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(${require('src/assets/images/logo/g.svg')});
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 60% auto;
  & > div {
    width: 100%;
  }
  @media (max-width: ${media.md}) {
    min-height: auto;
    padding-top: ${vwMobile(100)};
    padding-bottom: ${vwMobile(70)};
    background-size: 50% auto%;
    background-position: 100% 0%;
  }
`;

const Header = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SubtitleWrapper = styled.div`
  animation: ${animationFadeInRight} 1s forwards;
  @media (max-width: ${media.md}) {
    animation: ${animationFadeInRightMobile} 1s forwards;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    width: 45%;
  }
  @media (max-width: ${media.md}) {
    display: block;
    & > div {
      width: 100%;
    }
  }
`;

const Subtitle = styled.h3`
  font-size: ${vw(10)};
  font-weight: 500;
  letter-spacing: ${vw(3)};
  text-transform: uppercase;
  @media (max-width: ${media.md}) {
    font-size: ${vwMobile(10)};
    letter-spacing: ${vwMobile(3)};
  }
`;

const Title = styled.h1`
  font-size: ${vw(60)};
  font-weight: 400;
  color: ${({ theme }) => theme.color.primary};
  line-height: 1.083;
  animation: ${animationFadeInRight} 1s forwards;

  @media (max-width: ${media.md}) {
    opacity: 0;
    font-size: ${vwMobile(36)};
    animation: ${animationFadeInRightMobile} 1s 0.33s forwards;
  }
`;

const Content = styled.div`
  opacity: 0;
  animation: ${animationFadeInRight} 1s 0.33s forwards;
  @media (max-width: ${media.md}) {
    animation: ${animationFadeInRightMobile} 1s 0.66s forwards;
  }
`;
