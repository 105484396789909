import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import { Link } from 'react-scroll';

import Page from 'components/Page';
import Menu from 'components/RoyalCurator/Menu';
import Gallery from './elements/Gallery';
import Popup from './sections/Popup';
// import ServicesList from './sections/ServicesList';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

import data from 'src/data/curatorServices';

const Services = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [activeService, setActiveService] = useState(0);
  const [dataServices, setDataServices] = useState(null);

  const $popup = useRef();
  const $scrollSection = useRef();

  useEffect(() => {
    const services = [];
    data.map(item =>
      item.services.map(service => {
        service.category = item.title;
        return services.push(service);
      })
    );
    setDataServices(services);
    return () => clearAllBodyScrollLocks();
  }, []);

  const handlePopup = listIndex => {
    const index = dataServices.findIndex(item => item.listIndex === listIndex);
    setActiveService(index);
    disableBodyScroll($scrollSection.current);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    enableBodyScroll($scrollSection.current);
    setIsPopupOpen(false);
  };

  const handleNext = skip => {
    if (activeService === dataServices.length - 1) {
      setActiveService(0);
    } else {
      if (skip) {
        setActiveService(activeService + 2);
      } else {
        setActiveService(activeService + 1);
      }
    }
    $scrollSection.current.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handlePrev = skip => {
    if (activeService === 0) {
      setActiveService(dataServices.length - 1);
    } else {
      if (skip) {
        setActiveService(activeService - 2);
      } else {
        setActiveService(activeService - 1);
      }
    }
    $scrollSection.current.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Page>
      <Menu />
      <Intro>
        {/* <Link to="list" smooth={true}>
          <h2 className="curator-intro title">See Service Directory</h2>
        </Link> */}
        <h1 className="curator">Our Services</h1>
        <Gallery cards handlePopup={handlePopup} />
      </Intro>
      {/* <ServicesList handlePopup={handlePopup} /> */}
      <Popup
        isOpen={isPopupOpen}
        handleClose={handleClosePopup}
        handlePrev={handlePrev}
        handleNext={handleNext}
        data={dataServices}
        activeService={activeService}
        innerRef={$popup}
        scrollRef={$scrollSection}
      />
    </Page>
  );
};

const Intro = styled.div`
  background-color: ${({ theme }) => theme.color.veryLightPink};
  color: black;
  height: 100vh;
  padding-top: ${vwMobile(88)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    cursor: pointer;
    &::after {
      content: '';
      display: block;
      width: ${vwMobile(130)};
      height: 1px;
      background-color: black;
      margin-top: ${vwMobile(6)};
    }
  }
  @media (min-width: ${media.xsup}) {
    padding-top: ${vwTablet(228)};
    .title {
      &::after {
        width: ${vwTablet(220)};
        margin-top: ${vwTablet(6)};
      }
    }
  }
  @media (min-width: ${media.mdup}) {
    min-height: ${vw(700)};
    padding-top: ${vw(104)};
    .title {
      &::after {
        width: ${vw(220)};
        margin-top: ${vw(6)};
        transition: 0.5s ease-in-out;
      }
      &:hover::after {
        width: 100%;
      }
    }
  }
`;

export default Services;
