import React, { Component } from 'react';
import Script from 'react-load-script';
import { OnUpdate } from 'rrc';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { update } from 'src/redux/abTest';

import appConfig from 'src/config/app.conf';

class GoogleAnalytics extends Component {
  state = {
    isLoaded: false,
  };

  handleScriptLoad = () => {
    window.dataLayer = window.dataLayer || [{}];
    window.gtag = function() {
      window.dataLayer.push(arguments);
    };

    window.gtag('js', new Date());
    window.gtag('config', appConfig.gtag, { optimize_id: 'GTM-TJ9KBK8' });

    window.dataLayer && window.dataLayer.push({ event: 'optimize.activate' });

    const interval = setInterval(() => {
      if (window.google_optimize !== undefined) {
        // Value is the experiment ID in GA
        const value = window.google_optimize.get('T7QLksyRT0-ytNyVErJZhw');
        if (value === '1') {
          this.props.update();
        }
        clearInterval(interval);
      }
    }, 100);

    this.setState({ isLoaded: true });
  };

  handleUpdate = location => {
    const gtag = window.gtag;
    if (gtag) {
      gtag('config', appConfig.gtag, {
        page_location: window.location.href,
        page_path: location.pathname,
      });
    }
  };

  render() {
    return appConfig.gtag && !window.__PRERENDERING ? (
      <>
        <Script
          url={`https://www.googletagmanager.com/gtag/js?id=${appConfig.gtag}`}
          onLoad={this.handleScriptLoad}
        />
        {this.state.isLoaded && <OnUpdate immediate call={this.handleUpdate} />}
      </>
    ) : null;
  }
}

const mapStateToProps = state => ({
  abTest: state.abTest,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      update,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleAnalytics);
