import React, { Component } from 'react';
import styled from 'styled-components';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import Page from 'components/Page';
import PageContentBanner from 'components/PageContentBanner';
import Form from './elements/Form';

export default class Register extends Component {
  render() {
    return (
      <Page>
        <PageContentBanner title="Register">
          <Content>
            <Form />
          </Content>
        </PageContentBanner>
      </Page>
    );
  }
}

const Content = styled.div`
  line-height: 1.625;
  p {
    margin-bottom: ${vw(25)};
    @media (max-width: ${media.md}) {
      margin-bottom: ${vwMobile(25)};
    }
  }
`;
