import React, { Component } from 'react';
import styled from 'styled-components';
import scrollToElement from 'scroll-to-element';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import Container from 'components/Container';
import Spacing from 'components/Spacing';
import Button from 'components/Button';

export default class AtelierSection extends Component {
  handleClickButton = () => {
    scrollToElement('#atelier-form');
  };

  render() {
    return (
      <Container>
        <Root>
          <Logo
            src={require('src/assets/images/about/gryphon-atelier-gold.svg')}
          />
          <Spacing value={45}  mobile={30}  />
          <Copy>
            At Atelier Gryphon, We're harnessing the creative potential of a
            wide spectrum of world class artists to create new living art forms.
            This collaborative process embraces each individual, their unique
            perspective and creative backgrounds.
          </Copy>
          {this.props.atelier && (
            <>
              <Spacing value={100} />
              <Button onClick={this.handleClickButton}>Learn More</Button>
            </>
          )}

          <Spacing value={100} mobile={60} />

         
        </Root>
      </Container>
    );
  }
}

const Root = styled.div`
  text-align: center;
 
`;

const Logo = styled.img`
  display: block;
  margin: 0 auto;
  width: ${vw(400)};
  @media (max-width: ${media.md}),(max-width: ${media.lg}) {
    width: 35%;
  }
  @media (max-width: ${media.sm}) {
    width: 80%;
  }
`;

const Copy = styled.p`
  width: ${vw(860)};
  margin: 0 auto;
  color: ${({ theme }) => theme.color.primary};
  font-size: ${vw(20)};
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: ${vw(3.5)};
  @media (max-width: ${media.md}),(max-width: ${media.lg}) {
    width: 80%;
  }
  @media (max-width: ${media.sm}) {
    width: 100%;
    font-size: ${vwMobile(16)};
    letter-spacing: ${vwMobile(1.2)};
  }
`;
