import React, { useState, useEffect } from 'react';
import Script from 'react-load-script';
import TagManager from 'react-gtm-module';

import { OnUpdate } from 'rrc';

import appConfig from 'src/config/app.conf';
const { analytics } = appConfig;

const GTM = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleScriptLoad = () => {
    let options = {};
    window.dataLayer = window.dataLayer || [];
    window.gtag = function() {
      window.dataLayer.push(arguments);
    };

    if (analytics.optimizeId) {
      options.optimize_id = analytics.optimizeId;
    }

    window.gtag('js', new Date());
    window.gtag('config', Object.keys(window.gaData)[0], options);

    if (analytics.optimizeId) {
      window.dataLayer && window.dataLayer.push({ event: 'optimize.activate' });
    }

    setIsLoaded(true);
  };

  const handleUpdate = location => {
    const gtag = window.gtag;
    if (gtag) {
      gtag('config', Object.keys(window.gaData)[0], {
        page_location: window.location.href,
        page_path: location.pathname,
      });
    }
  };

  useEffect(() => {
    if (window.__PRERENDERING) return;
    if (analytics.gtmId) {
      const tagManagerArgs = {
        gtmId: analytics.gtmId,
      };

      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  if (window.gaData) {
    return Object.keys(window.gaData)[0] && !window.__PRERENDERING ? (
      <>
        <Script
          url={`https://www.googletagmanager.com/gtag/js?id=${
            Object.keys(window.gaData)[0]
          }`}
          onLoad={handleScriptLoad}
        />
        {isLoaded && <OnUpdate immediate call={handleUpdate} />}
      </>
    ) : null;
  } else {
    return null;
  }
};

export default GTM;
