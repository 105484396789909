import React from 'react';
import styled from 'styled-components';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

export default function Input(props) {
  return <Root {...props} ref={props.refDOM} />;
}

const Root = styled.input`
  padding: ${vw(5)} 0;
  border: none;
  border-bottom: ${vw(1)} solid ${({ theme }) => theme.color.primary};
  background: transparent;
  font-size: ${vw(14)};
  color: #fff;
  outline: none;
  &::placeholder {
    color: #fff;
    font-family: 'Gotham';
    font-size: ${vwMobile(13)};
    letter-spacing: ${vwMobile(1)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(13)};
      letter-spacing: ${vwTablet(1)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(13)};
      letter-spacing: ${vw(1)};
    }
  }
  @media (max-width: ${media.md}) {
    padding: ${vwTablet(5)} 0;
    font-size: ${vwTablet(13)};
  }
  @media (max-width: ${media.xs}) {
    padding: ${vwMobile(8)} 0;
    font-size: ${vwMobile(14)};
  }
`;
