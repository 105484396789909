import React, { Component } from 'react';
import styled from 'styled-components';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

export default class Consent extends Component {
  render() {
    return (
      <Root>
        <input type="checkbox" />
        <CheckBox />
        <CheckBoxLabel>
          I hereby consent to receive emails from Gryphon Development and their
          respective current and future affiliates and subsidiaries. This may
          include pricing, floor plans for current and future products, sales
          updates, event invitations, announcements, corporate newsletters, and
          other contact. I understand that I can unsubscribe at any time. *
        </CheckBoxLabel>
      </Root>
    );
  }
}

const CheckBoxLabel = styled.span`
  font-size: ${vw(9)};
  line-height: 1.5;
  @media (max-width: ${media.md}) {
    font-size: ${vwMobile(13)};
  }
`;

const CheckBox = styled.span`
  width: ${vw(15)};
  height: ${vw(15)};
  border: 1px solid ${({ theme }) => theme.color.primary};
  display: inline-block;
  margin-right: ${vw(15)};
  transition: all 0.25s;
  flex-shrink: 0;
  @media (max-width: ${media.md}) {
    width: ${vwMobile(15)};
    height: ${vwMobile(15)};
    margin-right: ${vwMobile(15)};
  }
`;

const Root = styled.label`
  display: flex;
  input {
    display: none;
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    &:checked ~ ${CheckBox} {
      background: ${({ theme }) => theme.color.primary};
    }
  }
`;
