import React, { Component } from 'react';
import scrollToElement from 'scroll-to-element';

import styled, { keyframes } from 'styled-components';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

function getRandomBackground() {
  const id = Math.floor(Math.random() * Math.floor(3)) + 1;
  return require(`src/assets/images/home/hero-${id}.jpg`);
}

export default class Hero extends Component {
  state = {
    background: null,
  };

  componentDidMount() {
    this.setState({
      background: getRandomBackground(),
    });
  }

  handleClickWatchVideo = () => {
    scrollToElement(document.querySelector('#home-video-player'));
  };

  render() {
    return (
      <Root background={this.state.background}>
        <h1>
          Dreams
          <br />
          are born from
          <br />
          Collaboration
        </h1>
        <Watch onClick={this.handleClickWatchVideo}>Watch the Video</Watch>
      </Root>
    );
  }
}

const animateFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const animateLine = keyframes`
  0% {
    transform: scaleY(0) translateX(-50%); 
  }
  100% {
    transform: scaleY(1) translateX(-50%);
  }
`;

const animateBackground = keyframes`
  0% {
    transform: scale(1);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    transform: scale(1.1);
  }
`;

const Root = styled.div`
  width: 100vw;
  height: ${vw(1080)};
  max-height: 100vh;
  position: relative;
  text-align: center;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${props => props.background});
    background-size: cover;
    background-position: center;
    display: block;
    animation: ${animateBackground} 25s forwards;
  }
  h1 {
    font-family: 'SangBleuEmpire';
    font-size: ${vw(100)};
    font-weight: normal;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    letter-spacing: ${vw(-1)};
    line-height: 1;
    opacity: 0;
    animation: ${animateFadeIn} 2s 1s forwards;
    @media (max-width: ${media.md}) {
      font-size: ${vwMobile(44)};
      letter-spacing: ${vwMobile(-1)};
    }
  }
  @media (max-width: ${media.md}) {
    height: 100vh;
  }
`;

const Watch = styled.span`
  text-transform: uppercase;
  position: absolute;
  bottom: ${vw(100)};
  left: 50%;
  transform: translateX(-50%);
  font-size: ${vw(18)};
  opacity: 0;
  animation: ${animateFadeIn} 1.5s 1.5s forwards;
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    width: 1px;
    height: ${vw(120)};
    background: ${({ theme }) => theme.color.primary};
    display: block;
    left: 50%;
    top: calc(100% + ${vw(20)});
    transform: translateX(-50%) scaleY(0);
    transform-origin: 50% 0%;
    animation: ${animateLine} 1.5s 2s forwards;
  }
  @media (max-width: ${media.md}) {
    font-size: ${vwMobile(16)};
    bottom: ${vwMobile(100)};
    &::after {
      top: calc(100% + ${vwMobile(20)});
      height: ${vwMobile(100)};
    }
  }
`;
