import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vw } from 'src/styles/utils';

export default function FadeUp(props) {
  return <Root animate={props.animate} {...props} />;
}

FadeUp.propTypes = {
  delay: PropTypes.number,
  duration: PropTypes.number,
  animate: PropTypes.bool,
};

FadeUp.defaultProps = {
  delay: 0,
  duration: 1,
  animate: true,
};

const Root = styled.div`
  transform: ${props =>
    props.animate ? 'translateY(0)' : `translateY(${vw(20)})`};
  opacity: ${props => (props.animate ? 1 : 0)};
  transition: transform ${props => props.duration}s,
    opacity ${props => props.duration}s;
  transition-delay: ${props => (props.animate ? props.delay : 0)}s;
`;
