// Constants
export const UPDATE_TEST = 'COUNTER::INCREMENT';

// Actions
export const update = () => ({
  type: UPDATE_TEST,
});

// Initial State
const initialState = {
  abTest: false,
};

// Reducer
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_TEST:
      return {
        ...state,
        abTest: true,
      };
    default:
      return state;
  }
};
