import React, { Component } from 'react';
import styled from 'styled-components';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import Button from 'components/Button';
import Spacing from 'components/Spacing';

export default class Project extends Component {
  render() {
    const {
      title,
      subtitle,
      text,
      link,
      image,
      margin,
      centerImage,
    } = this.props;
    return (
      <Root margin={margin}>
        <Background image={image} center={centerImage} />
        <Content>
          <Spacing value={0} mobile={60} tabletlg={60} />
          <h1>{title}</h1>
          <h4>{subtitle}</h4>
          <Spacing value={25} tabletlg={60} />
          <p>{text}</p>
          <Spacing value={25} tabletlg={60} />
          <Button href={link} target="_blank" rel="noreferrer">
            View Website
          </Button>
        </Content>
      </Root>
    );
  }
}

const Root = styled.div`
  position: relative;
  justify-content: flex-end;
  display: flex;
  margin-bottom: ${vw(100)};
  @media (max-width: ${media.md}) {
    display: block;
    margin-bottom: ${vwMobile(60)};
  }
`;

const Content = styled.div`
  max-width: ${vw(460)};
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  padding: ${vw(45)} ${vw(30)};
  background: ${({ theme }) => theme.color.background};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  h1 {
    color: ${({ theme }) => theme.color.primary};
    font-size: ${vw(40)};
    font-weight: 500;
    line-height: 1.25;
    @media (max-width: ${media.md}) {
      font-size: ${vwMobile(40)};
    }
  }
  h4 {
    font-size: ${vw(10)};
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: ${vw(3)};
    @media (max-width: ${media.md}) {
      font-size: ${vwMobile(10)};
      letter-spacing: ${vwMobile(3)};
    }
  }
  p {
    line-height: 1.5;
  }
  @media (max-width: ${media.md}) {
    padding: 0;
    position: static;
    transform: none;
    box-shadow: none;
    max-width: calc(100% - ${vwMobile(40)});
    margin: 0 auto;
  }
`;

const Background = styled.div`
  width: ${vw(1150)};
  height: ${vw(700)};
  background-image: url(${props => props.image});
  background-size: ${props => (props.center ? 'cover' : '120% auto')};
  @media (max-width: ${media.md}) {
    width: 100%;
    height: ${vwMobile(260)};
  }
`;
