import React from 'react';
import styled from 'styled-components';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

const PopupNav = props => {
  const { handleNext, handlePrev, mobile } = props;
  return (
    <Root mobile={mobile}>
      <div onClick={handlePrev}>
        <Arrow
          src={require('src/assets/images/icons/services_prevnext_arrow.svg')}
          alt="Prev"
          left
        />
        <h3 className="white-text">Prev</h3>
      </div>
      <div onClick={handleNext}>
        <h3 className="white-text">Next</h3>
        <Arrow
          src={require('src/assets/images/icons/services_prevnext_arrow.svg')}
          alt="Next"
        />
      </div>
    </Root>
  );
};

const Root = styled.div`
  justify-content: space-between;
  align-items: center;
  display: ${props => (props.mobile ? 'flex' : 'none')};
  margin-top: ${vwMobile(80)};
  > div {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  h3 {
    margin-bottom: 0 !important;
  }
  @media (min-width: ${media.xsup}) {
    justify-content: flex-start;
    position: absolute;
    bottom: ${vwTablet(-80)};
    margin-bottom: 0;
    > div:first-of-type {
      margin-right: ${vwTablet(40)};
    }
  }
  @media (min-width: ${media.mdup}) {
    position: absolute;
    bottom: ${vw(-70)};
    display: ${props => (props.mobile ? 'none' : 'flex')};
    justify-content: space-between;
    width: 100%;
    > div:first-of-type {
      margin-right: ${vw(40)};
    }
  }
`;

const Arrow = styled.img`
  width: ${vwMobile(19)};
  height: auto;
  display: block;
  transform: ${props => props.left && 'scaleX(-1)'};
  margin-right: ${props => (props.left ? vwMobile(14) : 0)};
  margin-left: ${props => (props.left ? 0 : vwMobile(14))};
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(19)};
    margin-right: ${props => (props.left ? vwTablet(14) : 0)};
    margin-left: ${props => (props.left ? 0 : vwTablet(14))};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(19)};
    margin-right: ${props => (props.left ? vw(14) : 0)};
    margin-left: ${props => (props.left ? 0 : vw(14))};
  }
`;

export default PopupNav;
