import React from 'react';

export default [
  {
    image: require('src/assets/images/curator/services/services_beauty.jpg'),
    logo: require('src/assets/images/curator/services/services_beauty_lily.png'),
    title: 'Beauty & TLC',
    services: [
      {
        title: 'Rose Nails & Spa',
        address: '6099 West Blvd, Vancouver',
        phone: '604-620-3100',
        text:
          <>
            Serving the Vancouver West area with two locations for your beauty needs, please contact us for a quote and to schedule your next appointment! 
            <br/><br/>
            3383 Dunbar St, Vancouver, BC V6S 2B9
            <br/>
            6099 West Blvd, Vancouver, BC V6M 3X2
          </>,
        offer: '10% off',
        image: require('src/assets/images/curator/services/service/rose-nails-spa.jpg'),
        listIndex: 0,
      },
    ],
  },
  {
    image: require('src/assets/images/curator/services/services_drycleaning.jpg'),
    logo: require('src/assets/images/curator/services/services_drycleaning_lotus.png'),
    title: 'Cleaning Services',
    services: [
      {
        comingsoon: true,
      },
    ],
  },
  {
    image: require('src/assets/images/curator/services/services_moving.jpg'),
    logo: require('src/assets/images/curator/services/services_moving_daisy.png'),
    title: 'MOVING ARRANGEMENTS',
    services: [
      {
        title: "Papa's Moving",
        address: '826 W 68th Ave, Vancouver',
        phone: '604-782-6683',
        website: 'http://www.papasmoving.com/',
        text:
          <>
            Serving Greater Vancouver since 2000  
            <br/><br/>
            Our moves range from small moves to large homes. We do apartments, homes, and offices. We do moves large or small.
            <br/><br/>
            We are a small family business, and we care about your move! Papa is Brent Partridge – office and maintenance. Mama is Annie B – office. Our son Cody and his friends plus more great guys are the Papa’s crew.
            <br/><br/>
            We are known for our steady work pace, our efficiency and our gentle care of the goods. We are an accredited member of the BBB with an A+ rating.  
          </>,
        offer: '5% discount on moving services',
        image: require('src/assets/images/curator/services/service/papas-moving.jpg'),
        listIndex: 6,
      },
    ],
  },
  {
    image: require('src/assets/images/curator/services/services_children.jpg'),
    logo: require('src/assets/images/curator/services/services_convenienceservices_anthurium.png.png'),
    title: 'CONVENIENCE SERVICES',
    services: [
      {
        title: "Tony's Shoes Repair",
        address: '6041 West Blvd, Vancouver',
        phone: '604-263-0699',
        text:
          <>
            Tony's Shoes Repair has been serving the Vancouver community for over 10 years, being one of the local community’s most trusted shop. Located in the heart of Kerrisdale, service includes:
            <br/><br/>
            <ul>
              <li>Shoe & bag repair</li>
              <li>Leather repair</li>
              <li>Zipper change and repair</li>
              <li>Knives & lawn mower sharpening </li>
              <li>Key cutting</li>
            </ul>
          </>,
        offer: 'Free delivery and pick up for Vancouver West locations',
        image: require('src/assets/images/curator/services/service/tonys-shoes-repair.jpg'),
        listIndex: 9,
      },
      {
        title: 'Kerrisdale Lumber',
        address: '6365 West Blvd, Vancouver',
        phone: '604-261-4274',
        website: 'https://www.kerrisdalelumber.ca/',
        text:
          <>
            Kerrisdale Lumber started in 1921, horse and wagon brought them much of their merchandise. Other goods came by rail car on the West Boulevard. A fourth generation of the family now works at Kerrisdale Lumber. 
            <br/><br/>
            The business has evolved from a neighbourhood hardware store to a diverse company with hardware, home décor, outdoor living and BBQ divisions. Our focus remains the same, providing extraordinary customer service while improving the spaces they live in.
          </>,
        offer: 'One time offer: 10% off discount for our outdoor living products: our BBQ’s, fire tables and fire pits, pizza ovens, outdoor heaters and BBQ accessories. Other offers: 10% off anything in the store (home décor, paint, hardware, other household items) with a minimum purchase of $150.',
        image: require('src/assets/images/curator/services/service/kerrisdale-lumber.jpg'),
        listIndex: 10,
      },
    ],
  },
  {
    image: require('src/assets/images/curator/services/services_health.jpg'),
    logo: require('src/assets/images/curator/services/services_health_glad.png'),
    title: 'HEALTH PROGRAMS',
    services: [
      {
        title: 'Urban Machina',
        address: '#229-1868 Glen Dr, Vancouver',
        phone: '604-695-0122',
        website: 'https://urbanmachina.com/',
        text:
          <>
            Today’s public transportation systems are better than ever. Urbanites are now going farther with ease, but the last mile experience hasn’t quite evolved over the past 10 years. Our goal is to equip commuters with an easier and more enjoyable way to get from home to the station, and wherever you may need to be.
            <br/><br/>
            At Urban Machina, you’re no longer just a commuter. You’re an urban rider. We make personal transportation fun, easy and efficient. Go personal. Go electric. 
          </>,
        offer: 'Free Urban Rider Pack ($125 value), for each electric scooter purchase. The rider pack includes: foldable water bottles, scooter alarm, Odier phone holder, Etook lock and tote bag ',
        image: require('src/assets/images/curator/services/service/urban-machina.jpg'),
        listIndex: 12,
      },
      {
        title: 'Ricepump Fitness',
        text:
          <>
            Timothy Yeh has been an award-winning personal trainer for over 5 years and was a top trainer at one of BC's largest organizations. With over 10 years of training experience, he provides leading edge science-based results and proven knowledge to deliver fast, effective and safe results for his clients.
            <br/><br/>
            Certified in: 
            <br/>
            <ul>
              <li>American Council of Exercise Personal Trainer</li>
              <li>Certificate Behavioural Change Coach</li>
              <li>TRX suspension Training</li>
              <li>Agatsu Kettle Bell Training</li>
              <li>Myofasical Foam Rolling</li>
              <li>Posture Analyst</li>
            </ul>
          </>,
        offer: '15% off personal training ',
        image: require('src/assets/images/curator/services/service/ricepump-fitness.jpg'),
        listIndex: 13,
      },
      {
        title: 'Barre Fitness',
        address: '3584 W 41st Ave, Vancouver',
        phone: '604-266-9191',
        website: 'http://www.barrefitness.com/dunbar',
        text:
          <>
          Barre Fitness is a 100% Canadian owned company that started with its first studio in Yaletown in 2010. Their newest location in Dunbar opened its doors to the community in Sept 2019.
          <br/><br/>
          Barre Fitness offers a one-hour total body workout that combines ballet barre work, Pilates, sports conditioning and stretching. Some classes also incorporate deep stretching, cardio or high intensity interval training. All fitness levels are welcome!
          <br/><br/>
          Joèl and Lani are the owners of the Dunbar studio, and they look forward to meeting you soon.
          </>,
        offer: '2 weeks of unlimited complimentary Barre classes. (Either in the virtual studio during COVID 19, or in the regular one once reopened).',
        image: require('src/assets/images/curator/services/service/barre-fitness.jpg'),
        listIndex: 14,
      },
    ],
  },
  {
    image: require('src/assets/images/curator/services/services_professional_services.jpg'),
    logo: require('src/assets/images/curator/services/services_smartdevice_iris.png'),
    title: 'PROFESSIONAL SERVICES',
    services: [
      {
        title: 'Caerus Immigration',
        address: '#1190-11980 Hammersmith Way , Richmond',
        phone: '778-968-4628',
        website: 'https://caerusimmigration.com/',
        text:
          <>
            Caerus Immigration Consulting Ltd. is a unique firm specialized in providing advisory services to clients on Canadian immigration and citizenship. With the company’s founder, Mark Lee’s vast experience as a former Citizenship Officer at Immigration, Refugees and Citizenship Canada (IRCC) coupled with his strong personal network, our mission is to assist clients to formulate strategies and strengthen their temporary and permanent residence applications while maintaining a high level of integrity.
          </>,
        offer: '15% discount on professional services related to Canadian immigration and citizenship.',
        image: require('src/assets/images/curator/services/service/caerus.jpg'),
        listIndex: 15,
      },
      {
        title: 'Eyelab',
        subTitle: 'Doctors of Optometry',
        address: '2369 W 41st Ave, Vancouver',
        phone: '604-260-1166',
        website: 'http://www.helloeyelab.com/',
        text:
          <>
            A creative team of eye care professionals that aim to push the boundaries of optometry.  We want to share our love of myopia management + vision therapy.  We believe in technology and have invested heavily in it.  Don’t worry, we still provide everyday eyecare. It’s pretty simple. 
            <br/><br/>
            <ul>
              <li>Orthokeratology + Myopia Management</li>
              <li>Vision Therapy (Lazy Eye, Reading, Concussion, Sports)</li>
              <li>Comprehensive Eye Exams</li>
              <li>Eyewear, Sunglasses, Contact Lenses</li>
              <li>Emergency and Ocular Disease Eye Exams</li>
              <li>Laser Eye Surgery Pre/Post-Op Management</li>
            </ul>
          </>,
        offer: '1. Spectacle Frames 20% off when purchased with lenses. Second Pair 40% off. 2. Sunglasses 10% off 3. Free delivery on all contact lenses sale. ',
        image: require('src/assets/images/curator/services/service/eyelab.jpg'),
        listIndex: 16,
      },
    ],
  },
  {
    image: require('src/assets/images/curator/services/services_diningout.jpg'),
    logo: require('src/assets/images/curator/services/services_diningout_rose.png'),
    title: 'Restaurants',
    services: [
      {
        title: 'Sweet Barrel ',
        subTitle: 'Creamery and Coffee Roasters',
        address: '2729 Arbutus St, Vancouver',
        website: 'https://www.sweetbarrelcreamery.com/',
        text:
          "Five years in the making and always aiming to serve the community, Sweet Barrel is a locally owned, Vancouver based cafe. Our on-site roasting, brewing and ice cream churning makes us an interesting triple threat and we hope you come to check us out!",
        offer: '5% off icecream and coffee',
        image: require('src/assets/images/curator/services/service/sweet-barrel.jpg'),
        listIndex: 18,
      },
      {
        title: "Karen's Dreamy",
        subTitle: "Bakeshop",
        address: '4021 Macdonald St, Vancouver',
        phone: '604-714-1212',
        website: 'https://www.kdbakeshop.com/',
        text:
          <>
            The following are the products we are selling at the bakery: 
            <br/><br/>
            Sourdough bread loaves! (Regular, Charcoal, Sunflower Seeds & Flax, Walnuts & Cranberries), Pastries, Croissants, cookies, brownies, make to order sandwiches - we make everything fresh, with love, every morning.
          </>,
        offer: '5% off & Free delivery on orders over $100 for Vancouver West Side location',
        image: require('src/assets/images/curator/services/service/kdbake-shop.jpg'),
        listIndex: 19,
      },
    ],
  },
  {
    image: require('src/assets/images/curator/services/services_interiordesign.jpg'),
    logo: require('src/assets/images/curator/services/services_interiordesign_paradise.png'),
    title: 'INTERIOR DESIGN & ART CONSULTING',
    services: [
      {
        comingsoon: true,
      },
    ],
  },
  {
    image: require('src/assets/images/curator/services/services_specialoccasions.jpg'),
    logo: require('src/assets/images/curator/services/services_specialoccasions_carnation.png'),
    title: 'THAT SPECIAL OCCASION',
    services: [
      {
        title: 'Thomas Hobbs Florist',
        address: '2127 W 41st Ave, Vancouver',
        phone: '604-263-2601',
        website: 'https://thomashobbsflorist.com/',
        text:
          <>
          Established in 1975, Thomas Hobbs Florist has been in the heart of Kerrisdale as a premier flowers and gift shop. Always having an abundant of local grown and imported flowers, we vary from classic English country gardens to chic and modern designs.
          <br/><br/>
          Our name, Thomas Hobbs Florist, has been passed down over 40 years and our traditions remain: remarkable service - extraordinary flowers and breathtaking atmosphere.
          <br/><br/>
          When only the finest flowers and quality will do, come and relish your senses in all that we have to offer.
          </>,
        offer: '10% discount and Free Delivery',
        image: require('src/assets/images/curator/services/service/thomas-hobbs-florist.jpg'),
        listIndex: 24,
      },
      {
        title: 'Limes and Lemons',
        website: 'http://www.limesandlemons.com',
        text:
          <>
            Limes & lemons is a creative visual collaborative between two Vancouver-based twenty-somethings, Phoebe and Isabel.
            <br/><br/>
            Services:
            <ul>
              <li>Photography</li>
              <li>Product</li>
              <li>Event</li>
              <li>Portrait/headshots</li>
              <li>Engagement/Wedding</li>
              <li>Corporate/Commercial</li>
              <li>Sports</li>
              <li>Videography + Editing</li>
            </ul>
          </>,
        offer: '10% off the total on first photoshoot session within the Portrait and Event Photography categories.',
        image: require('src/assets/images/curator/services/service/limes-and-lemons.jpg'),
        listIndex: 25,
      },
    ],
  },
];
