import React, { Component } from 'react';
import styled from 'styled-components';

import { vw } from 'src/styles/utils';
import media from 'src/styles/media';

import Spacing from 'components/Spacing';

export default class Featured extends Component {
  state = {
    active: 0,
  };

  handleSelectItem = index => {
    this.setState({ active: index });
  };

  renderFeaturedBackground = () => {
    return this.props.featuredItems.map((item, index) => {
      return (
        <Background
          src={item.background}
          key={index}
          active={this.state.active === index}
        />
      );
    });
  };

  renderFeaturedItem = index => {
    let featured = this.props.featuredItems[index];
    return (
      <FeaturedItem
        key={index}
        onMouseOver={() => this.handleSelectItem(index)}
        onClick={() => this.handleSelectItem(index)}
      >
        <MaskContainer active={this.state.active === index}>
          {featured.mask}
        </MaskContainer>
        <Content>
          <span>Years</span>
          <Spacing value={10} />
          <p>{featured.copy}</p>
        </Content>
      </FeaturedItem>
    );
  };

  render() {
    const { featuredItems } = this.props;
    return (
      <Root background={featuredItems[this.state.active].background}>
        {this.renderFeaturedBackground()}
        <Wrapper>
          {featuredItems.map((featuredItem, index) =>
            this.renderFeaturedItem(index)
          )}
        </Wrapper>
      </Root>
    );
  }
}

const Root = styled.div`
  width: 78.125%;
  height: ${vw(490)};
  margin: 0 auto;
  position: relative;
  @media (max-width: ${media.md}) {
    display: none;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${props => props.src});
  background-size: cover;
  background-position: center;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: all 1.5s;
  pointer-events: none;
`;

const FeaturedItem = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  &::before {
    content: '';
    position: absolute;
    width: ${vw(1)};
    min-width: 1px;
    background: #fff;
    height: 80%;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    opacity: 0.5;
  }
  &:first-child::before {
    display: none;
  }
  span {
    display: block;
    font-size: ${vw(10)};
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: ${vw(3)};
  }
  p {
    line-height: 1.625;
  }
`;

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${props => props.background});
`;

const Content = styled.div`
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  white-space: no-wrap;
`;

const MaskContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${props => (props.active ? 'transparent' : 'rgba(0,0,0,0.5)')};
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + ${vw(5)});
    height: calc(100% + ${vw(5)});
    & > g > g {
      fill: ${props => (props.active ? 'rgba(0,0,0,0.5)' : 'transparent')};
    }
    path {
      stroke: ${props => (props.active ? 'transparent' : 'white')};
      stroke-width: ${vw(1)};
      transition: stroke 0.5s;
    }
  }
`;
