import React, { Component } from 'react';
import styled from 'styled-components';
import scrollToElement from 'scroll-to-element';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import Spacing from 'components/Spacing';
import GradientText from 'components/GradientText';

export default class VideoSection extends Component {
  state = {
    isVideoPlaying: false,
  };

  constructor(props) {
    super(props);
    this.$videoPlayer = React.createRef();
  }

  componentDidMount() {
    let $video = this.$videoPlayer.current;
    window.addEventListener('scroll', this.handleScroll);

    $video.addEventListener('ended', () => {
      setTimeout(() => {
        if (window.scrollY <= window.innerHeight && window.innerWidth >= 768) {
          window.scrollTo({
            top: window.innerHeight * 0.75,
            behavior: 'smooth',
          });
        }
      }, 500);
    });
    $video.addEventListener('playing', () => {
      this.setState({
        isVideoPlaying: true,
      });
    });
  }

  handleClickWatchVideoText = () => {
    this.$videoPlayer.current.play();
    scrollToElement('#atelier-video-player');
  };

  handleClickPlay = () => {
    this.setState({
      isVideoPlaying: true,
    });
    this.$videoPlayer.current.play();
  };

  handleScroll = () => {
    if (window.scrollY >= window.innerHeight) {
      this.$videoPlayer.current.pause();
    }
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    return (
      <>
        <Root id="atelier-video-player">
          <Video
            src="https://player.vimeo.com/external/338353605.hd.mp4?s=f79a74a4af2fc39ab1182197a457fbeed2bb4198&profile_id=175"
            controls
            playsInline
            ref={this.$videoPlayer}
          />
          <Background show={!this.state.isVideoPlaying} />
          <CTA show={!this.state.isVideoPlaying}>
            <PlayButton
              src={require('src/assets/images/icons/play.svg')}
              onClick={this.handleClickPlay}
            />
            <Spacing value={20} />
            <p>Now Featuring</p>
            <Spacing value={10} mobile={5} />
            <p>
              <GradientText>Brent Comber</GradientText>
            </p>
          </CTA>
        </Root>
      </>
    );
  }
}

const Root = styled.div`
  position: relative;
  height: calc(100vh - ${vw(50)});
  overflow: hidden;
  @media (max-width: ${media.md}) {
    height: auto;
    padding-top: 56.25%;
  }
`;

const Background = styled.div`
  background: url(${require('src/assets/images/atelier/video_thumb.jpg')});
  background-size: cover;
  background-position: center center;
  opacity: ${props => (props.show ? 1 : 0)};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s;
  pointer-events: none;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media (max-width: ${media.md}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const CTA = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${props => (props.show ? 1 : 0)};
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
  transition: all 1s;
  p {
    font-size: ${vw(13)};
    font-weight: 500;
    letter-spacing: ${vw(3)};
    text-transform: uppercase;
    @media (max-width: ${media.md}), (max-width: ${media.lg}) {
      font-size: ${vw(13)};
    }
    @media (max-width: ${media.sm}) {
      font-size: ${vwMobile(10)};
      letter-spacing: ${vwMobile(3)};
    }
    span {
      font-size: ${vw(30)};
      margin-top: ${vw(0)};
      @media (max-width: ${media.sm}) {
        font-size: ${vwMobile(17)};
      }
    }
  }
`;

const PlayButton = styled.img`
  width: ${vw(95)};
  cursor: pointer;
  @media (max-width: ${media.md}) {
    width: ${vwMobile(45)};
  }
`;
