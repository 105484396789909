import React, { Component } from 'react';
import styled from 'styled-components';

import { vw, vwMobile, vwMobile320, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

import Spacing from 'components/Spacing';
import Exhibition from './Exhibition';

export default class Banner extends Component {
  render() {
    return (
      <Root>
        <BackgroundIcon src={require('src/assets/images/musee/g.svg')} alt="" />
        <Content>
          <div>
            <Spacing value={140} />
            <LogoWrapper>
              <Logo
                src={require('src/assets/images/musee/g-color.png')}
                alt=""
              />
              <LogoText
                src={require('src/assets/images/musee/gryphon-musee-gradient.svg')}
                alt="Gryphon Musee"
              />
            </LogoWrapper>
            <div className="description">
              <p>
                Art lifts the spirits. It inspires innovation. It also elevates
                the world in countless exciting ways. We love art.
              </p>
              <p>
                Gryphon houses an intimate art gallery with rotating exhibitions
                by local artists both established and emerging. We'd love to
                share it with you. Come visit us in Kerrisdale for a free tour.
              </p>
              <p>
                For more information contact us at{' '}
                <a href="mailto:musee@gryphonliving.com">
                  musee@gryphonliving.com
                </a>
              </p>
            </div>
          </div>
          <Exhibition />
        </Content>
      </Root>
    );
  }
}

const Root = styled.div`
  position: relative;
  text-align: center;
  .description {
    width: ${vw(315)};
    margin: 0 auto;
    line-height: 1.625;
    text-align: left;
    @media (max-width: ${media.md}) {
      width: 85%;
      text-align: center;
      margin-top: ${vwMobile(40)};
    }
    @media (min-width: ${media.mdup}) {
      margin-right: ${vw(116)};
      margin-top: ${vw(40)};
    }
  }
  p {
    margin-bottom: ${vwMobile320(20)};
    @media (min-width: ${media.xsup}) {
      margin-bottom: ${vwTablet(20)};
    }
    @media (min-width: ${media.mdup}) {
      margin-bottom: ${vw(20)};
    }
  }
  a {
    color: ${({ theme }) => theme.color.primary};
  }
`;

const Logo = styled.img`
  display: block;
  width: ${vw(120)};
  margin: 0 auto;
  @media (max-width: ${media.md}) {
    width: ${vwMobile(115)};
    margin: 0 auto;
  }
`;

const LogoWrapper = styled.div`
  @media (min-width: ${media.mdup}) {
    width: ${vw(187)};
  }
`;

const LogoText = styled.img`
  display: block;
  width: ${vwMobile320(187)};
  margin: ${vwMobile320(20)} auto 0;
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(187)};
    margin-top: ${vwTablet(20)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(187)};
    margin-top: ${vw(20)};
  }
`;

const Content = styled.div`
  position: relative;
  margin-bottom: ${vwMobile320(40)};
  @media (min-width: ${media.mdup}) {
    display: flex;
    align-items: flex-end;
    padding-left: ${vw(190)};
    margin-bottom: ${vw(200)};
  }
`;

const BackgroundIcon = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 50vw;
  @media (max-width: ${media.md}) {
    transform: translateY(-20%);
    width: 80%;
  }
`;
