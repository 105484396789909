import React, { Component } from 'react';

import Page from 'components/Page';
import WaypointTrigger from 'components/WaypointTrigger';
import FadeUp from 'components/animations/FadeUp';
import Spacing from 'components/Spacing';

import Intro from './sections/Intro';
import Video from './sections/Video';
import Creative from './sections/Creative';
import Atelier from './sections/Atelier';
import Artists from './sections/Artists';
import BannerAtelier from './sections/BannerAtelier';
import Story from './sections/Story';

export default class About extends Component {
  render() {
    return (
      <Page>
        <Intro />
        <Spacing value={200} />
        {[
          <Video />,
          <Spacing value={100} />,
          <Creative />,
          <Atelier />,
          <Artists />,
          <BannerAtelier />,
          <Story />,
        ].map((section, index) => (
          <WaypointTrigger key={index} once>
            {trigger => <FadeUp animate={trigger}> {section} </FadeUp>}
          </WaypointTrigger>
        ))}
      </Page>
    );
  }
}
