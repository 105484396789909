export default [
  {
    title: 'An Interview With Our C...',
    date: 'March 8 2019',
    link: 'https://blog.gryphonliving.com/2019/03/08/international-womens-day/',
  },
  {
    title: 'The Art Of Wine with Ne...',
    date: 'March 5 2019',
    link:
      'https://blog.gryphonliving.com/2019/03/05/gryphon-artisan-series-art-of-wine/',
  },
  {
    title: 'Holiday Wreath Making W...',
    date: 'December 13 2018',
    link:
      'https://blog.gryphonliving.com/2018/12/13/gryphon-artisan-series-wreath-making-workshop/',
  },
];
