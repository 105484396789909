import { combineReducers } from 'redux';
import counter from './counter';
import intl from './intl';
import abTest from './abTest';

const reducer = combineReducers({
  counter,
  intl,
  abTest,
});

export default reducer;
