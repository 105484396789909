import React, { Component } from 'react';

class NotFound extends Component {
  render() {
    return (
      <main>
        <h1>404!</h1>
      </main>
    );
  }
}

export default NotFound;
