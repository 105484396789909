import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import { vwMobile320, vwTablet, vw } from 'src/styles/utils';
import media from 'src/styles/media';

const DoubleGallery = props => {
  const largeGallery = useRef();
  const smallGallery = useRef();
  const textGallery = useRef();

  const [activeIndex, setActiveIndex] = useState(0);

  const settings = {
    fade: true,
    dots: false,
    infinite: true,
    arrows: false,
    swipe: false,
    easing: 'ease-out',
    speed: 1000,
  };

  const handleNext = () => {
    setTimeout(() => largeGallery.current.slickNext(), 150);
    smallGallery.current.slickNext();
    textGallery.current.slickNext();
    if (activeIndex === currentProjects.length - 1) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndex + 1);
    }
  };

  const handlePrev = () => {
    setTimeout(() => largeGallery.current.slickPrev(), 150);
    smallGallery.current.slickPrev();
    textGallery.current.slickPrev();
    if (activeIndex === 0) {
      setActiveIndex(currentProjects.length - 1);
    } else {
      setActiveIndex(activeIndex - 1);
    }
  };

  const { currentProjects } = props;
  return (
    <Root>
      <span className="featuring-artists">Featuring Artists</span>

      <BackgroundColor />
      <TextContainer {...settings} ref={textGallery}>
        {currentProjects.map((item, index) => (
          <div key={index}>
            <h4>{item.title}</h4>
            <p>{item.subtitle}</p>
            <p className="description">{item.description}</p>
            {console.log(item)}
            <a
              href={item.link ? item.link : item.mail}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.link && 'Website'}
              {item.mail && 'Email'}
            </a>
          </div>
        ))}
      </TextContainer>
      <Wrapper>
        <Arrows>
          <img
            src={require('src/assets/images/musee/arrow-slder.svg')}
            alt="Previous"
            onClick={handlePrev}
          />
          <img
            src={require('src/assets/images/musee/arrow-slder.svg')}
            alt="Next"
            className="next"
            onClick={handleNext}
          />
          <p className="description">
            0{activeIndex + 1} | 0{currentProjects.length}
          </p>
        </Arrows>
        <StyledSlider {...settings} ref={smallGallery}>
          {currentProjects.map((item, index) => (
            <Small key={index}>
              <img src={item.smallImage} key={index} alt={item.title} />
            </Small>
          ))}
        </StyledSlider>
      </Wrapper>
      <Slider {...settings} ref={largeGallery}>
        {currentProjects.map((item, index) => (
          <Large key={index}>
            <img src={item.largeImage} key={index} alt={item.subtitle} />
          </Large>
        ))}
      </Slider>
    </Root>
  );
};

export default DoubleGallery;

const Root = styled.div`
  margin-top: ${vwMobile320(40)};
  margin-bottom: ${vwMobile320(-120)};
  position: relative;
  span.featuring-artists {
    position: absolute;
    font-family: 'Gotham';
    font-size: ${vwMobile320(13)};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: ${vwMobile320(1)};
    color: ${({ theme }) => theme.color.background};
    text-transform: uppercase;
    top: ${vwMobile320(72)};
    left: ${vwMobile320(213 - 22)};
    transform: rotate(-90deg);
    white-space: nowrap;
  }
  .slick-slide {
    pointer-events: none;
  }
  .slick-active {
    pointer-events: auto;
  }
  @media (min-width: ${media.xsup}) {
    margin-top: ${vwTablet(60)};
    margin-bottom: ${vwTablet(-320)};
    span.featuring-artists {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(1)};
      top: ${vwTablet(166)};
      left: ${vwTablet(600 - 52)};
    }
  }
  @media (min-width: ${media.mdup}) {
    margin-top: ${vw(60)};
    padding-top: ${vw(60)};
    margin-bottom: ${vw(-500)};
    position: relative;
    width: 100%;
    span.featuring-artists {
      font-size: ${vw(14)};
      letter-spacing: ${vw(1)};
      top: ${vw(145 - 10)};
      left: ${vw(1107 - 223)};
      z-index: 2;
    }
  }
`;

const BackgroundColor = styled.div`
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: ${vw(1060)};
    height: ${vw(435)};
    background-color: rgba(215, 185, 0, 0.8);
    padding-left: ${vw(38)};
    padding-right: ${vw(38)};
    margin-left: ${vw(-40)};
    margin-right: ${vw(-40)};
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: ${media.xsup}) {
    align-items: flex-end;
  }
`;

const Large = styled.div`
  width: 100%;
  height: ${vwMobile320(141)};
  z-index: 1;
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(332)};
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(500)};
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const StyledSlider = styled(Slider)`
  width: ${vwMobile320(160)};
  height: ${vwMobile320(103)};
  margin-bottom: ${vwMobile320(-10)};
  z-index: 2;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);

  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(240)};
    height: ${vwTablet(194)};
    margin-bottom: ${vwTablet(-40)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(435)};
    height: ${vw(350)};
    margin-bottom: ${vw(-40)};
    overflow: visible;
  }
`;

const Small = styled.div`
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Arrows = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: ${vwMobile320(85)};
  margin-right: ${vwMobile320(20)};
  @media (min-width: ${media.xsup}) {
    width: auto;
    flex-wrap: nowrap;
    margin-right: ${vwTablet(172)};
    margin-bottom: ${vwTablet(20)};
  }
  @media (min-width: ${media.mdup}) {
    margin-right: ${vw(307)};
    margin-bottom: ${vw(20)};
    position: relative;
    z-index: 3;
  }
  .next {
    transform: scaleX(-1);
    margin-left: ${vwMobile320(25)};
    @media (min-width: ${media.xsup}) {
      margin-left: ${vwTablet(35)};
    }
    @media (min-width: ${media.mdup}) {
      margin-left: ${vw(35)};
    }
  }
  img {
    width: ${vwMobile320(30)};
    height: ${vwMobile320(17)};
    cursor: pointer;
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(33.7)};
      height: ${vwTablet(19.3)};
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(35)};
      height: ${vw(20)};
    }
  }
  p {
    font-family: ${({ theme }) => theme.font.body};
    font-size: ${vwMobile320(12)};
    font-weight: 500;
    letter-spacing: ${vwMobile320(3)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.background};
    margin-top: ${vwMobile320(24)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(12)};
      letter-spacing: ${vwTablet(3)};
      margin-left: ${vwTablet(30)};
      margin-top: 0;
      width: ${vwTablet(62)};
      white-space: nowrap;
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(12)};
      letter-spacing: ${vw(3)};
      margin-left: ${vw(30)};
      margin-top: 0;
      width: ${vw(64)};
    }
  }
`;

const TextContainer = styled(Slider)`
  position: relative;
  h4 {
    font-family: 'SangBleuEmpire';
    font-size: ${vwMobile320(20)};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${({ theme }) => theme.color.background};
    margin-bottom: ${vwMobile320(1)};
  }
  p:first-of-type {
    margin-bottom: ${vwMobile320(5)};
    font-style: italic;
  }
  p {
    width: ${vwMobile320(240)};
  }
  a {
    display: block;
    margin-top: ${vwMobile320(20)};
    margin-bottom: ${vwMobile320(20)};
    font-family: 'Gotham';
    font-size: ${vwMobile320(14)};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: ${vwMobile320(2)};
    color: ${({ theme }) => theme.color.background};
    text-transform: uppercase;
  }

  @media (min-width: ${media.xsup}) {
    margin-bottom: ${vwTablet(-114)};

    h4 {
      font-size: ${vwTablet(24)};
      margin-bottom: unset;
    }
    p:first-of-type {
      margin-bottom: ${vwTablet(10)};
    }
    p.description {
      width: ${vwTablet(370)};
      font-size: ${vwTablet(15)};
      line-height: 1.4;
    }
    a {
      margin-top: ${vwTablet(19)};
      margin-bottom: ${vwTablet(40)};
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(2)};
    }
  }
  @media (min-width: ${media.mdup}) {
    margin-bottom: ${vw(-257)};

    h4 {
      font-size: ${vw(24)};
      margin-bottom: unset;
    }
    p:first-of-type {
      margin-bottom: ${vw(10)};
    }
    p.description {
      width: ${vw(476)};
      font-size: ${vw(15)};
    }
    a {
      margin-top: ${vw(40)};
      margin-bottom: ${vw(46)};
      font-size: ${vw(14)};
      letter-spacing: ${vw(2)};
      position: relative;
      &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        width: ${vw(81)};
        height: 2px;
        background-color: ${({ theme }) => theme.color.background};
        opacity: 0;
        transition: 0.3s ease;
      }
      &:hover {
        &::after {
          opacity: 1;
        }
      }
    }
  }
`;
