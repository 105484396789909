import React from 'react';

import Page from 'components/Page';
import Hero from './sections/Hero';
import Menu from 'components/RoyalCurator/Menu';
import Intro from './sections/Intro';
import Services from './sections/Services';
import Content from './sections/Content';
import GetinTouch from './sections/GetinTouch';

const RoyalCurator = () => {
  return (
    <Page>
      <Hero />
      <Menu sticky />
      <Intro />
      <Services />
      <Content />
      <GetinTouch />
    </Page>
  );
};

export default RoyalCurator;
