import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

const Background = () => {
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = e => {
    if (e.target.scrollingElement.scrollTop > 100) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  };

  return (
    <Root
      style={{
        transform: hasScrolled ? 'translateY(0)' : 'translateY(-100%)',
      }}
    />
  );
};

const Root = styled.div`
  width: 100vw;
  /* height: ${`calc(100% + ${vwMobile(30)})`}; */
  height: ${vwMobile(85)};
  position: fixed;
  top: ${vwMobile(-15)};
  left: 0;
  z-index: 1;
  mix-blend-mode: multiply;
  background-image: linear-gradient(to bottom, #101e27, #101e27);
  transition: 0.5s ease-in-out;
  pointer-events: none;
  @media (min-width: ${media.xsup}) {
    display: none;
  }
`;

export default Background;
