import React, { Component } from 'react';
import styled from 'styled-components';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import VideoPlayer from 'components/VideoPlayer';

export default class VideoSection extends Component {
  state = {
    showVideo: false,
  };

  handleClickPlay = () => {
    this.setState({
      showVideo: true,
    });
  };

  render() {
    return (
      <Root id="home-video-player">
        <VideoPlayer
          url="https://player.vimeo.com/progressive_redirect/playback/812607378/rendition/1080p/file.mp4?loc=external&signature=30acb148912588d06f9e9b54f9dca5b9c6e67e9aa90becaea7ab7e51658984ab"
          background={require('src/assets/images/home/video-bg.jpg')}
          aspectRatio={50}
          autoPlay
          muted
          videoSrc
        >
          <TextImages>
            <img
              src={require('src/assets/images/home/gryphon-white.svg')}
              alt=""
            />
          </TextImages>
        </VideoPlayer>
      </Root>
    );
  }
}

const Root = styled.div``;

const TextImages = styled.div`
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: ${vw(20)};
  img {
    height: ${vw(30)};
  }
  @media (max-width: ${media.md}) {
    top: 12%;
    img {
      height: ${vwMobile(20)};
    }
  }
  @media (max-width: ${media.sm}) {
    display: none;
  }
`;
