import React, { Component } from 'react';
import styled from 'styled-components';

import Button from 'components/Button';
import Spacing from 'components/Spacing';
import SubTitle from 'components/SubTitle';
import HeroVideo from '../elements/HeroVideo';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

export default class Hero extends Component {
  render() {
    return (
      <>
        <Root id="hero">
          <HeroVideo />
          <Content>
            <SubTitle>GRYPHON x VMO</SubTitle>
            <Spacing value={23} />
            <h1>
              Life in Balance
              <br /> Concert Series
            </h1>
            <Spacing value={68} mobile={28} />
            <Button fill out="https://musee.gryphonliving.com/" id="home-cta">
              LEARN MORE
            </Button>
          </Content>
        </Root>
      </>
    );
  }
}

const Root = styled.div`
  width: 100%;
  height: 95vh;
  max-height: 100vh;
  position: relative;
  background-color: ${({ theme }) => theme.color.background};
  background-size: cover;
  &::after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    pointer-events: none;
  }

  @media (max-width: ${media.md}) {
    background-position: -88% top;
  }

  @media (max-width: ${media.xs}) {
    background-position: -98% top;
    height: 75vh;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: ${vw(55)};
  transform: translateY(-39%);
  z-index: 100;
  @media (max-width: ${media.md}), (max-width: ${media.lg}) {
    /* width:100%; */
    top: auto;
    bottom: 15%;

    transform: translateY(0%);
  }
  @media (max-width: ${media.sm}) {
    left: 0;
    right: 0;
    padding: 0;
    margin: 0 auto;
    text-align: center;
    top: unset;
    transform: unset;
    bottom: ${vwMobile(30)};
  }
  h1 {
    max-width: ${vw(677)};
    font-size: ${vw(65)};
    font-weight: 500;
    line-height: 1.15;
    @media (max-width: ${media.md}), (max-width: ${media.lg}) {
      max-width: 80%;
      font-size: ${vw(100)};
    }
    @media (max-width: ${media.sm}) {
      max-width: 100%;
      font-size: ${vwMobile(28)};
      text-align: center;
      line-height: 1.3;
    }
  }
`;
