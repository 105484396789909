import React, { Component } from 'react';
import styled from 'styled-components';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import PageContentBanner from 'components/PageContentBanner';

export default class Intro extends Component {
  render() {
    return (
      <Root>
        <PageContentBanner
          title="Like Our Homes, We Too Are Here For You Over the Long-term."
          subtitle="Homeowner Care"
        >
          <Content>
            <p>
              Gryphon has elevated customer care to an art form. Every detail of
              your home has been conceived with creativity and crafted for
              longevity. The beauty and ease of use are designed to endure.
            </p>
            <p>Every Gryphon home comes with New Home Warranty which covers:</p>
          </Content>
        </PageContentBanner>
      </Root>
    );
  }
}

const Root = styled.div``;

const Content = styled.div`
  line-height: 1.625;
  p {
    margin-bottom: ${vw(25)};
    @media (max-width: ${media.md}) {
      margin-bottom: ${vwMobile(25)};
    }
  }
`;
