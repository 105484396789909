import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { OnUpdate } from 'rrc';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

import Icon from './elements/Icon';
import Navigation from './elements/Navigation';

class Header extends Component {
  state = {
    isMenuOpen: false,
  };

  handleClickMenu = () => {
    this.setState(state => ({
      isMenuOpen: !state.isMenuOpen,
    }));
  };

  handleRouteChange = () => {
    this.setState({ isMenuOpen: false });
  };

  handleLogo = () => {
    if (this.props.location.pathname.includes('musee')) {
      return require('src/assets/images/musee/g-color.png');
    } else if (
      this.props.location.pathname.includes('royalcurator') &&
      !this.state.isMenuOpen
    ) {
      return require('src/assets/images/curator/royalcurator_logo_goldtulip.png');
    } else {
      return require('src/assets/images/logo/logo.svg');
    }
  };

  render() {
    const { isMenuOpen } = this.state;
    return (
      <Fragment>
        <Root
          hideGradient={
            this.props.location.pathname.includes('musee') ||
            this.props.location.pathname.includes('royalcurator')
          }
          curator={this.props.location.pathname.includes('royalcurator')}
        >
          <Link
            to={
              this.props.location.pathname.includes('royalcurator') &&
              !isMenuOpen
                ? '/royalcurator'
                : '/'
            }
          >
            <Logo
              src={this.handleLogo()}
              alt=""
              curator={this.props.location.pathname.includes('royalcurator')}
            >
              <img src={this.handleLogo()} alt="" />
            </Logo>
          </Link>
          {/* <Icon
            onClick={this.handleClickMenu}
            open={isMenuOpen}
            musee={this.props.location.pathname.includes('musee')}
          /> */}
        </Root>
        <Navigation show={isMenuOpen} />
        <OnUpdate call={this.handleRouteChange} />
      </Fragment>
    );
  }
}

export default withRouter(Header);

const Root = styled.header`
  width: 93%;
  position: fixed;
  top: ${vw(40)};
  left: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9000;
  transform: translateX(-50%);
  pointer-events: none;
  @media (max-width: ${media.md}) {
    top: ${vwTablet(24)};
    align-items: flex-start;
  }
  @media (max-width: ${media.sm}) {
    top: ${vwMobile(15)};
    align-items: center;
  }
  &::before {
    display: none;
    content: '';
    display: none;
    position: absolute;
    top: ${vwMobile(-15)};
    left: 50%;
    width: 100vw;
    height: ${vwMobile(75)};
    transform: translateX(-50%);
    /* background-blend-mode: multiply; */
    /* background-image: ${props =>
      props.curator
        ? ' linear-gradient(to bottom, #101e27, #101e27)'
        : 'linear-gradient(rgb(2, 45, 60), rgba(2, 45, 60, 0))'}; */
        background-image: linear-gradient(rgb(2, 45, 60), rgba(2, 45, 60, 0));
    @media (max-width: ${media.md}) {
      display: ${props => (props.hideGradient ? 'none' : 'block')};
    }
  }
`;

const Logo = styled.div`
  position: relative;
  width: ${vw(48)};
  height: ${vw(59)};
  pointer-events: auto;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: ${vw(48)};
  }
  @media (max-width: ${media.md}) {
    width: ${props => (props.curator ? vwTablet(41) : vwTablet(75))};
    height: ${vwTablet(50)};
    img {
      width: ${props => (props.curator ? vwTablet(41) : vwTablet(75))};
    }
  }
  @media (max-width: ${media.sm}) {
    width: ${vwMobile(40)};
    height: ${vwMobile(42)};
    img {
      width: ${vwMobile(40)};
    }
  }
`;
