export default {
  colorPrimary: '#00d8ff',
  color: {
    primary: '#b78771',
    background: '#022d3c',
    backgroundDark: '#101e27',
    backgroundMedium: '#04212b',
    brownGrey: '#6e6e6e',
    sunflowerYellow: '#fedd00',
    veryLightPink: '#f1e8e6',
    pinkBrown: '#bf7d5c',
  },
  font: {
    header: 'SangBleuEmpire',
    body: 'Gotham',
    curator: 'Recoleta',
  },
};
