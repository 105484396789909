import React, { Component } from 'react';
import { connect } from 'react-redux';

import WaypointTrigger from 'components/WaypointTrigger';
import FadeUp from 'components/animations/FadeUp';
import Spacing from 'components/Spacing';
import Page from 'components/Page';
// import Hero from './sections/Hero';
import HeroCampaign from './sections/HeroCampaign';
import Intro from './sections/Intro';
import Video from './sections/Video';
import FormA from 'src/pages/Atelier/AtelierB/sections/FormTop';
import FormB from 'src/pages/Atelier/AtelierB/sections/FormTopB';
import Social from 'src/pages/Atelier/sections/Social';
import Projects from './sections/Projects';
import News from './sections/News';

class Home extends Component {
  state = {
    isFormModalSuccess: false,
  };

  handleFormSuccess = () => {
    this.setState({ isFormModalSuccess: true });
  };

  render() {
    return (
      <Page>
        {/* <Hero /> */}
        <HeroCampaign />
        {window.innerWidth >= 767 ? (
          <WaypointTrigger once>
            {trigger => (
              <FadeUp animate={trigger}>
                {' '}
                <Intro />
              </FadeUp>
            )}
          </WaypointTrigger>
        ) : (
          <Intro />
        )}

        {[
          <Video />,
          <Social abTest={this.props.abTest} light />,
          <Projects />,
          // <Form />,
          this.props.abTest ? (
            <>
              <FormB onFormSuccess={this.handleFormSuccess} />
            </>
          ) : (
            <>
              <FormA onFormSuccess={this.handleFormSuccess} />
            </>
          ),
          <Spacing value={100} />,
          <News />,
        ].map((section, index) => (
          <WaypointTrigger key={index} once>
            {trigger => <FadeUp animate={trigger}> {section} </FadeUp>}
          </WaypointTrigger>
        ))}
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  abTest: state.abTest.abTest,
});

export default connect(
  mapStateToProps,
  {}
)(Home);
