import React, { useRef } from 'react';
import styled from 'styled-components';

import ScrollingBackground from 'components/RoyalCurator/ScrollingBackground';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

const Intro = () => {
  const $intro = useRef();

  return (
    <Root ref={$intro}>
      <Logo
        src={require('src/assets/images/curator/royalcurator_logo_full.png')}
        alt="Royal Curator"
      />
      <p>
        Introducing a new, elevated way of living where your every need is
        attended to by a team of committed professionals dedicated to giving you
        the one thing that everyone desires more of. Time.
      </p>
      <ScrollingBackground
        src={require('src/assets/images/curator/homepage_intro_bg.jpg')}
        container={$intro}
      />
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${vwMobile(100)} ${vwMobile(15)};
  height: ${vwMobile(800)};
  position: relative;
  p {
    font-family: Recoleta;
    font-size: ${vwMobile(10.5)};
    line-height: 1.9;
    letter-spacing: ${vwMobile(3)};
    text-transform: uppercase;
    text-align: center;
    color: black;
    margin-top: ${vwMobile(32)};
  }
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(120)} ${vwTablet(140)};
    height: 100vh;
    p {
      margin-top: ${vwTablet(60)};
      font-size: ${vwTablet(14)};
      line-height: 1.71;
      letter-spacing: ${vwTablet(4)};
    }
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(164)} ${vw(15)};
    height: ${vw(1080)};
    margin-top: 0;
    p {
      margin-top: ${vw(60)};
      font-size: ${vw(14)};
      letter-spacing: ${vw(4)};
      max-width: ${vw(488)};
    }
  }
  @media (min-width: ${media.textbreak}) {
    p {
      font-size: 18px;
      line-height: 1.78;
      letter-spacing: 5px;
    }
  }
`;

const Logo = styled.img`
  width: ${vwMobile(102)};
  display: block;
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(129)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(129)};
  }
`;

export default Intro;
