import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { withRouter } from 'react-router-dom';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

import data from 'src/data/curatorServices';

const Gallery = props => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isCardActive, setIsCardActive] = useState(false);

  const { cards, handlePopup } = props;

  const $slider = useRef();

  const handleSlideChange = (oldI, newI) => {
    setIsCardActive(false);
    setActiveSlide(newI);
  };

  const handleClick = index => {
    if (index === activeSlide && cards) {
      if (!isCardActive) {
        // hiding the clicked card state on the services page
        // uncomment the following line to bring it back
        // setIsCardActive(true);
      }
    } else if (index === activeSlide) {
      props.history.push('/royalcurator/services');
    } else {
      return;
    }
  };

  const handleIsSecondActive = index => {
    if (activeSlide === 0) {
      if (index === data.length - 1 || index === activeSlide + 1) {
        return true;
      } else {
        return false;
      }
    } else if (activeSlide === data.length - 1) {
      if (index === 0 || index === activeSlide - 1) {
        return true;
      } else {
        return false;
      }
    } else {
      if (index === activeSlide + 1 || index === activeSlide - 1) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleOuterTransformOrigin = index => {
    if (activeSlide === 0 && index === data.length - 2) {
      return 'right';
    } else if (activeSlide === 1 && index === data.length - 1) {
      return 'right';
    } else if (activeSlide === data.length - 1 && index === 1) {
      return 'left';
    } else if (activeSlide === data.length - 2 && index === 0) {
      return 'left';
    } else if (index === activeSlide - 2) {
      return 'right';
    } else if (index === activeSlide + 2) {
      return 'left';
    } else {
      return;
    }
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: cards ? 5 : 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    beforeChange: handleSlideChange,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '200px',
        },
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '68px',
        },
      },
    ],
  };

  return (
    <Root cards={cards}>
      <Logo>
        {data.map((item, index) => (
          <img
            key={index}
            src={item.logo}
            alt={item.title}
            style={{ opacity: activeSlide === index ? 1 : 0 }}
          />
        ))}
      </Logo>
      <Container>
        {cards && <Panel />}
        <Arrow
          src={require('src/assets/images/curator/services/services_arrow_2.svg')}
          alt="Previous"
          onClick={() => $slider.current.slickPrev()}
          cards={cards}
        />
        <Slider {...settings} ref={$slider}>
          {data.map((item, index) => (
            <Slide
              cards={cards}
              key={index}
              active={activeSlide === index}
              onClick={() => handleClick(index)}
              isSecondActive={handleIsSecondActive(index)}
              origin={handleOuterTransformOrigin(index)}
              isCardActive={isCardActive}
            >
              <div>
                <img
                  src={item.image}
                  alt={item.title}
                  className="main"
                  style={{
                    opacity: isCardActive && activeSlide === index ? 0 : 1,
                  }}
                />
                {cards && (
                  <Content
                    active={isCardActive}
                    style={{
                      opacity: isCardActive && activeSlide === index ? 1 : 0,
                      pointerEvents:
                        isCardActive && activeSlide === index ? 'all' : 'none',
                    }}
                  >
                    <h2 className="curator-intro">{item.title}</h2>
                    {item.services.map((service, index) => {
                      if (service.comingsoon) {
                        return (
                          <p className="curator-services coming-soon">
                            Coming Soon
                          </p>
                        );
                      } else {
                        return (
                          <p
                            className="curator-services"
                            onClick={() => handlePopup(service.listIndex)}
                          >
                            {service.title}
                          </p>
                        );
                      }
                    })}
                    <img
                      src={require('src/assets/images/icons/close-icon.svg')}
                      alt="close"
                      className="close"
                      onClick={() => setIsCardActive(false)}
                    />
                  </Content>
                )}
              </div>
            </Slide>
          ))}
        </Slider>
        <Arrow
          src={require('src/assets/images/curator/services/services_arrow_2.svg')}
          alt="Next"
          onClick={() => $slider.current.slickNext()}
          right
          cards={cards}
        />
        {cards && <Panel right />}
      </Container>
      <Title style={{ opacity: isCardActive ? 0 : 1 }}>
        <h2
          className="curator-intro"
          style={{ opacity: 0, pointerEvents: 'none', position: 'static' }}
        >
          placeholder
        </h2>
        {data.map((item, index) => (
          <h2
            key={index}
            className="curator-intro"
            index={index}
            style={{
              opacity: activeSlide === index ? 1 : 0,
              pointerEvents: activeSlide === index ? 'all' : 'none',
            }}
          >
            {item.title}
          </h2>
        ))}
      </Title>
      <Swipe>
        <img
          src={require('src/assets/images/curator/services/swipe_arrow.svg')}
          alt="Swipe"
        />
        <p>Swipe</p>
        <img
          src={require('src/assets/images/curator/services/swipe_arrow.svg')}
          alt="Swipe"
        />
      </Swipe>
    </Root>
  );
};

const Root = styled.div`
  margin: ${vwMobile(30)} 0 ${vwMobile(46)};
  width: 100%;
  .slick-slide {
    padding: 0 ${vwMobile(10)};
  }
  @media (min-width: ${media.xsup}) {
    margin: ${vwTablet(30)} 0 ${vwTablet(143)};
    width: 100%;
    .slick-slide {
      padding: 0 ${vwTablet(25)};
    }
  }
  @media (min-width: ${media.mdup}) {
    margin: ${props => (props.cards ? vw(40) : vw(20))} 0 ${vw(40)};
    width: ${props => (props.cards ? vw(1350) : vw(950))};
    .slick-slide {
      padding: 0 ${props => (props.cards ? vw(0) : vw(16))};
      transition: 0.5s ease;
    }
  }
`;

const Slide = styled.div`
  width: ${vwMobile(134)};
  transform: ${props => (props.active ? 'scale(1)' : 'scale(0.87)')};
  transition: 0.5s ease;
  > div {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .main {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: ${props => (props.isCardActive ? '0.5s' : '1.5s')} ease-in-out;
  }
  &:focus {
    outline: none;
  }
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(285)};
  }
  @media (min-width: ${media.mdup}) {
    cursor: ${props =>
      props.active && !props.isCardActive && !props.cards ? 'pointer' : 'auto'};
    width: ${props => (props.cards ? vw(270) : vw(370))};
    transform: ${props => {
      if (props.active) {
        return 'scale(1)';
      } else if (props.cards && props.isSecondActive) {
        return 'scale(0.8)';
      } else if (props.cards) {
        return 'scale(0.61)';
      } else {
        return 'scale(0.87)';
      }
    }};
    transform-origin: ${props => props.cards && props.origin};
    &:hover {
      .main {
        transform: ${props => props.active && 'scale(1.1)'};
      }
    }
    &:focus {
      outline: none;
    }
  }
`;

const Logo = styled.div`
  width: ${vwMobile(54)};
  height: ${vwMobile(54)};
  margin-bottom: ${vwMobile(-27)};
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  z-index: 2;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.3s ease;
  }
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(90)};
    height: ${vwTablet(90)};
    margin-bottom: ${vwTablet(-45)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(90)};
    height: ${vw(90)};
    margin-bottom: ${vw(-45)};
  }
`;

const Title = styled.div`
  position: relative;
  width: 100%;
  margin-top: ${vwMobile(6)};
  transition: 0.5s ease;
  h2 {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.3s ease;
    color: black;
    text-align: center;
  }
  @media (min-width: ${media.xsup}) {
    margin-top: ${vwTablet(10)};
  }
  @media (min-width: ${media.mdup}) {
    margin-top: ${vw(10)};
  }
`;

const Arrow = styled.img`
  position: absolute;
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
    z-index: 2;
    top: 50%;
    left: ${props => {
      if (props.right) {
        return 'auto';
      } else if (props.cards) {
        return vw(35);
      } else {
        return vw(-87);
      }
    }};
    right: ${props => {
      if (props.right && props.cards) {
        return vw(35);
      } else if (props.right) {
        return vw(-87);
      } else {
        return 'auto';
      }
    }};
    transform: translateY(-50%) ${props => !props.right && 'scaleX(-1)'};
    width: ${vw(17)};
    cursor: pointer;
  }
`;

const Panel = styled.div`
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
    background-color: ${({ theme }) => theme.color.veryLightPink};
    height: 100%;
    width: ${vw(100)};
    position: absolute;
    left: ${props => (props.right ? 'auto' : 0)};
    right: ${props => (props.right ? 0 : 'auto')};
    top: 0;
    pointer-events: none;
    z-index: 1;
  }
`;

const Container = styled.div`
  position: relative;
`;

const Swipe = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${vwMobile(30)};
  p {
    font-size: ${vwMobile(9)};
    letter-spacing: ${vwMobile(2.5)};
    text-transform: uppercase;
    color: black;
    margin: 0 ${vwMobile(20)};
  }
  img:first-of-type {
    transform: scaleX(-1);
  }
  img {
    width: ${vwMobile(82)};
  }
  @media (min-width: ${media.xsup}) {
    margin-top: ${vwTablet(40)};
    p {
      font-size: ${vwTablet(9)};
      letter-spacing: ${vwTablet(2.5)};
      margin: 0 ${vwMobile(20)};
    }
    img {
      width: ${vwTablet(82)};
    }
  }
  @media (min-width: ${media.mdup}) {
    display: none;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.4s ease;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  &:focus {
    outline: none;
  }
  h2 {
    margin-bottom: ${vwMobile(10)};
  }
  p:not(:last-of-type) {
    margin-bottom: ${vwMobile(10)};
  }
  .close {
    width: ${vwMobile(15)};
    height: ${vwMobile(15)};
    display: block;
    position: absolute;
    bottom: ${vwMobile(15)};
    left: 50%;
    transform: translateX(-50%);
  }
  .coming-soon {
    opacity: 0.4;
    text-decoration: none;

    &:hover {
      color: black;
      cursor: auto;
    }
  }
  @media (min-width: ${media.xsup}) {
    h2 {
      margin-bottom: ${vwTablet(20)};
    }
    p:not(:last-of-type) {
      margin-bottom: ${vwTablet(10)};
    }
    .close {
      width: ${vwTablet(15)};
      height: ${vwTablet(15)};
      bottom: ${vwTablet(25)};
    }
  }
  @media (min-width: ${media.mdup}) {
    h2 {
      margin-bottom: ${vw(20)};
    }
    p:not(:last-of-type) {
      margin-bottom: ${vw(10)};
    }
    .close {
      width: ${vw(15)};
      height: ${vw(15)};
      bottom: ${vw(36)};
      cursor: pointer;
    }
    p {
      transition: 0.5s ease-in-out;
      &:hover {
        color: ${({ theme }) => theme.color.pinkBrown};
      }
    }
  }
`;

export default withRouter(Gallery);
