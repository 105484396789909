import React, { useState } from 'react';
import styled from 'styled-components';

import GradientText from 'components/GradientText';

import { vwTablet, vwMobile320, vw } from 'src/styles/utils';
import media from 'src/styles/media';

const IDS = () => {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const $video = React.createRef();

  const handleVideo = () => {
    if (videoPlaying) {
      $video.current.pause();
      setVideoPlaying(false);
    } else {
      $video.current.play();
      setVideoPlaying(true);
    }
  };

  return (
    <Root>
      <div>
        <h3>
          <GradientText>Art Allows Us to Tell Stories</GradientText>
        </h3>
      </div>
      <Wrapper onClick={() => handleVideo()} isPlaying={videoPlaying}>
        <Overlay isPlaying={videoPlaying} />
        <video ref={$video}>
          <source src="https://player.vimeo.com/external/393050248.hd.mp4?s=aef9defe2808cd197223ad7cfb8272e6ded58c92&profile_id=175" />
        </video>

        <div className="overlay" />

        <img
          src={require('src/assets/images/musee/current/play-button.png')}
          alt="play button"
        />
      </Wrapper>
      <img
        src={require('src/assets/images/musee/current/gryphon_x_ids_logos_mobile.svg')}
        alt="Gryphon x Interior Design Show"
        className="mobile-only"
      />
      <img
        src={require('src/assets/images/musee/current/gryphon_x_ids_logos.svg')}
        alt="Gryphon x Interior Design Show"
        className="tablet-desktop-only"
      />
    </Root>
  );
};

export default IDS;

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.background};
  padding: ${vwMobile320(121)} ${vwMobile320(22)} 0;
  @media (min-width: ${media.xsup}) {
    background-color: ${({ theme }) => theme.color.backgroundDark};
    padding: ${vwTablet(322)} ${vwTablet(30)} ${vwTablet(0)};
    margin: 0 ${vwTablet(30)} ${vwTablet(0)};
    display: flex;
    flex-direction: column;
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(500)} ${vw(40)} ${vw(80)};
    width: ${vw(1061)};
    margin: 0 auto;
    flex-direction: column;
  }
  > img {
    display: block;
    width: ${vwMobile320(242)};
    height: auto;
    margin: ${vwMobile320(30)} auto 0;
    &.tablet-desktop-only {
      display: none;
    }
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(349)};
      margin: ${vwTablet(41)} auto ${vwTablet(60)};
      &.mobile-only {
        display: none;
      }
      &.tablet-desktop-only {
        display: block;
        margin-top: ${vwTablet(41)};
      }
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(580)};
      height: ${vw(60)};
      object-fit: contain;
      margin: 0 auto;
    }
  }
  h3 {
    font-family: ${({ theme }) => theme.font.body};
    font-size: ${vwMobile320(12)};
    font-weight: 500;
    letter-spacing: ${vwMobile320(2)};
    text-transform: uppercase;
    text-align: center;
    margin-bottom: ${vwMobile320(50)};
    position: relative;
    &::after {
      content: '';
      height: ${vwMobile320(60)};
      width: ${vwMobile320(2)};
      background-color: #b78771;
      position: absolute;
      left: 50%;
      bottom: ${vwMobile320(-60 - 20)};
      z-index: 4;
    }

    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(3)};
      margin-bottom: ${vwTablet(59)};
      &::after {
        content: '';
        height: ${vwTablet(60)};
        width: ${vwTablet(2)};
        background-color: #b78771;
        position: absolute;
        left: 50%;
        bottom: ${vwTablet(-60 - 20)};
        z-index: 4;
      }
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(14)};
      letter-spacing: ${vw(3)};
      margin-bottom: ${vw(64)};
      &::after {
        content: '';
        height: ${vw(89)};
        width: ${vw(2)};
        background-color: #b78771;
        position: absolute;
        left: 50%;
        bottom: ${vw(-89 - 20)};
        z-index: 4;
      }
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${vwMobile320(155)};
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  img {
    height: ${vwMobile320(53)};
    width: ${vwMobile320(52)};
    object-fit: contain;
    object-position: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: ${props => (props.isPlaying ? 0 : 1)};
    transition: opacity 0.3s ease;
  }

  .overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: ${props => (props.isPlaying ? 0 : 1)};
    transition: opacity 0.3s ease;
    pointer-events: none;
    background-color: rgba(16, 30, 39, 0.3);
  }
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(366)};
    img {
      height: ${vwTablet(53)};
      width: ${vwTablet(52)};
    }
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(553)};
    img {
      height: ${vw(79)};
      width: ${vw(79)};
      cursor: ${props => (props.isPlaying ? 'auto' : 'pointer')};
      z-index: 3;
    }
  }
`;

const Overlay = styled.div`
  background-image: url(${require('src/assets/images/musee/current/gryphon_x_ids_video.jpg')});
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  opacity: ${props => (props.isPlaying ? 0 : 1)};
  transition: opacity 0.3s ease;
`;
