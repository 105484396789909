import React, { Component } from 'react';
import styled from 'styled-components';

import sendData from 'src/utils/sendData';

import Button from 'components/Button';
import Spacing from 'components/Spacing';
import GradientText from 'components/GradientText';
import Input from 'components/Form/elements/Input';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

export default class FormTop extends Component {
  state = {
    isShowOverlay: false,
    isFormSuccess: false,
  };

  constructor(props) {
    super(props);
    this.$nameField = React.createRef();
    this.$emailField = React.createRef();
  }

  handleClickBtn = () => {
    this.setState({ isShowOverlay: true });
  };

  handleClickClose = () => {
    this.setState({ isShowOverlay: false });
  };

  handleSubmitForm = () => {
    this.setState({ isFormSuccess: true });
    this.props.onFormSuccess();
    window.gtag('event', 'Sign Up', {
      event_category: 'GeneralRegistration',
      event_label: 'General_Event_Sign_Up',
      value: 0,
    });

    sendData(this.$nameField.current.value, this.$emailField.current.value);
  };

  render() {
    return (
      <Root>
        <Modal>
          <>
            <FormWrapper show={!this.state.isFormSuccess}>
              <iframe
                title="atelierdummyframemodal"
                width="0"
                height="0"
                border="0"
                name="atelierdummyframemodal"
                id="atelierdummyframemodal"
                style={{
                  width: 0,
                  height: 0,
                  display: 'block',
                  visibility: 'hidden',
                  margin: 0,
                  position: 'absolute',
                }}
              />
              <Form
                id="atelier-form-modal"
                accept-charset="UTF-8"
                method="post"
                target="atelierdummyframemodal"
                action="https://spark.re/gryphon-development/gryphon/register/atelier_registration"
                // action="https://bamcommunications.ca/test"
                onSubmit={this.handleSubmitForm}
              >
                <input name="utf8" type="hidden" value="&#x2713;" />
                <input
                  type="hidden"
                  name="source"
                  id="source"
                  value="Musee Signup - Atelier Campaign - Brent Comber"
                />
                <input
                  type="hidden"
                  name="redirect_success"
                  id="redirect_success"
                  value=""
                />
                <input
                  type="hidden"
                  name="redirect_error"
                  id="redirect_error"
                  value=""
                />
                <input
                  type="text"
                  name="are_you_simulated"
                  id="are_you_simulated"
                  placeholder="Leave this field blank"
                  style={{ display: 'none' }}
                />
                <input
                  type="hidden"
                  name="answers[5906][answers]"
                  value="Brent Comber Musee Exhibition "
                />
                <h2
                  style={{
                    WebkitTextFillColor: 'transparent',
                    WebkitBackgroundClip: 'text',
                  }}
                >
                  Art Brings People Together
                </h2>
                <h3>
                  At Gryphon, we believe art has the ability to inspire, tell
                  stories and bring people together. art plays such an impactful
                  role in our community.
                </h3>
                <Center>
                  <div className="left">
                    <ul>
                      <li>Art Workshops</li>
                      <li>Wine Tasting</li>
                      <li>VIP Events</li>
                      <li>Guided Photowalks</li>
                    </ul>
                  </div>
                  <div className="video">
                    <video
                      src="https://player.vimeo.com/external/350249864.hd.mp4?s=16e1a236848ea4313e8997e275c2c82a820efc60&profile_id=174"
                      autoPlay
                      muted
                      playsInline
                      loop
                    />
                    <div className="background" />
                    <p
                      style={{
                        WebkitTextFillColor: 'transparent',
                        WebkitBackgroundClip: 'text',
                      }}
                    >
                      #ArtLivesHere
                    </p>
                  </div>
                  <div className="right">
                    <ul>
                      <li>Ticket Giveaways</li>
                      <li>Art-Driven Events</li>
                      <li>Art Exhibits</li>
                      <li>Culinary Experiences</li>
                    </ul>
                  </div>
                </Center>
                <h3>Sign up to keep up-to-date on our upcoming events</h3>
                <Spacing value={30} />
                <Fields>
                  <Input
                    name="contact[first_name]"
                    placeholder="Name *"
                    required
                    refDOM={this.$nameField}
                  />
                  <Input
                    name="contact[email]"
                    placeholder="Email *"
                    type="email"
                    required
                    refDOM={this.$emailField}
                  />
                </Fields>
                <StyledButton type="submit" fill>
                  Subscribe
                </StyledButton>
                <Spacing value={20} />
              </Form>
            </FormWrapper>
          </>

          {this.state.isFormSuccess && (
            <ThankYou>
              <div>
                <Spacing value={30} mobile={20} />
                <h3>
                  <GradientText>
                    Thank you for joining our mailing list.
                  </GradientText>
                </h3>
                <Spacing value={20} mobile={20} />
                <p>
                  We deeply appreciate your interest in Gryphon Atelier. We will
                  keep you informed about our art initiatives and upcoming
                  events.
                </p>
                <Spacing value={30} mobile={20} />
              </div>
            </ThankYou>
          )}
        </Modal>
      </Root>
    );
  }
}

const Root = styled.div`
  @media (max-width: ${media.md}) {
    /* display: none; */
  }
`;

const Modal = styled.div`
  background: ${({ theme }) => theme.color.backgroundDark};
  margin: 0 auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: none;
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(10)};
    width: ${vwTablet(688)};
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(10)};
    width: ${vw(1278)};
  }
`;

const Form = styled.form`
  position: relative;
  padding: ${vwMobile(12)} ${vwMobile(37)};
  text-align: center;
  height: 100%;
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(70)} ${vwTablet(50)};
    border: ${vw(1)} solid ${({ theme }) => theme.color.primary};
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(140)} ${vw(48)} ${vw(90)};
  }
  h3 {
    font-weight: normal;
    font-size: ${vwMobile(11)};
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: ${vwMobile(1.5)};
    line-height: 1.83;
    margin: 0 auto;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(16)};
      letter-spacing: ${vwTablet(1.5)};
      line-height: 1.88;
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(18)};
      letter-spacing: ${vw(2.12)};
      max-width: ${vw(818)};
      opacity: 0.8;
      line-height: 1.83;
    }
  }
  h2 {
    font-size: ${vwMobile(28)};
    font-weight: 500;
    line-height: 1.2;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.primary};
    margin: 0 auto ${vwMobile(20)};
    max-width: ${vwMobile(246)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(38)};
      max-width: ${vwTablet(435)};
      margin: 0 auto ${vwTablet(20)};
      background-image: linear-gradient(
        to right,
        #d29379,
        #efc8a2 51%,
        #ecc39e 62%,
        #e5b593 74%,
        #d89e81 88%,
        #d29379
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(44)};
      margin-bottom: ${vw(10)};
      max-width: none;
    }
  }
`;

const FormWrapper = styled.div`
  height: ${props => (props.show ? '100%' : '0')};
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
  overflow: hidden;
`;

const Fields = styled.div`
  width: 100%;
  display: block;
  input {
    width: 100%;
    margin: 0 0 ${vwMobile(25)};
  }
  @media (min-width: ${media.xsup}) {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    margin-bottom: ${vwTablet(40)};
    input {
      margin: 0 ${vwTablet(15)};
    }
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(531)};
    margin-bottom: ${vw(40)};
    input {
      margin: 0 ${vw(15)};
      width: ${vw(231)};
    }
  }
`;

const StyledButton = styled(Button)`
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(16)} ${vwTablet(50)} ${vwTablet(15)};
    font-weight: bold;
    letter-spacing: ${vwTablet(2.75)};
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(16)} ${vw(50)} ${vw(15)};
  }
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: ${vwMobile(40)} 0 ${vwMobile(60)};
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(40)} 0 ${vwTablet(80)};
  }
  @media (min-width: ${media.mdup}) {
    flex-wrap: nowrap;
    padding: ${vw(50)} 0;
  }
  .left {
    text-align: center;
    order: 2;
    width: 100%;
    @media (min-width: ${media.xsup}) {
      margin-right: ${vwTablet(60)};
      width: auto;
      text-align: right;
    }
    @media (min-width: ${media.mdup}) {
      order: 1;
      margin-right: 0;
    }
  }
  .video {
    margin: 0 ${vwMobile(25)} ${vwMobile(17)};
    width: ${vwMobile(246)};
    height: ${vwMobile(246)};
    display: flex;
    align-items: center;
    justify-content: center;
    order: 1;
    position: relative;
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(302)};
      height: ${vwTablet(302)};
      margin: 0 ${vwTablet(123)} ${vwTablet(20)};
    }
    @media (min-width: ${media.mdup}) {
      margin: 0 ${vw(50)};
      width: ${vw(400)};
      height: ${vw(400)};
      order: 2;
    }
  }
  .right {
    text-align: center;
    order: 3;
    width: 100%;
    @media (min-width: ${media.xsup}) {
      width: auto;
      text-align: left;
    }
    @media (min-width: ${media.mdup}) {
    }
  }
  video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
  }
  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${require('src/assets/images/atelier/mask.png')});
    background-size: contain;
  }
  li {
    list-style-type: none;
    font-family: 'Gotham';
    font-size: ${vwMobile(11)};
    line-height: 2;
    letter-spacing: ${vwMobile(1.5)};
    text-transform: uppercase;
    color: white;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(12)};
      letter-spacing: ${vwTablet(2.5)};
      line-height: 2.75;
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(12)};
      letter-spacing: ${vw(2.5)};
    }
  }
  p {
    font-family: 'Gotham';
    font-size: ${vwMobile(16)};
    line-height: 1.06;
    letter-spacing: ${vwMobile(1.78)};
    text-transform: uppercase;
    text-align: center;
    opacity: 0.8;
    color: ${({ theme }) => theme.color.primary};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(24)};
      letter-spacing: ${vwTablet(2.82)};
      line-height: 1.38;
      background-image: linear-gradient(
        to right,
        #e2b08f,
        #efc8a2 25%,
        #ecc39e 53%,
        #e5b593 79%,
        #d89e81
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(24)};
      letter-spacing: ${vw(2.82)};
    }
  }
`;

const ThankYou = styled.div`
  position: relative;
  border: ${vw(1)} solid ${({ theme }) => theme.color.primary};
  padding: ${vw(20)} ${vw(48)};
  text-align: center;
  h3 {
    font-family: 'SangBleuEmpire';
    font-weight: normal;
    color: ${({ theme }) => theme.color.primary};
    letter-spacing: ${vw(3)};
    line-height: 1.25;
    text-align: center;
    max-width: ${vw(650)};
    margin: 0 auto;
    font-size: ${vw(40)};
  }
  p {
    line-height: 1.5;
  }
  @media (max-width: ${media.md}) {
    border: ${vwMobile(1)} solid ${({ theme }) => theme.color.primary};
    padding: ${vwMobile(20)};
    h3 {
      font-size: ${vwMobile(24)};
      letter-spacing: ${vwMobile(3)};
      max-width: 100%;
    }
  }
  & > div {
    width: 80%;
    margin: 0 auto;
  }
`;
