import React from 'react';
import styled from 'styled-components';

import GradientText from 'components/GradientText';

import { vwTablet, vwMobile320, vw } from 'src/styles/utils';
import media from 'src/styles/media';

const BrentComber = () => {
  return (
    <Root>
      <div>
        <h3>
          <GradientText>Featuring</GradientText>
        </h3>
        <img
          src={require('src/assets/images/musee/current/brentcomber_logo.svg')}
          alt="Brent Comber"
        />
      </div>
      <Wrapper>
        <h4>
          “Wood has a humanistic quality to it, and I think that’s why people
          connect to it.”
        </h4>
        <p className="description">
          Brent Comber Originals is an art and design studio that creates
          sculpted objects, functional pieces and designed environments.{' '}
        </p>

        <p className="description">
          Brent, a fourth generation resident of the Pacific Northwest, has a
          body of work that is deeply rooted in the knowledge of, and respect
          for, place. His work has been shown as far as Paris, London, Japan,
          Bora Bora and beyond.{' '}
        </p>
        <a
          href="https://www.brentcomber.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Website
        </a>
      </Wrapper>
    </Root>
  );
};

export default BrentComber;

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.background};
  padding: ${vwMobile320(121)} ${vwMobile320(22)} ${vwMobile320(80)};
  @media (min-width: ${media.xsup}) {
    background-color: ${({ theme }) => theme.color.backgroundDark};
    padding: ${vwTablet(282)} ${vwTablet(30)} ${vwTablet(60)};
    margin: 0 ${vwTablet(30)} ${vwTablet(30)};
    display: flex;
    flex-direction: column;
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(360)} ${vw(44)} ${vw(85)};
    width: ${vw(1063)};
    margin: 0 auto;
    flex-direction: row;
  }
  img {
    width: ${vwMobile320(261)};
    height: auto;
    margin: ${vwMobile320(10)} 0 ${vwMobile320(22)};
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(349)};
      margin: ${vwTablet(20)} 0 ${vwTablet(30)};
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(349)};
      margin: ${vw(20)} 0 0;
    }
  }
  h3 {
    font-family: ${({ theme }) => theme.font.body};
    font-size: ${vwMobile320(13)};
    font-weight: 500;
    letter-spacing: ${vwMobile320(2)};
    text-transform: uppercase;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(3)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(14)};
      letter-spacing: ${vw(3)};
    }
  }
  h4 {
    font-family: ${({ theme }) => theme.font.body};
    font-size: ${vwMobile320(13)};
    font-weight: normal;
    line-height: 1.46;
    letter-spacing: ${vwMobile320(1)};
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.8);
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(16)};
      letter-spacing: ${vwTablet(2)};
      line-height: 1.5;
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(16)};
      letter-spacing: ${vw(2)};
    }
  }
  p {
    color: white;
    margin-top: ${vwMobile320(20)};
    @media (min-width: ${media.xsup}) {
      margin-top: ${vwTablet(30)};
    }
    @media (min-width: ${media.mdup}) {
      margin-top: ${vw(40)};
    }
  }
  a {
    font-family: ${({ theme }) => theme.font.family};
    font-size: ${vwMobile320(13)};
    font-weight: 500;
    letter-spacing: ${vwMobile320(1.5)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.primary};
    margin-top: ${vwMobile320(20)};
    display: block;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(2)};
      margin-top: ${vwTablet(30)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(14)};
      letter-spacing: ${vw(2)};
      margin-top: ${vw(40)};
    }
  }
  .description {
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(16)};
    }
  }
`;

const Wrapper = styled.div`
  @media (min-width: ${media.xsup}) {
    max-width: ${vwTablet(498)};
    align-self: flex-end;
  }
  @media (min-width: ${media.mdup}) {
    max-width: ${vw(516)};
    align-self: flex-start;
    margin-left: ${vw(43)};
    margin-top: ${vw(34)};
  }
`;
