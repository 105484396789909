import React, { useState } from 'react';
import styled from 'styled-components';

import SingleGallery from '../elements/SingleGallery';

import { vw, vwTablet, vwMobile320 } from 'src/styles/utils';
import media from 'src/styles/media';

import events from 'src/data/pastEvents';

const PastEvents = () => {
  const [activePanel, setActivePanel] = useState(null);

  const handleActivePanel = index => {
    if (index === activePanel) {
      setActivePanel(null);
    } else {
      setActivePanel(index);
    }
  };

  return (
    <Root>
      {events.map((item, index) => (
        <Event key={index}>
          <h1>{item.title}</h1>
          <Heading>
            <div>
              <h2>{item.subTitle}</h2>
              <h2>{item.date}</h2>
            </div>
            <Plus
              onClick={() => handleActivePanel(index)}
              active={activePanel === index}
            >
              <span />
              <span />
            </Plus>
          </Heading>
          <Content active={activePanel === index}>
            <p className="item-description">{item.description}</p>
            <Wrapper>
              <SingleGallery images={item.images} />
              <Items>
                {item.aside.map((descriptiveItem, index) => (
                  <div className="item" key={index}>
                    <div>
                      {descriptiveItem.title && (
                        <h3>{descriptiveItem.title}</h3>
                      )}
                      <p>{descriptiveItem.description}</p>
                    </div>
                    <a
                      href={descriptiveItem.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Website
                    </a>
                  </div>
                ))}
              </Items>
            </Wrapper>
          </Content>
        </Event>
      ))}
      <img
        src={require('src/assets/images/musee/past/pastevents_bottom_photo.jpg')}
        alt="Past Events"
        className="bottom-image"
      />
    </Root>
  );
};

export default PastEvents;

const Root = styled.div`
  color: ${({ theme }) => theme.color.background};
  img {
    width: 100%;
  }
  h1 {
    font-size: ${vwMobile320(18)};
    font-family: ${({ theme }) => theme.font.header};
    font-weight: 500;
    line-height: 1.67;
    color: #003345;
    margin: 0 ${vwMobile320(17)} ${vwMobile320(30)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(35)};
      margin: 0 0 ${vwTablet(25)};
      line-height: 1.43;
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(35)};
      margin-bottom: ${vw(25)};
    }
  }
  .item-description {
    margin: 0 ${vwMobile320(17)};
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(575)};
      margin: 0;
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(575)};
    }
  }
  h3 {
    font-family: ${({ theme }) => theme.font.header};
    font-size: ${vwMobile320(16)};
    font-weight: 500;
    line-height: 3.13;
    color: #003950;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(20)};
      line-height: 2.5;
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(20)};
    }
  }
  a {
    font-family: ${({ theme }) => theme.font.body};
    font-size: ${vwMobile320(14)};
    font-weight: 500;
    letter-spacing: ${vwMobile320(2)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.background};
    display: block;
    margin-top: ${vwMobile320(15)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(2)};
      margin-top: ${vwTablet(20)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(14)};
      letter-spacing: ${vw(2)};
      margin-top: ${vw(20)};
      position: relative;
      &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        width: ${vw(81)};
        height: 2px;
        background-color: ${({ theme }) => theme.color.background};
        opacity: 0;
        transition: 0.3s ease;
      }
      &:hover {
        &::after {
          opacity: 1;
        }
      }
    }
  }
  .item {
    margin-top: ${vwMobile320(20)};
    @media (min-width: ${media.xsup}) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: ${vwTablet(303)};
      margin-top: 0;
    }
    @media (min-width: ${media.mdup}) {
      display: block;
      max-width: none;
      margin-bottom: ${vw(40)};
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .bottom-image {
    margin-bottom: ${vw(-480)};
    @media (min-width: ${media.xsup}) {
      margin-bottom: ${vwTablet(-320)};
    }
    @media (min-width: ${media.mdup}) {
      margin-bottom: ${vw(-480)};
    }
  }
`;

const Items = styled.div`
  margin: 0 ${vwMobile320(17)};
  @media (min-width: ${media.xsup}) {
    display: flex;
    justify-content: space-between;
    margin: ${vwTablet(40)} 0 0;
  }
  @media (min-width: ${media.mdup}) {
    display: block;
    margin-top: 0;
  }
`;

const Event = styled.div`
  padding-bottom: ${vwMobile320(30)};
  margin-bottom: ${vwMobile320(30)};
  border-bottom: 1px solid rgba(2, 45, 60, 0.5);
  position: relative;
  &:last-of-type {
    border-bottom: 0;
  }
  @media (min-width: ${media.xsup}) {
    padding-bottom: ${vwTablet(40)};
    margin-bottom: ${vwTablet(45)};
    &:last-of-type {
      padding-bottom: ${vwTablet(75)};
    }
  }
  @media (min-width: ${media.mdup}) {
    padding-bottom: ${vw(40)};
    margin-bottom: ${vw(40)};
    &:last-of-type {
      padding-bottom: ${vw(40)};
    }
  }
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 ${vwMobile320(17)};
  @media (min-width: ${media.xsup}) {
    margin: 0;
  }
`;

const Plus = styled.div`
  position: absolute;
  top: ${vwMobile320(5)};
  right: 0;
  width: ${vwMobile320(30)};
  height: ${vwMobile320(30)};
  cursor: pointer;
  @media (min-width: ${media.xsup}) {
    position: relative;
    width: ${vwTablet(30)};
    height: ${vwTablet(30)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(30)};
    height: ${vw(30)};
  }
  span:first-of-type {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: ${vwMobile320(1.5)};
    width: 100%;
    background-color: ${({ theme }) => theme.color.background};
    border-radius: 0.8px;
    @media (min-width: ${media.xsup}) {
      height: ${vwTablet(1.5)};
    }
    @media (min-width: ${media.mdup}) {
      height: ${vw(1.5)};
    }
  }
  span:last-of-type {
    position: absolute;
    left: 50%;
    top: 0;
    transform: ${props =>
      props.active ? 'translateX(-50%) rotate(90deg)' : 'translateX(-50%)'};
    width: ${vwMobile320(1.5)};
    height: 100%;
    background-color: ${({ theme }) => theme.color.background};
    border-radius: 0.8px;
    opacity: ${props => (props.active ? 0 : 1)};
    transition: 0.3s ease;
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(1.5)};
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(1.5)};
    }
  }
`;

const Content = styled.div`
  margin-top: ${props => (props.active ? vwMobile320(30) : 0)};
  max-height: ${props => (props.active ? '1000px' : 0)};
  transition: 0.5s ease-out;
  overflow: hidden;
  @media (min-width: ${media.xsup}) {
    margin-top: ${props => (props.active ? vwTablet(50) : 0)};
  }
  @media (min-width: ${media.mdup}) {
    margin-top: ${props => (props.active ? vw(50) : 0)};
  }
`;

const Wrapper = styled.div`
  margin-top: ${vwMobile320(20)};
  @media (min-width: ${media.xsup}) {
    margin-top: ${vwTablet(40)};
  }
  @media (min-width: ${media.mdup}) {
    display: flex;
    align-items: center;
    margin-top: ${vw(40)};
  }
`;
