import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import Spacing from 'components/Spacing';
import Input from 'components/Form/elements/Input';
import Select from 'components/Form/elements/Select';
import Consent from 'components/Form/elements/Consent';
import RadioGroup from 'components/Form/elements/RadioGroup';
import Button from 'components/Button';

import sendData from 'src/utils/sendData';

class Form extends Component {
  constructor(props) {
    super(props);
    this.$firstNameField = React.createRef();
    this.$lastNameField = React.createRef();
    this.$emailField = React.createRef();
  }

  handleSubmitForm = () => {
    window.gtag('event', 'Sign Up', {
      event_category: 'CampaignRegistration',
      event_label: 'General_Event_Sign_Up',
      value: 1,
    });

    const firstName = this.$firstNameField.current.value;
    const lastName = this.$lastNameField.current.value;
    const email = this.$emailField.current.value;

    sendData(`${firstName} ${lastName}`, email);
    this.props.history.push('/thankyou');
  };

  render() {
    return (
      <>
        <iframe
          title="registerformdummy"
          width="0"
          height="0"
          border="0"
          name="registerformdummy"
          id="registerformdummy"
          style={{
            width: 0,
            height: 0,
            display: 'block',
            visibility: 'hidden',
            margin: 0,
            position: 'absolute',
          }}
        />
        <Root
          action="https://spark.re/gryphon-development/gryphon/register/gryphon-website-registration"
          accept-charset="UTF-8"
          target="registerformdummy"
          method="post"
          onSubmit={this.handleSubmitForm}
        >
          <input name="utf8" type="hidden" value="&#x2713;" />
          <FlexRow>
            <Input
              name="contact[first_name]"
              required
              placeholder="First Name *"
              refDOM={this.$firstNameField}
            />
            <Input
              name="contact[last_name]"
              required
              placeholder="Last Name *"
              refDOM={this.$lastNameField}
            />
          </FlexRow>
          <FlexRow>
            <Input name="contact[phone]" required placeholder="Phone *" />
            <Input
              name="contact[email]"
              required
              type="email"
              placeholder="Email *"
              refDOM={this.$emailField}
            />
          </FlexRow>
          <FlexRow>
            <Select name="answers[4207][answers][]" defaultValue="">
              <option disabled="disabled" value="">
                I am looking for
              </option>
              <option value="Condo">a Condo</option>
              <option value="Townhome">a Townhome</option>
            </Select>
            <Select name="answers[4208][answers][]" defaultValue="">
              <option disabled="disabled" value="">
                How many bedrooms?
              </option>
              <option value="1">1 Bedroom</option>
              <option value="2">2 Bedroom</option>
              <option value="3">3 Bedroom</option>
              <option value="4">4 Bedroom</option>
            </Select>
          </FlexRow>
          <FlexRow>
            <Select name="answers[4209][answers][]" defaultValue="">
              <option disabled="disabled" value="">
                Price Range
              </option>
              <option value="$1,000,000 - $1,200,000">
                $1,000,000 - $1,200,000
              </option>
              <option value="$1,200,000 - $1,500,000">
                $1,200,000 - $1,500,000
              </option>
              <option value="$1,500,000 - $1,800,000">
                $1,500,000 - $1,800,000
              </option>
              <option value="$1,800,000 - $2,000,000">
                $1,800,000 - $2,000,000
              </option>
              <option value="$2,000,000 +">$2,000,000 +</option>
            </Select>
            <Select name="answers[4210][answers][]" defaultValue="">
              <option disabled="disabled" value="">
                What are you looking to purchase?
              </option>
              <option value="In the next 6 months">In the next 6 months</option>
              <option value="In the next year">In the next year</option>
              <option value="In the next two years">
                In the next two years
              </option>
              <option value="Anytime">Anytime</option>
            </Select>
          </FlexRow>
          <Spacing value={30} />
          <RadioGroup
            name="agent"
            label="Are you a realtor?"
            options={[
              {
                label: 'Yes',
                value: 'true',
              },
              {
                label: 'No',
                value: 'false',
                checked: true,
              },
            ]}
          />
          <Spacing value={30} />
          <Consent />
          <Spacing value={50} />
          <Button type="submit">Submit</Button>
          <Spacing value={100} />
          <input
            type="hidden"
            name="source"
            id="source"
            value="Gryphon Website Registration"
          />
          <input
            type="hidden"
            name="redirect_success"
            id="redirect_success"
            value="https://gryphonliving.com/thankyou"
          />
          <input
            type="hidden"
            name="redirect_error"
            id="redirect_error"
            value="https://gryphonliving.com/register"
          />
          <input
            type="text"
            name="are_you_simulated"
            id="are_you_simulated"
            placeholder="Leave this field blank"
            style={{ display: 'none' }}
          />
        </Root>
      </>
    );
  }
}

export default withRouter(Form);

const Root = styled.form`
  input,
  select {
    width: 100%;
    margin-bottom: ${vw(20)};
    @media (max-width: ${media.md}) {
      margin-bottom: ${vwMobile(20)};
    }
  }
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  & > *,
  & > select,
  & > input {
    width: 47.5%;
  }
  @media (max-width: ${media.md}) {
    display: block;
    & > *,
    & > select,
    & > input {
      width: 100%;
    }
  }
`;
