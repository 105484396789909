import { createGlobalStyle } from 'styled-components';
import theme from './theme';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
  }

  body {
    background: ${theme.color.background};
    font-family: 'Gotham';
    font-size: ${vw(16)};
    color: #fff;
    @media (max-width: ${media.md}) {
      font-size: ${vwMobile(16)};
    }
  }

  .white {
    background: white;
  }

  a,
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    color: ${theme.colorPrimary};
  }

  main {
    text-align: center;
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    height: 100%;
  }

  h1, h2 {
    font-family: 'SangBleuEmpire';
  }

  h1 {
    &.curator {
      font-family: ${theme.font.curator};
      font-size: ${vwMobile(18)};
      font-weight: normal;
      letter-spacing: ${vwMobile(5)};
      text-transform: uppercase;
      @media (min-width: ${media.xsup}) {
        font-size: ${vwTablet(24)};
        letter-spacing: ${vwTablet(7)};
      }
      @media (min-width: ${media.mdup}) {
        font-size: ${vw(24)};
        letter-spacing: ${vw(7)};
      }
      @media (min-width: ${media.textbreak}) {
        font-size: 32px;
        letter-spacing: 9px;
      }
    }
  }

  h2 {
    &.curator {
      font-family: ${theme.font.curator};
      font-size: ${vwMobile(12)};
      font-weight: normal;
      line-height: normal;
      letter-spacing: ${vwMobile(3)};
      @media (min-width: ${media.xsup}) {
        font-size: ${vwTablet(16)};
        line-height: 1.5;
        letter-spacing: ${vwTablet(4.5)};
      }
      @media (min-width: ${media.mdup}) {
        font-size: ${vw(16)};
        letter-spacing: ${vw(4.5)};
      }
      @media (min-width: ${media.textbreak}) {
        font-size: 21px;
        line-height: 1.52;
        letter-spacing: 6px;
      }
    }
    &.curator-intro {
      font-family: ${theme.font.curator};
      font-size: ${vwMobile(10.5)};
      font-weight: normal;
      line-height: 1.9;
      letter-spacing: ${vwMobile(3)};
      text-transform: uppercase;
      @media (min-width: ${media.xsup}) {
        font-size: ${vwTablet(14)};
        letter-spacing: ${vwTablet(4)};
        line-height: 1.71;
      }
      @media (min-width: ${media.mdup}) {
        font-size: ${vw(14)};
        letter-spacing: ${vw(4)};
        line-height: 1.71;
      }
      @media (min-width: ${media.textbreak}) {
        font-size: 18px;
        letter-spacing: 5px;
        line-height: 1.78;
      }
    }
  }

  .curator-services {
    font-family: ${theme.font.curator};
    font-size: ${vwMobile(12)};
    line-height: 2;
    letter-spacing: ${vwMobile(1)};
    text-decoration: underline;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(1)};
      line-height: 1.71;
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(14)};
      letter-spacing: ${vw(1)};
      line-height: 1.71;
      cursor: pointer;
    }
    @media (min-width: ${media.textbreak}) {
      font-size: 18px;
      letter-spacing: 1px;
    }
  }

  @font-face{
    font-family: 'SangBleuEmpire';
    src: url('/assets/fonts/SangBleuEmpire-Medium.woff2') format("woff2"),
      url('/assets/fonts/SangBleuEmpire-Medium.woff') format("woff"); 
    font-style: normal
}

  @font-face {
    font-family: 'BodoniStd-Compressed';
    src: url('/assets/fonts/BodoniStd-BoldCondensed.woff2') format('woff2'),
        url('/assets/fonts/BodoniStd-BoldCondensed.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'DINOT';
    src: url('/assets/fonts/DINOT-Light.woff2') format('woff2'),
        url('/assets/fonts/DINOT-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'DINOT';
    src: url('/assets/fonts/DINOT-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'DINOT';
    src: url('/assets/fonts/DINOT-Medium.woff2') format('woff2'),
        url('/assets/fonts/DINOT-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'DINOT';
    src: url('/assets/fonts/DINOT-Bold.woff2') format('woff2'),
        url('/assets/fonts/DINOT-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham';
    src: url('/assets/fonts/Gotham-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham';
    src: url('/assets/fonts/Gotham-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham';
    src: url('/assets/fonts/Gotham-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham';
    src: url('/assets/fonts/Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Recoleta';
    src: url('/assets/fonts/Recoleta-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recoleta';
    src: url('/assets/fonts/Recoleta-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recoleta';
    src: url('/assets/fonts/Recoleta-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
`;
