import React, { Component } from 'react';
import styled from 'styled-components';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import Page from 'components/Page';
import PageContentBanner from 'components/PageContentBanner';
import SubTitle from 'components/SubTitle';
import Spacing from 'components/Spacing';
import Button from 'components/Button';

const info = [
  {
    title: 'All Enquiries',
    copy: 'info@gryphonliving.com',
    link: 'mailto:info@gryphonliving.com',
  },
  {
    title: 'Office',
    copy: (
      <>
        102-2590 Granville Street,
        <br /> Vancouver, BC V6H 3H1
      </>
    ),
    link:
      'https://www.google.com/maps/place/102-2590+Granville+St,+Vancouver,+BC+V6H+3H1',
  },
  {
    title: 'Contact Number',
    copy: '604 620 8296',
    link: 'tel:604 620 8296',
  },
];

export default class Contact extends Component {
  render() {
    return (
      <Page>
        <PageContentBanner title="Contact Us" heightRatio={1.5}>
          <Content>
            <FlexRow>
              {info.map((item, index) => (
                <Info key={index}>
                  <SubTitle>{item.title}</SubTitle>
                  <p>
                    <a
                      href={item.link}
                      target={item.title === 'Office' ? '_blank' : null}
                    >
                      {item.copy}
                    </a>
                  </p>
                  <Spacing value={30} />
                </Info>
              ))}
            </FlexRow>
            <Spacing value={20} />
            <Map href={info[1].link} target="_blank">
              <img
                src={require('src/assets/images/contact/map.png')}
                alt="map"
              />
              <Spacing value={15} />
              <p>Get Directions</p>
            </Map>
            <Spacing value={75} />
            <Careers>
              <SubTitle>Careers</SubTitle>
              <Spacing value={30} />
              <p>
                If you are interested in joining the Gryphon Team, please submit
                your resume to be included in our search for future candidates.
              </p>
              <Spacing value={30} />
              <Button href="mailto:info@gryphonliving.com">Apply Now</Button>
            </Careers>
          </Content>
        </PageContentBanner>
      </Page>
    );
  }
}

const Content = styled.div``;

const Info = styled.div`
  width: 50%;
  @media (max-width: ${media.md}) {
    width: 100%;
  }
  p {
    margin-top: ${vw(20)};
    @media (max-width: ${media.md}) {
      margin-top: ${vwMobile(20)};
    }
  }
  a {
    color: #fff;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${media.md}) {
    display: block;
  }
`;

const Map = styled.a`
  color: #fff;
  img {
    width: 100%;
  }
  p {
    text-transform: uppercase;
    font-size: ${vw(10)};
    letter-spacing: ${vw(3)};
    @media (max-width: ${media.md}) {
      font-size: ${vwMobile(10)};
      letter-spacing: ${vwMobile(3)};
    }
  }
`;

const Careers = styled.div``;
