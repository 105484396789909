import React from 'react';
import styled from 'styled-components';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import GradientText from './GradientText';

export default function SubTitle(props) {
  return (
    <Root {...props}>
      <GradientText>{props.children}</GradientText>
    </Root>
  );
}

const Root = styled.h3`
  font-size: ${vw(14)};
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: ${vw(3)};
  text-align: ${props => props.align && props.align};
  border-bottom: ${props =>
    props.border ? `${vw(1)} solid ${props.theme.color.primary}` : 'none'};
  padding-bottom: ${props => (props.border ? `${vw(10)}` : 0)};

  @media (max-width: ${media.md}), (max-width: ${media.lg}) {
    font-size: ${vw(25)};
  }
  @media (max-width: ${media.sm}) {
    display: block;
    margin: 0 auto;
    max-width: ${props => (props.noWidth ? 'none' : '50vw')};
    line-height: 1.5;
    font-size: ${vwMobile(13)};
    letter-spacing: ${vwMobile(3)};
  }
`;
