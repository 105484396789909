import React from 'react';
import styled from 'styled-components';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

export default function RadioGroup({ label, options, name, required }) {
  return (
    <Root>
      <Label>{label}</Label>
      {options.map((option, index) => (
        <RadioContainer key={index}>
          <input
            type="radio"
            name={name}
            value={option.value}
            required={required}
            checked={option.checked}
          />
          <RadioBox />
          <Text>{option.label}</Text>
        </RadioContainer>
      ))}
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  font-size: ${vw(14)};
  @media (max-width: ${media.md}) {
    font-size: ${vwMobile(14)};
  }
`;

const Label = styled.span`
  margin-right: ${vw(10)};
  @media (max-width: ${media.md}) {
    display: block;
    margin-right: ${vwMobile(10)};
  }
`;

const RadioBox = styled.span`
  display: inline-block;
  width: ${vw(20)};
  height: ${vw(20)};
  margin-right: ${vw(10)};
  border-radius: 50%;
  border: ${vw(1)} solid ${({ theme }) => theme.color.primary};
  background: transparent;
  transition: background 0.5s;
  @media (max-width: ${media.md}) {
    width: ${vwMobile(20)};
    height: ${vwMobile(20)};
    margin-right: ${vwMobile(10)};
    border: ${vwMobile(1)} solid ${({ theme }) => theme.color.primary};
  }
`;

const RadioContainer = styled.label`
  display: flex;
  align-items: center;
  margin-right: ${vw(10)};
  @media (max-width: ${media.md}) {
    margin-right: ${vwMobile(10)};
  }
  input {
    display: none;
    &:checked ~ ${RadioBox} {
      background: ${({ theme }) => theme.color.primary};
    }
  }
`;

const Text = styled.span``;
