import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import { vw, vwTablet, vwMobile320 } from 'src/styles/utils';
import media from 'src/styles/media';

const SingleGallery = props => {
  const [activeImage, setActiveImage] = useState(0);

  const $gallery = useRef();

  const { images } = props;

  const settings = {
    fade: true,
    dots: false,
    infinite: true,
    arrows: false,
    swipe: false,
  };

  const handleNext = () => {
    $gallery.current.slickNext();
    if (activeImage === props.images.length - 1) {
      setActiveImage(0);
    } else {
      setActiveImage(activeImage + 1);
    }
  };

  const handlePrev = () => {
    $gallery.current.slickPrev();
    if (activeImage === 0) {
      setActiveImage(props.images.length - 1);
    } else {
      setActiveImage(activeImage - 1);
    }
  };

  return (
    <Root>
      <Slider {...settings} ref={$gallery}>
        {images.map((item, index) => (
          <Image key={index}>
            <img src={item} alt="" />
          </Image>
        ))}
      </Slider>
      <Wrapper>
        <Arrows>
          <img
            src={require('src/assets/images/musee/arrow-slder.svg')}
            alt="Previous"
            onClick={handlePrev}
          />
          <img
            src={require('src/assets/images/musee/arrow-slder.svg')}
            alt="Next"
            className="next"
            onClick={handleNext}
          />
        </Arrows>
        <h5>
          0{activeImage + 1} | 0{images.length}
        </h5>
      </Wrapper>
    </Root>
  );
};

export default SingleGallery;

const Root = styled.div`
  width: 100%;
  @media (min-width: ${media.xsup}) {
    width: 100%;
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(585)};
    margin-right: ${vw(30)};
  }
`;

const Image = styled.div`
  width: 100%;
  height: ${vwMobile320(178)};
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(380)};
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(380)};
  }
  img {
    width: 100%;
    height: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${vwMobile320(12.5)};
  @media (min-width: ${media.xsup}) {
    margin-top: ${vwTablet(20)};
  }
  @media (min-width: ${media.mdup}) {
    margin-top: ${vw(20)};
  }
  h5 {
    font-family: ${({ theme }) => theme.font.body};
    font-size: ${vwMobile320(12)};
    font-weight: 500;
    letter-spacing: ${vwMobile320(3)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.background};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(12)};
      letter-spacing: ${vwTablet(3)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(12)};
      letter-spacing: ${vw(3)};
    }
  }
`;

const Arrows = styled.div`
  display: flex;
  .next {
    transform: scaleX(-1);
    margin-left: ${vwMobile320(25)};
    @media (min-width: ${media.xsup}) {
      margin-left: ${vwTablet(35)};
    }
    @media (min-width: ${media.mdup}) {
      margin-left: ${vw(40)};
    }
  }
  img {
    width: ${vwMobile320(30)};
    height: ${vwMobile320(17)};
    cursor: pointer;
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(33.7)};
      height: ${vwTablet(19.3)};
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(35)};
      height: ${vw(20)};
    }
  }
`;
