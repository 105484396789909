import React, { Component } from 'react';
import styled from 'styled-components';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import SubTitle from 'components/SubTitle';
import Spacing from 'components/Spacing';
import Input from 'components/Form/elements/Input';
import TextArea from 'components/Form/elements/TextArea';
import Button from 'components/Button';

export default class FormSection extends Component {
  render() {
    return (
      <Root>
        <Intro>
          <p>
            You can reach us by email at homecare@gryphonliving.com or by phone
            at 604 620 8296. You can also contact us by submitting the following
            form below:
          </p>
          <Spacing value={50} />
          <SubTitle>Service Request Form</SubTitle>
          <Spacing value={90} />
        </Intro>
        <Form
          action="https://gryphonliving.com/gryphonliving-hoc-mailer.php"
          method="POST"
        >
          <FlexRow>
            <Input name="first_name" required placeholder="First Name *" />
            <Input name="last_name" required placeholder="Last Name *" />
          </FlexRow>
          <input
            type="hidden"
            name="retURL"
            value={`${window.location.origin}/thankyou`}
          />
          <FlexRow>
            <Input name="phone1" required placeholder="Primary phone *" />
            <Input name="phone2" placeholder="Secondary phone" />
          </FlexRow>
          <Input name="email" required type="email" placeholder="Email *" />
          <Label>Gryphon Home Address</Label>
          <Spacing value={50} />
          <FlexRow>
            <Input name="unit" placeholder="Unit #" />
            <Input name="street" placeholder="Street" />
          </FlexRow>
          <Input name="city" placeholder="City" />
          <TextArea
            name="comments"
            required
            placeholder="Service Request Summary *"
            rows="5"
          />
          <Spacing value={50} />
          <ButtonContainer>
            <Button type="submit">Submit</Button>
          </ButtonContainer>
          <Spacing value={100} />
        </Form>
      </Root>
    );
  }
}

const Root = styled.div`
  width: ${vw(700)};
  margin: 0 auto;
  @media (max-width: ${media.md}) {
    width: 90%;
  }
`;

const Intro = styled.div`
  text-align: center;
`;

const Form = styled.form`
  text-align: left;
  input {
    margin-bottom: 40px;
    width: 100%;
  }
  textarea {
    width: 100%;
  }
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  & > *,
  & > input {
    width: 47.5%;
  }
  @media (max-width: ${media.md}) {
    display: block;
    & > *,
    & > input {
      width: 100%;
    }
  }
`;

const Label = styled.label`
  color: #92a8b6;
  font-size: ${vw(10)};
  font-weight: 500;
  letter-spacing: ${vw(2)};
  text-transform: uppercase;
  text-align: left;
  @media (max-width: ${media.md}) {
    font-size: ${vwMobile(10)};
    letter-spacing: ${vwMobile(2)};
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
`;
