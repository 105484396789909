import React from 'react';
import styled from 'styled-components';

export default function BannerAtelier() {
  return <Root src={require('src/assets/images/about/story-banner.jpg')} />;
}

const Root = styled.img`
  width: 100%;
`;
