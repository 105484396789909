import React, { Component } from 'react';

import Page from 'components/Page';
import Spacing from 'components/Spacing';
import WaypointTrigger from 'components/WaypointTrigger';
import FadeUp from 'components/animations/FadeUp';

import Intro from './sections/Intro';
import Featured from './sections/Featured';
import FeaturedMobile from './sections/FeaturedMobile';
import Form from './sections/Form';

import { ReactComponent as SVG2 } from 'src/assets/images/homeowner/svg/2.svg';
import { ReactComponent as SVG5 } from 'src/assets/images/homeowner/svg/5.svg';
import { ReactComponent as SVG10 } from 'src/assets/images/homeowner/svg/10.svg';
import { ReactComponent as MaskSVG2 } from 'src/assets/images/homeowner/svg/mask2.svg';
import { ReactComponent as MaskSVG5 } from 'src/assets/images/homeowner/svg/mask5.svg';
import { ReactComponent as MaskSVG10 } from 'src/assets/images/homeowner/svg/mask10.svg';

const featuredItems = [
  {
    background: require('src/assets/images/homeowner/featured-1.jpg'),
    mask: <MaskSVG2 />,
    svg: <SVG2 />,
    copy: (
      <>
        On labour and materials
        <br />
        (some limits apply)
      </>
    ),
  },
  {
    background: require('src/assets/images/homeowner/featured-2.jpg'),
    mask: <MaskSVG5 />,
    svg: <SVG5 />,
    copy: (
      <>
        On the building envelope,
        <br />
        including water penetration
      </>
    ),
  },
  {
    background: require('src/assets/images/homeowner/featured-3.jpg'),
    mask: <MaskSVG10 />,
    svg: <SVG10 />,
    copy: 'On the structure',
  },
];

export default class Homeowner extends Component {
  render() {
    return (
      <Page>
        <Intro />
        <Spacing value={100} mobile={0} />
        {[
          <Featured featuredItems={featuredItems} />,
          <FeaturedMobile featuredItems={featuredItems} />,
          <Spacing value={100} mobile={50} />,
          <Form />,
        ].map((section, index) => (
          <WaypointTrigger key={index} once>
            {trigger => <FadeUp animate={trigger}> {section} </FadeUp>}
          </WaypointTrigger>
        ))}
      </Page>
    );
  }
}
