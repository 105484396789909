import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

import Container from 'components/Container';
import GradientText from 'components/GradientText';

export default function Footer() {
  return (
    <Root>
      <Container>
        <Top>
          <div>
            <h4>
              <GradientText>Get in Touch</GradientText>
            </h4>
            <p>
              <a href="tel:604-620-8296">604 620 8296</a>
              <br />
              <a href="mailto:info@gryphonliving.com">info@gryphonliving.com</a>
            </p>
            <p>
              <a
                href="https://www.google.com/maps/place/102-2590+Granville+St,+Vancouver,+BC+V6H+3H1"
                target="_blank"
                rel="noopener noreferrer"
              >
                102-2590 Granville Street,
                <br /> Vancouver, BC V6H 3H1
              </a>
            </p>
          </div>
          <div>
            <img
              src={require('src/assets/images/footer/art-lives-here.svg')}
              alt=""
            />
          </div>
          <div>
            <img src={require('src/assets/images/logo/footer.svg')} alt="" />
          </div>
        </Top>

        <Middle>
          <div>
            <a
              href="https://www.facebook.com/gryphondevelopmentca/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('src/assets/images/icons/facebook.svg')}
                alt=""
              />
            </a>
            <a
              href="https://www.instagram.com/gryphondevelopment/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('src/assets/images/icons/instagram.svg')}
                alt=""
              />
            </a>
            <a
              href="https://twitter.com/gryphon_dev"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('src/assets/images/icons/twitter.svg')}
                alt=""
              />
            </a>
          </div>
          <div>&copy; {new Date().getFullYear()} Gryphon Development</div>
        </Middle>

        {/* <Bottom>
          <nav>
            <Link to="/">Home</Link>
            <Link to="/projects">Projects</Link>
            <Link to="/about">About</Link>
            <Link to="/homeownercare">Homeowner Care</Link>
            <a href="http://blog.gryphonliving.com/">Gryphon News</a>
            <Link to="/contact">Contact Us</Link>
          </nav>
          <nav>
            <a
              href="/doc/gryphon_privacy_policy.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            <a
              href="/doc/gryphon_terms_of_use.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </a>
          </nav>
        </Bottom> */}
      </Container>
    </Root>
  );
}

const Root = styled.footer`
  padding-top: ${vw(50)};
  background: ${({ theme }) => theme.color.backgroundDark};
  @media (max-width: ${media.md}) {
    padding-top: ${vw(150)};
  }
  @media (max-width: ${media.sm}) {
    padding-top: ${vwMobile(50)};
  }
`;

const Top = styled.div`
  display: flex;
  padding-bottom: ${vw(50)};
  @media (max-width: ${media.md}) {
    display: block;
    padding-bottom: ${vwTablet(50)};
    & > div {
      text-align: center;
    }
  }
  @media (max-width: ${media.sm}) {
    display: block;
    padding-bottom: ${vwMobile(50)};
    & > div {
      text-align: center;
    }
  }
  & > div {
    width: 100%;
    &:first-child {
      h4 {
        font-size: ${vw(13)};
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: ${vw(3)};
        margin-bottom: ${vw(20)};
        @media (max-width: ${media.md}) {
          font-size: ${vwTablet(13)};
          margin-bottom: ${vwTablet(23)};
        }
        @media (max-width: ${media.sm}) {
          font-size: ${vwMobile(13)};
          letter-spacing: ${vwMobile(3)};
          margin-bottom: ${vwMobile(20)};
        }
      }
      a {
        color: #fff;
      }
      p {
        line-height: 1.7;
      }
      p:nth-child(2) {
        margin-bottom: ${vw(10)};
        font-weight: 500;
        font-size: ${vw(11)};
        letter-spacing: ${vw(2.3)};
        text-transform: uppercase;
        @media (max-width: ${media.md}) {
          margin-bottom: ${vwTablet(20)};
          font-size: ${vwTablet(12)};
          letter-spacing: ${vwTablet(1.85)};
        }
        @media (max-width: ${media.sm}) {
          margin-bottom: ${vwMobile(10)};
          font-size: ${vwMobile(11)};
          letter-spacing: ${vwMobile(2.3)};
        }
      }
      p:last-child {
        font-size: ${vw(14)};
        font-weight: 300;
        line-height: 1.57;
        letter-spacing: normal;
        @media (max-width: ${media.md}) {
          font-size: ${vwTablet(13)};
          font-weight: normal;
          text-transform: uppercase;
          letter-spacing: ${vwTablet(1)};
        }
        @media (max-width: ${media.sm}) {
          font-size: ${vwMobile(14)};
        }
      }
    }
    &:nth-child(2) {
      img {
        width: ${vw(170)};
        display: block;
        margin: 0 auto;
      }
      @media (max-width: ${media.md}) {
        margin: ${vwTablet(40)} 0;
        img {
          width: ${vwTablet(180)};
        }
      }
      @media (max-width: ${media.sm}) {
        margin: ${vwMobile(40)} 0;
        img {
          width: ${vwMobile(170)};
        }
      }
    }
    &:last-child {
      text-align: right;
      @media (max-width: ${media.md}) {
        text-align: center;
      }
      img {
        width: ${vw(93)};
        @media (max-width: ${media.md}) {
          width: ${vwTablet(65)};
        }
        @media (max-width: ${media.sm}) {
          width: ${vwMobile(60)};
        }
      }
    }
  }
`;

const Middle = styled.div`
  position: relative;
  padding-bottom: ${vw(40)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${vw(11)};
  font-weight: 500;
  letter-spacing: ${vw(2.3)};
  text-transform: uppercase;
  @media (max-width: ${media.md}) {
    display: block;
    padding-bottom: ${vwTablet(40)};
    font-size: ${vwTablet(10)};
    letter-spacing: ${vwTablet(2)};
  }
  @media (max-width: ${media.sm}) {
    display: block;
    padding-bottom: ${vwMobile(40)};
    font-size: ${vwMobile(11)};
    letter-spacing: ${vwMobile(2.3)};
  }
  /* &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: ${vw(1)};
    width: 100%;
    min-height: 1px;
    background: #b78771;
    @media (max-width: ${media.md}) {
      height: ${vwMobile(1)};
    }
  } */
  & > div {
    @media (max-width: ${media.md}) {
      display: flex;
      justify-content: center;
    }
  }
  & > div:first-child {
    display: flex;

    a {
      display: block;
      margin-right: ${vw(15)};
      &:last-of-type {
        margin-right: 0;
      }
      @media (max-width: ${media.md}) {
        margin-right: ${vwTablet(20)};
      }
      @media (max-width: ${media.sm}) {
        margin-right: ${vwMobile(15)};
      }
      img {
        height: ${vw(20)};
        margin-bottom: ${vw(20)};
        @media (max-width: ${media.md}) {
          margin-bottom: ${vwTablet(40)};
          height: ${vwTablet(20)};
        }
        @media (max-width: ${media.sm}) {
          margin-bottom: ${vwMobile(20)};
          height: ${vwMobile(20)};
        }
        display: block;
      }
    }
  }
`;

const Bottom = styled.div`
  padding: ${vw(50)} 0;
  display: flex;
  justify-content: space-between;
  text-align: left;
  @media (max-width: ${media.md}) {
    display: block;
    padding: ${vwTablet(40)} 0;
    text-align: center;
    nav:first-child {
      margin-bottom: ${vwTablet(10)};
    }
  }
  @media (max-width: ${media.sm}) {
    display: block;
    padding: ${vwMobile(50)} 0;
    text-align: center;
    nav:first-child {
      margin-bottom: ${vwMobile(40)};
    }
  }
  nav {
    font-size: ${vw(11)};
    font-weight: 500;
    line-height: 1.7;
    letter-spacing: ${vw(2.3)};
    text-transform: uppercase;

    a {
      position: relative;
      padding: 0 ${vw(12)};
      display: inline-block;
      color: #fff;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: ${vw(1)};
        min-width: 1px;
        height: 50%;
        background: #fff;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    @media (max-width: ${media.md}) {
      font-size: ${vwTablet(12)};
      letter-spacing: ${vwTablet(1.75)};
      a {
        display: inline-block;
        padding: 0 ${vwTablet(12)};
      }
    }
    @media (max-width: ${media.sm}) {
      font-size: ${vwMobile(11)};
      letter-spacing: ${vwMobile(2.3)};
      a {
        display: block;
        padding: 0;
        &::after {
          display: none;
        }
      }
    }
  }
  nav:first-child {
    a:first-child {
      padding-left: 0;
    }
  }
  nav:last-child {
    a:last-child {
      padding-right: 0;
    }
  }
`;
