import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withRouter } from 'react-router-dom';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

class Button extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    fill: PropTypes.bool,
  };

  static defaultProps = {
    onClick: f => f,
    fill: false,
  };

  handleClick = () => {
    const { onClick, to, out, history } = this.props;
    onClick();
    if (to) {
      history.push(to);
    } else if (out) {
      window.open(out, '_blank');
    }
  };

  render() {
    if (this.props.type === 'submit') {
      return this.props.fill ? (
        <SubmitButtonFill
          {...this.props}
          onClick={this.handleClick}
          type="submit"
        />
      ) : (
        <SubmitButton
          {...this.props}
          onClick={this.handleClick}
          type="submit"
        />
      );
    }
    return this.props.fill ? (
      <RootFill {...this.props} onClick={this.handleClick} />
    ) : (
      <Root {...this.props} onClick={this.handleClick} />
    );
  }
}

const buttonStyles = css`
  display: inline-block;
  border: ${vw(1)} solid ${({ theme }) => theme.color.primary};
  border-radius: ${vw(100)};
  padding: ${vw(12)} ${vw(30)};
  font-size: ${vw(10)};
  font-weight: 500;
  color: #fff;
  letter-spacing: ${vw(2)};
  transition: background 0.33s;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.color.primary};
  }
  @media (max-width: ${media.md}) {
    border: ${vwTablet(1)} solid ${({ theme }) => theme.color.primary};
    border-radius: ${vwTablet(100)};
    padding: ${vwTablet(12)} ${vwTablet(30)};
    font-size: ${vwTablet(10)};
    letter-spacing: ${vwTablet(2)};
  }
  @media (max-width: ${media.sm}) {
    border: ${vwMobile(1)} solid ${({ theme }) => theme.color.primary};
    border-radius: ${vwMobile(100)};
    padding: ${vwMobile(12)} ${vwMobile(30)};
    font-size: ${vwMobile(10)};
    letter-spacing: ${vwMobile(2)};
  }
`;

const buttonFillStyles = css`
  display: inline-block;
  border-radius: ${vw(100)};
  padding: ${vw(16)} ${vw(50)} ${vw(15)};
  font-size: ${vw(12)};
  font-weight: bold;
  color: #fff;
  letter-spacing: ${vw(2)};
  transition: background 1s;
  text-transform: uppercase;
  cursor: pointer;
  background: linear-gradient(to bottom, #eec6a0 0%, #b78771 100%);
  color: #022d3c;
  &:hover {
    background: linear-gradient(to bottom, #b78771 0%, #b78771 100%);
  }

  @media (max-width: ${media.md}) {
    font-size: ${vwTablet(20)};
    font-weight: 500;
    padding: ${vwTablet(37)} ${vwTablet(50)} ${vwTablet(33)};
    letter-spacing: ${vwTablet(3)};
  }
  @media (max-width: ${media.sm}) {
    border: ${vwMobile(1)} solid ${({ theme }) => theme.color.primary};
    border-radius: ${vwMobile(100)};
    padding: ${vwMobile(12)} ${vwMobile(30)};
    font-size: ${vwMobile(10)};
    letter-spacing: ${vwMobile(2)};
  }
`;

const Root = styled.a`
  ${buttonStyles}
`;

const SubmitButton = styled.button`
  ${buttonStyles}
`;

const RootFill = styled.a`
  ${buttonFillStyles}
`;

const SubmitButtonFill = styled.button`
  ${buttonFillStyles}
`;

export default withRouter(Button);
