import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

export default class WaypointTrigger extends Component {
  state = {
    isTriggered: false,
  };

  static propTypes = {
    children: PropTypes.func,
    once: PropTypes.bool,
  };

  static defaultProps = {
    children: f => f,
    once: false,
  };

  handleEnter = () => {
    this.setState({ isTriggered: true });
  };

  handleLeave = () => {
    if (this.props.once) return;
    this.setState({ isTriggered: false });
  };

  render() {
    return (
      <Waypoint
        onEnter={this.handleEnter}
        onLeave={this.handleLeave}
        bottomOffset={this.props.topOffset || '30%'}
        {...this.props}
      >
        <div>{this.props.children(this.state.isTriggered)}</div>
      </Waypoint>
    );
  }
}
