import React, { Component } from 'react';
import styled from 'styled-components';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import Container from 'components/Container';
import Button from 'components/Button';
import Spacing from 'components/Spacing';
import SubTitle from 'components/SubTitle';

export default class History extends Component {
  render() {
    return (
      <Root>
        <Spacing value={120} />
        <h2>Learn More About Our History and the Atelier</h2>
        <Spacing value={35} />
        <Button to="/about">Learn More</Button>
        <Spacing value={120} mobile={80} />
        <SubTitle>Get To Know Revery</SubTitle>
        <Spacing value={40} mobile={20} />
        <ReveryLogo
          src={require('src/assets/images/home/revery.svg')}
          alt="Revery Architecture logo"
        />
        <Spacing value={70} />
        <Container>
          <Revery>
            <ReveryImages>
              <img
                src={require('src/assets/images/home/revery-1.jpg')}
                alt=""
              />
              <img
                src={require('src/assets/images/home/revery-2.jpg')}
                alt=""
              />
            </ReveryImages>
            <ReveryContent>
              <p>
                Formerly Bing Thom Architects, Revery is an internationally
                renowned architecture firm based in Vancouver. Design Principle,
                Venelin Kokalov and his design team have developed a reputation
                globally for creating vanguard spatial experiences for the arts
                such as UBC’s Chan Centre and Washington’s Arena Stage, to the
                new Allard Hall in Edmonton. In Hong Kong, they have created the
                new University of Chicago Centre and the spectacular Xiqu Centre
                on the Kowloon waterfront, which has been recently selected to
                appear on their new one hundred dollar bill.
              </p>
              <p>Learn More About Revery Architecture</p>
              <Button
                href="http://reveryarchitecture.com/"
                target="_blank"
                rel="noreferrer"
              >
                Visit Website
              </Button>
            </ReveryContent>
          </Revery>
          <Spacing value={180} />
        </Container>
      </Root>
    );
  }
}

const Root = styled.div`
  text-align: center;
  h2 {
    color: ${({ theme }) => theme.color.primary};
    font-size: ${vw(46)};
    font-weight: normal;
    text-transform: uppercase;
    line-height: 1.152173913;
    max-width: ${vw(550)};
    margin: 0 auto;
    @media (max-width: ${media.md}) {
      font-size: ${vwMobile(36)};
      max-width: 100%;
    }
  }
`;

const ReveryLogo = styled.img`
  width: ${vw(430)};
  @media (max-width: ${media.md}) {
    width: ${vwMobile(300)};
  }
`;

const Revery = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 90%;
  margin: 0 auto;
  @media (max-width: ${media.md}) {
    width: 100%;
    display: block;
  }
`;

const ReveryImages = styled.div`
  position: absolute;
  width: 50%;
  height: 110%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  img:first-child {
    width: 100%;
  }
  img:last-child {
    width: 50%;
  }
  @media (max-width: ${media.md}) {
    position: static;
    transform: none;
    width: 100%;
    img:last-child {
      width: 100%;
      margin-top: ${vwMobile(30)};
    }
  }
`;

const ReveryContent = styled.div`
  width: 60%;
  padding: ${vw(80)} ${vw(30)} ${vw(30)} ${vw(160)};
  background-color: ${({ theme }) => theme.color.backgroundDark};
  text-align: left;
  p {
    font-size: ${vw(16)};
    line-height: 1.625;
    margin-bottom: ${vw(35)};
  }
  @media (max-width: ${media.md}) {
    width: 100%;
    background: transparent;
    padding: ${vwMobile(25)} 0;
    text-align: center;
    p {
      font-size: ${vwMobile(16)};
      margin-bottom: ${vwMobile(35)};
    }
  }
`;
