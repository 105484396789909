import React from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';
import { vwMobile320, vw, vwTablet } from 'src/styles/utils';

const Exhibition = () => {
  return (
    <Root>
      <div>
        <h3>Free Exhibition</h3>
        <img
          src={require('src/assets/images/musee/above-the-trees-text.png')}
          alt="Above the Trees"
        />
      </div>
      <div>
        <p>
          Featuring Brent Comber
          <br />
          July 19th - October 16th, 2019
        </p>
        <p className="text">Monday to Friday (except holidays)</p>
        <p>11AM - 4PM</p>
        <a
          href="https://www.google.com/maps/place/6111+West+Blvd,+Vancouver,+BC+V6M+3X3/@49.230598,-123.1581158,17z/data=!3m1!4b1!4m5!3m4!1s0x5486737cc83ee6cb:0x92b40f899b73156c!8m2!3d49.230598!4d-123.1559217"
          target="_blank"
          rel="noopener noreferrer"
        >
          6111 West Boulevard.
        </a>
      </div>
    </Root>
  );
};

export default Exhibition;

const Root = styled.div`
  background-image: url(${require('src/assets/images/musee/brent-comber-7464-exhibition.png')});
  background-size: cover;
  background-position: center;
  color: ${({ theme }) => theme.color.background};
  text-transform: uppercase;
  text-align: left;
  font-family: 'Gotham';
  font-weight: normal;
  padding: ${vwMobile320(140)} ${vwMobile320(20)} ${vwMobile320(30)};
  margin-top: ${vwMobile320(40)};
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(114)} ${vwTablet(40)};
    margin-top: ${vwTablet(40)};
    height: ${vwTablet(636)};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-position: top center;
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(625)};
    height: ${vw(636)};
    padding: ${vw(94)} ${vw(40)};
    margin-top: 0;
    flex-direction: row;
    align-items: flex-end;
  }
  h3 {
    font-size: ${vwMobile320(14)};
    letter-spacing: ${vwMobile320(1.58)};
    font-weight: 500;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(15)};
      letter-spacing: ${vwTablet(3.1)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(15)};
      letter-spacing: ${vw(3.1)};
    }
  }
  p {
    font-size: ${vwMobile320(13)};
    letter-spacing: ${vwMobile320(1)};
    width: 100%;
    margin-bottom: 0 !important;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(1)};
      line-height: 1.3;
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(14)};
      letter-spacing: ${vw(1)};
      line-height: 1.4;
    }
  }
  img {
    margin: ${vwMobile320(10)} 0 ${vwMobile320(15)};
    width: ${vwMobile320(154)};
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(180)};
      margin: ${vwTablet(20)} 0 ${vwTablet(40)};
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(170)};
      margin: ${vw(20)} ${vw(42)} 0 0;
    }
  }
  .text {
    max-width: 60%;
    @media (min-width: ${media.mdup}) {
      max-width: none;
    }
  }
  a {
    color: ${({ theme }) => theme.color.background} !important;
    text-decoration: underline;
    font-size: ${vwMobile320(13)};
    letter-spacing: ${vwMobile320(1)};
    display: block;
    margin-top: ${vwMobile320(20)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(1)};
      margin-top: ${vwTablet(40)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(14)};
      letter-spacing: ${vw(1)};
      margin-top: ${vw(20)};
    }
  }
`;
