import cookie from 'cookie-parse';
import LCrypt from 'lcrypt';

export default function sendData(name, email) {
  const cookies = cookie.parse(document.cookie);
  const lcrypt = new LCrypt('aHUyb25wZzU4ZnQ2eG9xOHdmOXh0YWp2bnpmdXZkYWQ=');

  fetch('https://i.bamcommunications.ca/server/public/api/intelligence', {
    method: 'POST',
    mode: 'no-cors',
    body: JSON.stringify({
      name: lcrypt.encode(name),
      email: lcrypt.encode(email),
      guid: lcrypt.encode(cookies._ga),
      ip: '',
      success: 'qwe',
    }),
  });
}
