import React, { Component } from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { hot } from 'react-hot-loader';
import { hotjar } from 'react-hotjar';

import { prerenderStyles } from 'src/plugins/prerender';
import routes from 'src/routes';
import Header from 'components/Header/Header';
import Insight from 'src/plugins/insight';

class App extends Component {
  componentDidMount() {
    prerenderStyles();
    if (!window.__PRERENDERING) {
      hotjar.initialize('1340153', '6');
      // window.abTest = false;
    }
    this.props.history.listen(() => {
      if (this.props.history.location.pathname.includes('musee')) {
        document.body.classList.add('white');
      } else {
        document.body.classList.remove('white');
      }
    });
  }

  generateRoutes() {
    Insight();
    let generatedRoutes = routes.map((route, index) => {
      const { path, component } = route;
      const Component = require(`src/${component}`).default;
      return <Route exact path={path} component={Component} key={index} />;
    });

    return generatedRoutes;
  }

  render() {
    return (
      <Route
        render={({ location }) => (
          <AppWrapper>
            <Header />
            <TransitionGroup>
              <CSSTransition
                key={location.key}
                timeout={location.pathname.includes('musee') ? 1500 : 500}
                classNames={
                  location.pathname.includes('musee') ? 'fade-white' : 'fade'
                }
              >
                <Switch location={location}>
                  {this.generateRoutes()}
                  <Redirect to="/" />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          </AppWrapper>
        )}
      />
    );
  }
}

const AppWrapper = styled.div`
  .fade-enter {
    opacity: 0;
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: all 0.5s;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit.fade-exit-active {
    opacity: 0;
    transition: all 0.5s;
  }
  .fade-white-enter {
    opacity: 0;
    position: relative;
  }
  .fade-white-enter-active {
    opacity: 1;
    transition: opacity 1.5s;
    position: absolute;
    top: 0;
    left: 0;
  }
  .fade-white-exit {
    opacity: 1;
    transition: opacity 1.5s;
  }
  .fade-white-exit-active {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export default withRouter(hot(module)(App));
