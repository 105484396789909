import React, { useRef } from 'react';
import styled from 'styled-components';

import ScrollingBackground from 'components/RoyalCurator/ScrollingBackground';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

const Content = () => {
  const $container = useRef();

  return (
    <Root ref={$container}>
      <Container>
        <h1 className="curator">The art of living</h1>
        <p>
          At Gryphon, the meaning of a home has always been more than its
          location, its design and fittings but the experience that it offers
          for life. We hold a deep belief in the power of art - that it is
          integral to life and therefore a fundamental element in everything we
          build.
        </p>
        <p>
          We support artists and integrate their work into our brand, our design
          and our buildings. Art is what inspires us to create homes that are
          thoughtful, unique and original.
        </p>
        <p>
          Gryphon’s Royal Curator concierge program is our latest manifestation
          of the art of living.{' '}
        </p>
      </Container>
      <ScrollingBackground
        src={require('src/assets/images/curator/homepage_artofliving_bg.jpg')}
        container={$container}
        leftAlign
      />
      <Overlay />
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  height: ${vwMobile(768)};
  padding: ${vwMobile(105)} ${vwMobile(36)};
  color: white;
  text-transform: uppercase;
  text-align: center;
  h1 {
    margin-bottom: ${vwMobile(30)};
  }
  p {
    font-family: ${({ theme }) => theme.font.curator};
    font-size: ${vwMobile(10.5)};
    line-height: 1.52;
    letter-spacing: ${vwMobile(3)};
    margin-bottom: ${vwMobile(10)};
  }
  p:last-of-type {
    margin-bottom: 0;
  }
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(1024)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    h1 {
      margin-bottom: ${vwTablet(40)};
    }
    p {
      font-size: ${vwTablet(14)};
      line-height: 1.57;
      letter-spacing: ${vwTablet(4)};
      margin: 0 auto ${vwTablet(20)};
      max-width: ${vwTablet(445)};
    }
  }
  @media (min-width: ${media.mdup}) {
    height: 100vh;
    min-height: ${vw(700)};
    padding: ${vw(104)} 0 0;
    justify-content: center;
    align-items: flex-end;
    h1 {
      margin-bottom: ${vw(40)};
      width: ${vw(445)};
    }
    p {
      font-size: ${vw(14)};
      line-height: 1.57;
      letter-spacing: ${vw(4)};
      margin: 0 0 ${vw(20)};
      max-width: ${vw(445)};
    }
  }
  @media (min-width: ${media.textbreak}) {
    p {
      font-size: 18px;
      line-height: 1.63;
      letter-spacing: 5px;
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  pointer-events: none;
  z-index: -1;
  @media (min-width: ${media.xsup}) {
    display: none;
  }
`;

const Container = styled.div`
  @media (min-width: ${media.mdup}) {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export default Content;
