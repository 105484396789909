import React from 'react';
import styled from 'styled-components';

import Frame from '../elements/Frame';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

import data from 'src/data/curatorServices';

const ServicesList = props => {
  const { handlePopup } = props;
  return (
    <Root id="list">
      {data.map((category, index) => (
        <Category>
          <h3>{category.title}</h3>
          {category.services.map((item, index) => {
            if (item.comingsoon) {
              return (
                <p className="curator-services coming-soon">Coming Soon</p>
              )
            } else {
              return (
                <p
                  className="curator-services"
                  onClick={() => handlePopup(item.listIndex)}
                >
                  {item.title}
                </p>
              )
            }
          })}
        </Category>
      ))}
      <Frame />
    </Root>
  );
};

const Root = styled.div`
  padding: ${vwMobile(80)} ${vwMobile(37)};
  background-color: ${({ theme }) => theme.color.backgroundDark};
  color: white;
  h3 {
    font-family: ${({ theme }) => theme.font.curator};
    font-size: ${vwMobile(12)};
    font-weight: normal;
    letter-spacing: ${vwMobile(3)};
    text-transform: uppercase;
  }
  p {
    margin-top: ${vwMobile(6)};
  }

  .coming-soon {
    opacity: 0.4;
    text-decoration: none;

    &:hover {
      color: white;
      cursor: auto;
    }
  }
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(120)} ${vwTablet(90)};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: ${vwTablet(52)};
    grid-row-gap: ${vwTablet(60)};
    h3 {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(4)};
      line-height: 1.71;
    }
    p {
      margin-top: ${vwTablet(10)};
    }
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(160)} ${vw(190)};
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: auto;
    grid-row-gap: ${vw(40)};
    position: relative;
    h3 {
      font-size: ${vw(14)};
      letter-spacing: ${vw(4)};
    }
    p {
      margin-top: ${vw(10)};
      transition: 0.5s ease-in-out;
      &:hover {
        color: ${({ theme }) => theme.color.pinkBrown};
      }
    }
  }
  @media (min-width: ${media.textbreak}) {
    h3 {
      font-size: 18px;
      letter-spacing: 5px;
    }
  }
`;

const Category = styled.div`
  margin-bottom: ${vwMobile(40)};
  &:last-of-type {
    margin-bottom: 0;
  }
  @media (min-width: ${media.xsup}) {
    margin-bottom: 0;
  }
`;

export default ServicesList;
