export default {
  xs: '479px',
  sm: '767px',
  md: '991px',
  lg: '1100px',
  xl: '1599px',
  xsup: '480px',
  smup: '768px',
  mdup: '992px',
  lgup: '1200px',
  xlup: '1600px',
  textbreak: '1920px',
};
