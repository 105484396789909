import React, { Component } from 'react';
import styled from 'styled-components';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import Container from 'components/Container';
import SubTitle from 'components/SubTitle';
import Spacing from 'components/Spacing';
import Button from 'components/Button';

import news from 'src/data/latestNews';

export default class News extends Component {
  render() {
    return (
      <Container>
        <Root>
          <SubTitle>News &amp; Updates</SubTitle>
          <Spacing value={40} mobile={20} />
          <MobileLatestText>
            The latest insights from the Gryphon team.
          </MobileLatestText>
          <Spacing value={0} mobile={20} />
          <Wrapper>
            <div>
              <img
                src={require('src/assets/images/home/gryphon-news.jpg')}
                alt=""
              />
              <Spacing value={0} mobile={20} />
            </div>
            <Content>
              <p>The latest insights from the Gryphon team.</p>
              <Spacing value={60} mobile={20} />
              <List>
                {news.map((item, index) => (
                  <Item key={index}>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h4>{item.title}</h4>
                      <p>{item.date}</p>
                    </a>
                  </Item>
                ))}
              </List>
              <Button href="http://blog.gryphonliving.com/" target="_blank">
                View Latest
              </Button>
            </Content>
          </Wrapper>
        </Root>
        <Spacing value={250} mobile={100} />
      </Container>
    );
  }
}

const Root = styled.div``;

const Wrapper = styled.div`
  display: flex;
  & > div {
    width: 50%;
  }
  img {
    width: 100%;
  }
  @media (max-width: ${media.md}) {
    display: block;
    & > div {
      width: 100%;
    }
  }
`;

const Content = styled.div`
  padding-left: 8%;
  @media (max-width: ${media.md}) {
    padding-left: 0;
    p:first-child {
      display: none;
    }
  }
`;

const List = styled.ul`
  list-style: none;
`;

const Item = styled.li`
  margin-bottom: ${vw(40)};
  padding-left: ${vw(30)};
  border-left: ${vw(2)} solid ${({ theme }) => theme.color.primary};
  h4 {
    font-weight: normal;
    line-height: 1.5;
  }
  a {
    color: #fff;
  }
  p {
    font-size: ${vw(10)};
    text-transform: uppercase;
    letter-spacing: ${vw(2)};
    color: #92a8b6;
    opacity: 0.5;
  }
  @media (max-width: ${media.md}) {
    margin-bottom: ${vwMobile(40)};
    padding-left: ${vwMobile(30)};
    border-left: ${vwMobile(2)} solid ${({ theme }) => theme.color.primary};
    p {
      font-size: ${vwMobile(10)};
      letter-spacing: ${vwMobile(2)};
    }
  }
`;

const MobileLatestText = styled.p`
  display: none;
  @media (max-width: ${media.md}) {
    display: block;
  }
`;
