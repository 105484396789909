import React, { Component } from 'react';
import styled from 'styled-components';

import Page from 'components/Page';

import Banner from './sections/Banner';
import Gallery from './sections/Gallery';

export default class Register extends Component {
  render() {
    return (
      <Page>
        <Root>
          <Banner />
          <Gallery />
        </Root>
      </Page>
    );
  }
}

const Root = styled.div`
  background: #fff;
  color: #6e6e6e;
`;
