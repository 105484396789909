import React, { Component } from 'react';
import styled from 'styled-components';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import Container from 'components/Container';
import Spacing from 'components/Spacing';

export default class ArtistsSection extends Component {
  render() {
    return (
      <Container>
        <Root>
          <h2>Artists Contributors</h2>
          <Spacing value={50} mobile={10} />
          <Artists>
            <ul>
              <li>Architects</li>
              <li>Engineers</li>
              <li>Builders</li>
              <li>Millworkers</li>
            </ul>
            <ul>
              <li>Contemporary Artists</li>
              <li>Landscape Designers</li>
              <li>Interiors</li>
              <li>Communications</li>
            </ul>
            <ul>
              <li>Writers</li>
              <li>Painters</li>
              <li>Sculptors</li>
              <li>Musicians</li>
            </ul>
          </Artists>
          <Spacing value={120} mobile={80} />
        </Root>
      </Container>
    );
  }
}

const Root = styled.div`
  text-align: center;
  h2 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.primary};
    font-weight: 400;
    font-size: ${vw(44)};
    @media (max-width: ${media.sm}) {
      font-size: ${vwMobile(28)};
    }
  }
`;

const Artists = styled.div`
  display: flex;
  text-transform: uppercase;
  ul {
    width: 100%;
    list-style: none;
    font-size: ${vw(14)};
    letter-spacing: ${vw(2)};
    line-height: 1.75;
    border-left: ${vw(2)} solid ${({ theme }) => theme.color.primary}};
  }
  ul:first-child {
    border-left: none;
  }
  @media (max-width: ${media.sm}) {
    display: block;
    ul {
      border: none;
      border-bottom: ${vwMobile(2)} solid ${({ theme }) => theme.color.primary};
      padding: ${vwMobile(20)} 0;
      font-size: ${vwMobile(14)};
      &:last-child {
        border: none;
      }
    }
  }
`;
