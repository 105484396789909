import React, { Component } from 'react';
import styled from 'styled-components';
import { FacebookShareButton } from 'react-share';

import GradientText from 'components/GradientText';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

export default class Social extends Component {
  render() {
    return (
      <>
        {/* {!this.props.noSpacing && <Spacing value={157} mobile={80} />} */}
        <Root abTest={this.props.abTest} light={this.props.light}>
          <h3>
            <GradientText>
              {this.props.abTest ? 'Spread the Word' : 'Share the Story'}
            </GradientText>
          </h3>
          {/* <a
            href="https://www.facebook.com/gryphondevelopmentca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require('src/assets/images/icons/facebook.svg')}
              alt="Facebook"
            />
          </a> */}
          <FacebookShareButton
            url={`https://gryphonliving.com/${
              this.props.atelierB ? 'atelier-gryphon?_' : 'ateliergryphon'
            }`}
            quote={`We believe the Artist can profoundly impact the way we build homes. Learn more about Brent Comber’s collaboration with @gryphon_dev https://gryphonliving.com/${
              this.props.atelierB ? 'atelier-gryphon' : 'ateliergryphon'
            } #ARTLIVESHERE #BrentComber #Vancouverartist`}
          >
            <img
              src={require('src/assets/images/icons/facebook.svg')}
              alt="Facebook"
            />
          </FacebookShareButton>
          <a
            href={`https://twitter.com/intent/tweet?text=We believe the Artist can profoundly impact the way we build homes. Learn more about Brent Comber’s collaboration with @gryphon_dev https://gryphonliving.com/${
              this.props.atelierB ? 'atelier-gryphon' : 'ateliergryphon'
            }&hashtags=ARTLIVESHERE,BrentComber,Vancouverartist&url=https://gryphonliving.com/${
              this.props.atelierB ? 'atelier-gryphon?_' : 'ateliergryphon'
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require('src/assets/images/icons/twitter.svg')}
              alt="Instagram"
            />
          </a>
        </Root>
      </>
    );
  }
}

const Root = styled.div`
  display: flex;
  justify-content: center;
  padding: ${props => (props.abTest ? 0 : vw(87))} 0 ${vw(150)};
  background-color: ${props => {
    if (props.light) {
      return 'transparent';
    } else if (props.abTest) {
      return props.theme.color.backgroundMedium;
    } else {
      return 'transparent';
    }
  }};
  margin-bottom: 0;
  &::after {
    content: '';
    display: ${props => (props.abTest ? 'block' : 'none')};
    position: absolute;
    left: 50%;
    bottom: ${vw(0)};
    transform: translateX(-50%) translateY(50%);
    width: ${vw(1)};
    min-width: 1px;
    height: ${vwMobile(50)};
    background: ${({ theme }) => theme.color.primary};
    margin: 0 auto;
    margin-top: ${vw(15)};
    @media (min-width: ${media.xsup}) {
      height: ${vwTablet(120)};
    }
    @media (min-width: ${media.mdup}) {
      height: ${vw(80)};
    }
  }
  h3 {
    font-weight: 500;
    text-transform: uppercase;
    font-size: ${vw(14)};
    letter-spacing: ${vw(3)};
    margin-right: ${vw(30)};
  }
  a {
    padding: 0 ${vw(30)};
  }
  img {
    height: ${vw(20)};
  }
  @media (max-width: ${media.md}) {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => {
      if (props.light) {
        return 'transparent';
      } else if (props.abTest) {
        return props.theme.color.backgroundMedium;
      } else {
        return 'transparent';
      }
    }};
    padding: ${props => (props.abTest ? 0 : vwTablet(67))} 0 ${vwTablet(140)};
    margin-bottom: ${props => props.abTest && vwTablet(100)};
    h3 {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(3)};
      margin-right: ${vwTablet(30)};
      margin-bottom: 0;
    }
    a {
      padding: 0 ${vwTablet(30)};
    }
    img {
      height: ${vwTablet(20)};
    }
  }
  @media (max-width: ${media.xs}) {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props =>
      props.light ? 'transparent' : props.theme.color.backgroundDark};
    padding: ${vwMobile(40)} 0 ${vwMobile(60)};
    h3 {
      font-size: ${vwMobile(11)};
      letter-spacing: ${vwMobile(2)};
      line-height: 1.55;
      margin-right: ${vwMobile(20)};
    }
    a {
      padding: 0 ${vwMobile(15)};
    }
    img {
      height: ${vwMobile(20)};
    }
  }
`;
