import React, { Component } from 'react';
import styled from 'styled-components';
import { animateScroll as scroll } from 'react-scroll';
import { Redirect } from 'react-router-dom';

import Page from 'components/Page';
import Hero from './sections/Hero';
import ExtraContent from './sections/ExtraContent';

import data from 'src/data/musee';

import media from 'src/styles/media';
import { vw, vwTablet, vwMobile320 } from 'src/styles/utils';

class MuseeSections extends Component {
  state = {
    activeSection: null,
    isDropdownOpen: false,
    currentHero: null,
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ activeSection: this.props.match.params.id });
    } else {
      this.setState({ activeSection: Object.values(data)[0].id });
    }

    this.renderHero();

    // psuedo-lazy load to try to improve the loading time of background images of each section
    Object.values(data).forEach(image => {
      let img = new Image();
      img.src = image.hero;
    });
  }

  handleActiveSection = id => {
    scroll.scrollToTop({ smooth: true });
    return this.props.history.push(`/musee/${id}`);
  };

  handleDropdown = () =>
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen });

  renderExtraContent = () => {
    if (!this.props.match.params.id && Object.values(data)[0].extraContent) {
      return Object.values(data)[0].extraContent;
    } else if (
      this.props.match.params.id &&
      data[this.props.match.params.id].extraContent
    ) {
      return data[this.props.match.params.id].extraContent;
    } else {
      return <ExtraContent />;
    }
  };

  renderHero = () => {
    let currentHero;
    if (
      this.props.match.params.id &&
      typeof data[this.props.match.params.id].hero === 'object'
    ) {
      currentHero =
        data[this.props.match.params.id].hero[
          Math.floor(
            Math.random() * data[this.props.match.params.id].hero.length
          )
        ];
    } else if (this.props.match.params.id) {
      currentHero = data[this.props.match.params.id].hero;
    } else {
      if (typeof Object.values(data)[0].hero === 'object') {
        currentHero = Object.values(data)[0].hero[0];
      } else {
        currentHero = Object.values(data)[0].hero;
      }
    }

    this.setState({ currentHero });
  };

  render() {
    const { match } = this.props;
    return (
      <Page>
        <Root>
          <Hero
            image={
              this.state.currentHero
              // match.params.id
              //   ? this.state.currentHero
              //   : Object.values(data)[0].hero
            }
            component={
              match.params.id
                ? data[match.params.id].component
                : Object.values(data)[0].component
            }
            title={
              match.params.id
                ? data[match.params.id].title
                : Object.values(data)[0].title
            }
            cover={
              match.params.id
                ? data[match.params.id].cover
                : Object.values(data)[0].cover
            }
            categories={Object.values(data)}
            activeSection={this.state.activeSection}
            handleActiveSection={this.handleActiveSection}
            handleDropdown={this.handleDropdown}
            isDropdownOpen={this.state.isDropdownOpen}
            heroGradient={
              match.params.id
                ? data[match.params.id].heroGradient
                : Object.values(data)[0].heroGradient
            }
          />
          {this.renderExtraContent()}
        </Root>
      </Page>
    );
  }
}

export default MuseeSections;

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.background};
  padding-bottom: ${vwMobile320(40)};
  @media (min-width: ${media.xsup}) {
    padding-bottom: ${vwTablet(60)};
  }
  @media (min-width: ${media.mdup}) {
    padding-bottom: ${vw(120)};
  }
  h2 {
    font-family: ${({ theme }) => theme.font.body};
    font-size: ${vwMobile320(13)};
    font-weight: normal;
    letter-spacing: ${vwMobile320(1)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.background};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(2)};
      line-height: 1.5;
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(14)};
      letter-spacing: ${vw(2)};
    }
  }
  p {
    font-family: ${({ theme }) => theme.font.body};
    font-size: ${vwMobile320(13)};
    line-height: 1.38;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(16)};
      line-height: 1.57;
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(16)};
    }
  }
`;
