import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';

const ScrollingBackground = props => {
  const { src, container, leftAlign, topLeft } = props;

  const [scale, setScale] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = e => {
    if (
      container.current.offsetTop <
      e.srcElement.scrollingElement.scrollTop <
      container.current.offsetTop + container.current.offsetHeight
    ) {
      setScale(
        e.srcElement.scrollingElement.scrollTop - container.current.offsetTop
      );
    }
  };

  return (
    <Root leftAlign={leftAlign} topLeft={topLeft}>
      <img
        src={src}
        alt=""
        style={{ transform: `scale(${1.15 - scale * 0.0001})` }}
      />
    </Root>
  );
};

const Root = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: ${props => {
      if (props.leftAlign) {
        return '10%';
      } else if (props.topLeft) {
        return 'top left';
      } else {
        return 'center';
      }
    }};
  }
  @media (min-width: ${media.xsup}) {
    img {
      object-position: ${props => (props.topLeft ? 'top left' : 'center')};
    }
  }
`;

export default ScrollingBackground;
