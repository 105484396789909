import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

import media from 'src/styles/media';

export default class VideoPlayer extends Component {
  state = {
    showVideo: false,
  };

  static propTypes = {
    url: PropTypes.string,
    background: PropTypes.string,
    aspectRatio: PropTypes.number,
  };

  handleClickPlay = () => {};

  render() {
    return (
      <Root aspectRatio={56} id="HeroVideo">
        <VideoContainer>
          {/* <ReactPlayer
            url="https://player.vimeo.com/progressive_redirect/playback/821319828/rendition/1080p/file.mp4?loc=external&signature=f104d77b3abaf05517ba0cc8cd0a8e7e5a01f2979b75604b9643d3a5aee7ae75"
            playing
            width="100%"
            height="100%"
            loop
            muted
            playsinline
          /> */}
          <video
            src="https://player.vimeo.com/progressive_redirect/playback/821319828/rendition/1080p/file.mp4?loc=external&signature=f104d77b3abaf05517ba0cc8cd0a8e7e5a01f2979b75604b9643d3a5aee7ae75"
            muted
            loop
            autoPlay
            playsInline
          />
        </VideoContainer>
      </Root>
    );
  }
}

const Root = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  video {
    object-fit: cover;
    object-position: 50% 50%;
  }
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  video {
    width: 100%;
    height: 100%;
  }
  @media (max-width: ${media.md}) {
    /* display: none; */
  }
`;
