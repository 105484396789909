import React from 'react';
import styled from 'styled-components';

import Footer from 'components/Footer';

export default function Page({ children }) {
  return (
    <Root>
      {children}
      <Footer />
    </Root>
  );
}

const Root = styled.div``;
