import React, { Component } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

import { vw } from 'src/styles/utils';

import Spacing from 'components/Spacing';
import GradientText from 'components/GradientText';

export default class VideoSection extends Component {
  state = {
    showVideo: false,
  };

  handleClickPlay = () => {
    this.setState({
      showVideo: true,
    });
  };

  render() {
    return (
      <Root>
        {this.state.showVideo && (
          <VideoContainer>
            <ReactPlayer
              url="https://player.vimeo.com/video/294474148"
              playing
              width="100%"
              height="100%"
            />
          </VideoContainer>
        )}
        <VideoThumbnail show={!this.state.showVideo} />
        <CTA show={!this.state.showVideo}>
          <TextImages>
            <img
              src={require('src/assets/images/home/gryphon-white.svg')}
              alt=""
            />
            <img src={require('src/assets/images/home/icon-plus.svg')} alt="" />
            <img src={require('src/assets/images/home/revery.svg')} alt="" />
          </TextImages>
          <Spacing value={25} />
          <PlayButton
            src={require('src/assets/images/icons/play.svg')}
            onClick={this.handleClickPlay}
          />
          <Spacing value={20} />
          <p>
            <GradientText>Play Video</GradientText>
          </p>
        </CTA>
      </Root>
    );
  }
}

const Root = styled.div`
  position: relative;
  &::after {
    content: '';
    display: block;
    padding-top: 56.25%;
    background: #ccc;
  }
`;

const VideoThumbnail = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${require('src/assets/images/home/video-bg.jpg')});
  background-size: cover;
  background-position: center;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: all 1s;
  pointer-events: none;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }
`;

const CTA = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${props => (props.show ? 1 : 0)};
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
  transition: all 1s;
  p {
    font-size: ${vw(13)};
    font-weight: 500;
    letter-spacing: ${vw(3)};
    text-transform: uppercase;
  }
`;

const TextImages = styled.div`
  display: flex;
  align-items: flex-start;
  img {
    height: ${vw(30)};
    margin: 0 ${vw(10)};
    &:last-child {
      height: ${vw(42)};
    }
  }
`;

const PlayButton = styled.img`
  width: ${vw(95)};
  cursor: pointer;
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
