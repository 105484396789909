import React, { useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import ScrollingBackground from 'components/RoyalCurator/ScrollingBackground';
import Tagline from '../elements/Tagline';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

const GetinTouch = () => {
  const $container = useRef();

  return (
    <Root ref={$container}>
      <Tagline />
      <StyledLink to="/royalcurator/contact">
        <h2 className="curator">contact your royal curator</h2>
        <span />
      </StyledLink>
      <ScrollingBackground
        src={require('src/assets/images/curator/homepage_contact_bg.jpg')}
        container={$container}
      />
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0 ${vwMobile(12)};
  span {
    width: ${vwMobile(130)};
    height: 1px;
    background-color: white;
    display: block;
    margin-top: ${vwMobile(6)};
  }
  @media (min-width: ${media.xsup}) {
    padding: 0 ${vwTablet(32)};
    span {
      width: ${vwTablet(220)};
      margin-top: ${vwTablet(6)};
    }
  }
  @media (min-width: ${media.mdup}) {
    min-height: ${vw(700)};
    padding: ${vw(104)} 0 0;
    span {
      width: ${vw(220)};
      margin-top: ${vw(6)};
      transition: 0.5s ease-in-out;
    }
  }
`;

const StyledLink = styled(Link)`
  margin-top: ${vwMobile(10)};
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-transform: uppercase;
  @media (min-width: ${media.xsup}) {
    margin-top: ${vwTablet(7)};
  }
  @media (min-width: ${media.mdup}) {
    margin-top: ${vw(7)};
    &:hover span {
      width: 100%;
    }
  }
`;

export default GetinTouch;
