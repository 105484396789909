import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import media from 'src/styles/media';
import { vw, vwMobile, vwTablet } from 'src/styles/utils';

import Button from 'components/Button';

export default class Navigation extends Component {
  render() {
    const { show } = this.props;
    return (
      <Root show={show}>
        <Background show={show} />
        <div>
          <Nav show={show}>
            <Link to="/">Home</Link>
            <Link to="/projects">Projects</Link>
            <Link to="/about">About</Link>
            <Link to="/homeownercare">Homeowner Care</Link>
            <a href="http://blog.gryphonliving.com/">Gryphon News</a>
            <Link to="/contact">Contact Us</Link>
            <Social>
              <a
                href="https://www.facebook.com/gryphondevelopmentca/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('src/assets/images/icons/facebook.svg')}
                  alt=""
                />
              </a>
              <a
                href="https://www.instagram.com/gryphondevelopment/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('src/assets/images/icons/instagram.svg')}
                  alt=""
                />
              </a>
              <a
                href="https://twitter.com/gryphon_dev"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('src/assets/images/icons/twitter.svg')}
                  alt=""
                />
              </a>
            </Social>
            <div>
              <ButtonContainer>
                <Button to="/register">Register</Button>
              </ButtonContainer>
            </div>
            <Wrapper>
              <Musee>
                <a href="https://musee.gryphonliving.com/">
                  <img
                    src={require('src/assets/images/musee/gryphon-musee-gradient.svg')}
                    alt=""
                  />
                </a>
              </Musee>
              <Curator>
                <Link to="/royalcurator">
                  <img
                    src={require('src/assets/images/curator/royalcurator_logo_gold.png')}
                    alt="Royal Curator"
                  />
                </Link>
              </Curator>
            </Wrapper>
          </Nav>
        </div>
      </Root>
    );
  }
}

const transitionDelay = 0.075;

const Root = styled.div`
  position: fixed;
  z-index: 8000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.color.background};
  display: flex;
  & > div {
    width: 100%;
  }
  opacity: ${props => (props.show ? 1 : 0)};
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
  transition: opacity 1s;
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  pointer-events: none;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
      ${props => (props.show ? 'scale(1.25)' : 'scale(1)')};
    background: url(${require('src/assets/images/menu-bg.jpg')});
    background-position: center;
    background-size: cover;
    transition: all ${props => (props.show ? 45 : 5)}s;
  }
  @media (max-width: ${media.md}) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const Nav = styled.nav`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-transform: uppercase;
  & > a {
    position: relative;
    margin-bottom: ${vw(35)};
    display: block;
    font-weight: 500;
    font-size: ${vw(14)};
    letter-spacing: ${vw(2.93)};
    color: ${({ theme }) => theme.color.primary};
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      &:after {
        content: '';
        display: block;
        position: absolute;
        min-height: 1px;
        height: ${vw(1)};
        background: ${({ theme }) => theme.color.primary};
        width: 100%;
        transform: scaleX(0);
        transition: all 0.5s;
        bottom: ${vw(-7)};
      }
      &:hover {
        &:after {
          transform: scaleX(1);
        }
      }
    }
  }
  & > * {
    transform: ${props =>
      props.show ? `translateX(0)` : `translateX(${vw(50)})`};
    opacity: ${props => (props.show ? 1 : 0)};
    transition: transform 0.5s, opacity 0.5s;
    &:nth-child(1) {
      transition-delay: ${props =>
        !props.show ? 0 : 0.5 + transitionDelay * 0}s;
    }
    &:nth-child(2) {
      transition-delay: ${props =>
        !props.show ? 0 : 0.5 + transitionDelay * 1}s;
    }
    &:nth-child(3) {
      transition-delay: ${props =>
        !props.show ? 0 : 0.5 + transitionDelay * 2}s;
    }
    &:nth-child(4) {
      transition-delay: ${props =>
        !props.show ? 0 : 0.5 + transitionDelay * 3}s;
    }
    &:nth-child(5) {
      transition-delay: ${props =>
        !props.show ? 0 : 0.5 + transitionDelay * 4}s;
    }
    &:nth-child(6) {
      transition-delay: ${props =>
        !props.show ? 0 : 0.5 + transitionDelay * 5}s;
    }
    &:nth-child(7) {
      transition-delay: ${props =>
        !props.show ? 0 : 0.5 + transitionDelay * 6}s;
    }
    &:nth-child(8) {
      transition-delay: ${props =>
        !props.show ? 0 : 0.5 + transitionDelay * 7}s;
    }
    &:nth-child(9) {
      transition-delay: ${props =>
        !props.show ? 0 : 0.5 + transitionDelay * 8}s;
    }
  }
  @media (max-width: ${media.md}) {
    & > a {
      margin-bottom: ${vwMobile(25)};
      font-size: ${vwMobile(14)};
      letter-spacing: ${vwMobile(2.93)};
    }
  }
`;

const Social = styled.div`
  margin-bottom: ${vw(35)};
  a {
    margin: 0 ${vw(10)};
    img {
      height: ${vw(20)};
    }
  }
  @media (max-width: ${media.md}) {
    margin-bottom: ${vwMobile(25)};
    a {
      margin: 0 ${vwMobile(10)};
      img {
        height: ${vwMobile(20)};
      }
    }
  }
`;

const ButtonContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: ${vw(35)};
  &::before,
  &::after {
    content: '';
    position: absolute;
    height: ${vw(1)};
    min-height: 1px;
    background: rgba(255, 255, 255, 0.15);
    width: 12.5vw;
    top: 50%;
    transform: translateY(-50%);
  }
  &::before {
    left: calc(100% + ${vw(25)});
  }
  &::after {
    right: calc(100% + ${vw(25)});
  }
  @media (max-width: ${media.md}) {
    margin-bottom: ${vwMobile(25)};
    &::before,
    &::after {
      height: ${vwMobile(2)};
      min-height: 1px;
      width: 17.5vw;
    }
    &::before {
      left: calc(100% + ${vwMobile(10)});
    }
    &::after {
      right: calc(100% + ${vwMobile(15)});
    }
  }
`;

const Wrapper = styled.div`
  @media (min-width: ${media.xsup}) {
    display: flex;
    align-items: flex-end;
  }
`;

const Musee = styled.div`
  span {
    display: block;
    color: #fff;
    font-size: ${vw(9)};
    font-weight: 500;
    letter-spacing: ${vw(1.8)};
    text-transform: uppercase;
  }
  img {
    width: ${vw(142)};
  }
  @media (max-width: ${media.md}) {
    span {
      font-size: ${vwMobile(9)};
      letter-spacing: ${vwMobile(1.8)};
    }
    img {
      width: ${vwTablet(124)};
    }
  }
  @media (max-width: ${media.xs}) {
    margin-bottom: ${vwMobile(20)};
    img {
      width: ${vwMobile(105)};
    }
  }
`;

const Curator = styled.div`
  img {
    width: ${vwMobile(104)};
  }
  @media (min-width: ${media.xsup}) {
    margin-left: ${vwTablet(250)};
    img {
      width: ${vwTablet(122)};
    }
  }
  @media (min-width: ${media.mdup}) {
    margin-left: ${vw(250)};
    img {
      width: ${vw(122)};
    }
  }
`;
