import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import Container from 'components/Container';
import Button from 'components/Button';
import Spacing from 'components/Spacing';
import Input from 'components/Form/elements/Input';
import Select from 'components/Form/elements/Select';
import Consent from 'components/Form/elements/Consent';

import sendData from 'src/utils/sendData';

class FormSection extends Component {
  constructor(props) {
    super(props);
    this.$firstNameField = React.createRef();
    this.$lastNameField = React.createRef();
    this.$emailField = React.createRef();
  }

  handleSubmitForm = () => {
    window.gtag('event', 'Sign Up', {
      event_category: 'CampaignRegistration',
      event_label: 'General_Event_Sign_Up',
      value: 1,
    });

    const firstName = this.$firstNameField.current.value;
    const lastName = this.$lastNameField.current.value;
    const email = this.$emailField.current.value;

    sendData(`${firstName} ${lastName}`, email);
    this.props.history.push('/thankyou');
  };

  componentDidMount() {}

  render() {
    return (
      <Wrapper>
        <iframe
          title="homeformdummy"
          width="0"
          height="0"
          border="0"
          name="homeformdummy"
          id="homeformdummy"
          style={{
            width: 0,
            height: 0,
            display: 'block',
            visibility: 'hidden',
            margin: 0,
            position: 'absolute',
          }}
        />
        <Container>
          <Spacing value={120} mobile={100} />
          <Root>
            <div>
              <h2>Stay informed with our development progress</h2>
            </div>
            <div>
              <Form
                action="https://spark.re/gryphon-development/west-blvd-45th/register/development-registration"
                // action="https://bamcommunications.ca/test"
                accept-charset="UTF-8"
                method="post"
                target="homeformdummy"
                onSubmit={this.handleSubmitForm}
              >
                <TwoColumn>
                  <input name="utf8" type="hidden" value="&#x2713;" />
                  <Input
                    name="contact[first_name]"
                    placeholder="First Name *"
                    required
                    refDOM={this.$firstNameField}
                  />
                  <Input
                    name="contact[last_name]"
                    placeholder="Last Name *"
                    required
                    refDOM={this.$lastNameField}
                  />
                  <Input
                    name="contact[email]"
                    type="email"
                    placeholder="Email *"
                    required
                    refDOM={this.$emailField}
                  />
                  <Input name="contact[phone]" type="tel" placeholder="Phone" />
                </TwoColumn>
                <PostalInput
                  name="contact[postcode]"
                  placeholder="Postcode / Zip"
                />
                <TwoColumn>
                  <Select name="agent" id="agent" defaultValue="">
                    <option disabled="disabled" value="">
                      Are you a Realtor?
                    </option>{' '}
                    <option value="false">No</option>{' '}
                    <option value="true">Yes</option>
                  </Select>
                  <Input
                    name="contact[brokerage_name]"
                    placeholder="Employer / Brokerage"
                  />
                </TwoColumn>
                <Spacing value={20} />
                <Consent />
                <Spacing value={40} />
                <Button type="submit">Stay Informed</Button>
                <input
                  type="hidden"
                  name="source"
                  id="source"
                  value="Development Update Form"
                />
                <input
                  type="hidden"
                  name="redirect_success"
                  id="redirect_success"
                  value="http://gryphonliving.com/?form=success"
                />
                <input
                  type="hidden"
                  name="redirect_error"
                  id="redirect_error"
                  value="http://gryphonliving.com/contact"
                />
                <input
                  type="text"
                  name="are_you_simulated"
                  id="are_you_simulated"
                  placeholder="Leave this field blank"
                  style={{ display: 'none' }}
                />
              </Form>
            </div>
          </Root>
          <Spacing value={120} mobile={100} />
        </Container>
      </Wrapper>
    );
  }
}

export default withRouter(FormSection);

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.backgroundDark};
`;

const Root = styled.div`
  display: flex;
  & > div {
    width: 100%;
    &:first-child {
      width: 45%;
    }
    &:last-child {
      padding-top: ${vw(25)};
      padding-bottom: ${vw(25)};
      padding-left: 5%;
      width: 55%;
      border-left: ${vw(1)} solid ${({ theme }) => theme.color.primary};
      @media (max-width: ${media.md}) {
        border-left: none;
        padding-left: 0;
        padding-top: ${vwMobile(15)};
        padding-bottom: ${vwMobile(15)};
      }
    }
  }
  h2 {
    font-size: ${vw(29)};
    font-weight: 400;
    color: ${({ theme }) => theme.color.primary};
    max-width: ${vw(400)};
    @media (max-width: ${media.md}) {
      font-size: ${vwMobile(29)};
      max-width: 100%;
      margin-bottom: ${vwMobile(40)};
    }
  }
  @media (max-width: ${media.md}) {
    display: block;
    & > div:first-child,
    & > div:last-child {
      width: 100%;
    }
  }
`;

const Form = styled.form`
  input,
  select {
    margin-bottom: ${vw(20)};
    @media (max-width: ${media.md}) {
      margin-bottom: ${vwMobile(20)};
    }
  }
`;

const TwoColumn = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  input,
  select {
    width: 45%;
  }
  @media (max-width: ${media.md}) {
    input,
    select {
      width: 100%;
    }
  }
`;

const PostalInput = styled(Input)`
  width: 100%;
`;
