import React, { Component } from 'react';
import styled from 'styled-components';

import sendData from 'src/utils/sendData';

import Button from 'components/Button';
import Spacing from 'components/Spacing';
import Input from 'components/Form/elements/Input';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import GradientText from 'components/GradientText';

export default class FormCTA extends Component {
  state = {
    isShowOverlay: false,
    isFormSuccess: false,
  };

  constructor(props) {
    super(props);
    this.$nameField = React.createRef();
    this.$emailField = React.createRef();
  }

  handleClickBtn = () => {
    this.setState({ isShowOverlay: true });
  };

  handleClickClose = () => {
    this.setState({ isShowOverlay: false });
  };

  handleSubmitForm = () => {
    this.setState({ isFormSuccess: true });
    this.props.onFormSuccess();
    window.gtag('event', 'Sign Up', {
      event_category: 'CampaignRegistration',
      event_label: 'Musee_Event_Sign_Up',
      value: 1,
    });
    sendData(this.$nameField.current.value, this.$emailField.current.value);
  };

  render() {
    return (
      <>
        <Root>
          <p>
            Visit Brent Comber's upcoming exhibition. Join our mailing list for
            more details.
          </p>
          <Button onClick={this.handleClickBtn} fill>
            Keep Me Informed
          </Button>
        </Root>
        <Overlay show={this.state.isShowOverlay}>
          <Modal>
            <>
              <FormWrapper show={!this.state.isFormSuccess}>
                <iframe
                  title="atelierdummyframemodal"
                  width="0"
                  height="0"
                  border="0"
                  name="atelierdummyframemodal"
                  id="atelierdummyframemodal"
                  style={{
                    width: 0,
                    height: 0,
                    display: 'block',
                    visibility: 'hidden',
                    margin: 0,
                    position: 'absolute',
                  }}
                />
                <Form
                  id="atelier-form-modal"
                  accept-charset="UTF-8"
                  method="post"
                  target="atelierdummyframemodal"
                  action="https://spark.re/gryphon-development/gryphon/register/atelier_registration"
                  // action="https://bamcommunications.ca/test"
                  onSubmit={this.handleSubmitForm}
                >
                  <Content>
                    <input name="utf8" type="hidden" value="&#x2713;" />
                    <input
                      type="hidden"
                      name="source"
                      id="source"
                      value="Musee Signup - Atelier Campaign - Brent Comber"
                    />
                    <input
                      type="hidden"
                      name="redirect_success"
                      id="redirect_success"
                      value=""
                    />
                    <input
                      type="hidden"
                      name="redirect_error"
                      id="redirect_error"
                      value=""
                    />
                    <input
                      type="text"
                      name="are_you_simulated"
                      id="are_you_simulated"
                      placeholder="Leave this field blank"
                      style={{ display: 'none' }}
                    />
                    <input
                      type="hidden"
                      name="answers[5906][answers]"
                      value="Brent Comber Musee Exhibition "
                    />
                    <Close
                      onClick={this.handleClickClose}
                      src={require('src/assets/images/icons/close.svg')}
                    />
                    <Spacing value={50} mobile={20} />
                    <h3>
                      Visit Brent Comber’s upcoming exhibition. Join our mailing
                      list for more details
                    </h3>
                    <Spacing value={30} />
                    <Fields>
                      <Input
                        name="contact[first_name]"
                        placeholder="Name *"
                        required
                        refDOM={this.$nameField}
                      />
                      <Input
                        name="contact[email]"
                        placeholder="Email *"
                        type="email"
                        required
                        refDOM={this.$emailField}
                      />
                    </Fields>
                    <Spacing value={25} />
                    <Button type="submit" fill>
                      Join List
                    </Button>
                    <Spacing value={20} />
                  </Content>
                </Form>
              </FormWrapper>
            </>
            {this.state.isFormSuccess && (
              <ThankYou>
                <div>
                  <Spacing value={30} mobile={20} />
                  <Close
                    onClick={this.handleClickClose}
                    src={require('src/assets/images/icons/close.svg')}
                  />
                  <h3>
                    <GradientText>
                      Thank you for joining our mailing list.
                    </GradientText>
                  </h3>
                  <p>
                    We deeply appreciate your interest in Gryphon Atelier. We
                    will keep you informed about our art initiatives and
                    upcoming events.
                  </p>
                  <Spacing value={30} mobile={20} />
                </div>
              </ThankYou>
            )}
          </Modal>
        </Overlay>
      </>
    );
  }
}

const Root = styled.div`
  padding: ${vw(10)} ${vw(50)};
  background: rgb(16, 30, 39, 85);
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: #101e27;
  p {
    line-height: 1.4;
    font-weight: 500;
    text-transform: uppercase;
    font-size: ${vw(14)};
    letter-spacing: ${vw(1)};
  }
  a {
    padding: ${vw(11)} ${vw(30)} ${vw(9)} !important;
  }
  @media (max-width: ${media.md}), (max-width: ${media.lg}) {
    padding: ${vw(40)} ${vw(50)};
    p {
      font-size: ${vw(20)};
      max-width: 60%;
    }
  }
  @media (max-width: ${media.sm}) {
    padding: ${vwMobile(25)} ${vwMobile(30)};
    display: block;
    text-align: center;
    p {
      font-size: ${vwMobile(11)};
      letter-spacing: ${vwMobile(1)};
      margin-bottom: ${vw(40)};
      max-width: 100%;
    }
    a {
      padding: ${vwMobile(5)} ${vwMobile(12)} !important;
    }
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: ${props => (props.show ? 9999999 : -1)};
  transition: all 0.5s;
  opacity: ${props => (props.show ? 1 : 0)};
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
`;

const Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${({ theme }) => theme.color.background};
  padding: ${vw(10)};
  width: ${vw(840)};
  @media (max-width: ${media.md}) {
    padding: ${vwMobile(10)};
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform: none;
  }
`;

const Form = styled.form`
  position: relative;
  border: ${vw(1)} solid ${({ theme }) => theme.color.primary};
  padding: ${vw(20)} ${vw(48)};
  text-align: center;
  height: 100%;
  h3 {
    font-weight: normal;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: ${vw(3)};
    line-height: ${vw(30)};
  }
  @media (max-width: ${media.md}) {
    border: ${vwMobile(1)} solid ${({ theme }) => theme.color.primary};
    padding: ${vwMobile(20)};
    h3 {
      letter-spacing: ${vwMobile(3)};
      line-height: 1.8;
      font-size: ${vw(50)};
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const FormWrapper = styled.div`
  height: ${props => (props.show ? '100%' : '0')};
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
  overflow: hidden;
`;

const Fields = styled.div`
  width: ${vw(531)};
  display: flex;
  margin: 0 auto;
  justify-content: center;
  input {
    margin: 0 ${vw(15)};
  }
  @media (max-width: ${media.md}) {
    width: 100%;
    display: block;
    input {
      width: 100%;
      margin: 0 0 ${vwMobile(25)};
    }
  }
`;

const ThankYou = styled.div`
  position: relative;
  border: ${vw(1)} solid ${({ theme }) => theme.color.primary};
  padding: ${vw(20)} ${vw(48)}};
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    font-family: 'SangBleuEmpire';
    font-weight: normal;

    text-transform: uppercase;
    letter-spacing: ${vw(3)};
    line-height: 1.25;
    font-size: ${vw(40)};
    padding-bottom: ${vw(20)};
  }
  p {
    line-height: 1.5;
  }
  @media (max-width: ${media.md}) {
    border: ${vwMobile(1)} solid ${({ theme }) => theme.color.primary};
    padding: ${vwMobile(20)} ${vwMobile(15)};
    h3 {
      letter-spacing: ${vwMobile(3)};
      font-size: ${vwMobile(21)};
      padding-bottom: ${vwMobile(20)};
    }
  }
`;

const Close = styled.img`
  position: absolute;
  top: ${vw(15)};
  right: ${vw(15)};
  width: ${vw(20)};
  cursor: pointer;
  @media (max-width: ${media.md}) {
    top: ${vwMobile(15)};
    right: ${vwMobile(15)};
    width: ${vwMobile(20)};
  }
`;

const Content = styled.div``;
