import React, { useRef } from 'react';
import styled from 'styled-components';

import Page from 'components/Page';
import ScrollingBackground from 'components/RoyalCurator/ScrollingBackground';
import Menu from 'components/RoyalCurator/Menu';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

const Contact = () => {
  const $container = useRef();

  return (
    <Page>
      <Menu />
      <Root ref={$container}>
        <ScrollingBackground
          src={require('src/assets/images/curator/contact_bg.jpg')}
          container={$container}
          topLeft
        />
        <Overlay />
        <Content>
          <div>
            <h2 className="curator">text &amp; call</h2>
            <a href="tel:778.928.8292" className="phone">
              778.928.8292
            </a>
          </div>
          <div>
            <h2 className="curator">Email</h2>
            <a href="mailto:curator@gryphonliving.com">
              curator@gryphonliving.com
            </a>
          </div>
          <div>
            <h2 className="curator">Wechat</h2>
            <img
              src={require('src/assets/images/curator/qr_code2.png')}
              alt="QR"
              className="qr"
            />
          </div>
          <p className="content">
            All requests will be respondend
            <br />
            to during regular business hours.
            <br />
            <br />
            Monday – Friday (9AM- 5PM)
            <br className="mobile" /> excluding holidays.
            <br />
            For emergencies regarding your property, <br />
            please contact your strata management company.
          </p>
          <p className="disclaimer">
            All Gryphon home owners will automatically be eligible for the
            program (max 4 members per household). For others to join, we will
            require 2 members’ referral and a $5,000 membership fee, contact us
            to inquire.
          </p>
        </Content>
      </Root>
    </Page>
  );
};

const Root = styled.div`
  position: relative;
  height: 100vh;
  @media (min-width: ${media.xsup}) {
  }
  @media (min-width: ${media.mdup}) {
    height: 100vh;
    min-height: ${vw(700)};
    display: flex;
    justify-content: flex-end;
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  pointer-events: none;
  @media (min-width: ${media.mdup}) {
    display: none;
  }
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${vwMobile(100)} ${vwMobile(13)} ${vwMobile(40)};
  text-align: center;
  z-index: 2;
  position: relative;
  h2 {
    text-transform: uppercase;
    color: white;
  }
  a {
    font-family: ${({ theme }) => theme.font.curator};
    font-size: ${vwMobile(14)};
    line-height: 1.71;
    letter-spacing: ${vwMobile(3)};
    color: ${({ theme }) => theme.color.pinkBrown};
    margin-top: ${vwMobile(5)};
    text-decoration: underline;
    display: block;
    color: white;
  }
  .qr {
    width: ${vwMobile(81)};
    display: block;
    margin-top: ${vwMobile(13)};
  }
  > div {
    margin-bottom: ${vwMobile(30)};
  }
  .content {
    font-family: ${({ theme }) => theme.font.curator};
    font-size: ${vwMobile(14)};
    letter-spacing: ${vwMobile(1)};
    margin: 0 ${vwMobile(24)} ${vwMobile(30)};
  }
  .disclaimer {
    font-family: ${({ theme }) => theme.font.body};
    font-size: ${vwMobile(10)};
  }
  @media (min-width: ${media.xsup}) {
    padding: 0;
    h2 {
      letter-spacing: ${vwTablet(3)};
    }
    a {
      font-size: ${vwTablet(16)};
      line-height: 1.5;
      letter-spacing: ${vwTablet(2)};
      margin-top: ${vwTablet(1)};
    }
    .phone {
      letter-spacing: ${vwTablet(4.5)};
    }
    .qr {
      width: ${vwTablet(100)};
      margin-top: ${vwTablet(10)};
    }
    > div {
      margin-bottom: ${vwTablet(40)};
    }
    .content {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(1)};
      margin: 0 0 ${vwTablet(20)};
    }
    .disclaimer {
      font-size: ${vwTablet(10)};
      max-width: ${vwTablet(528)};
    }
    .mobile {
      display: none;
    }
  }
  @media (min-width: ${media.mdup}) {
    padding: 0;
    width: 50%;
    h2 {
      font-size: ${vw(14)};
      line-height: 1.71;
      letter-spacing: ${vw(2)};
    }
    a {
      font-size: ${vw(16)};
      line-height: 1.5;
      letter-spacing: ${vw(3)};
      margin-top: ${vw(1)};
    }
    .phone {
      letter-spacing: ${vw(4.5)};
    }
    .qr {
      width: ${vw(100)};
      margin-top: ${vw(10)};
    }
    > div {
      margin-bottom: ${vw(40)};
    }
    .content {
      font-size: ${vw(14)};
      letter-spacing: ${vw(1)};
      margin: 0 0 ${vw(20)};
    }
    .disclaimer {
      font-size: ${vw(10)};
      max-width: ${vw(528)};
    }
    .mobile {
      display: none;
    }
  }
  @media (min-width: ${media.textbreak}) {
    h2 {
      letter-spacing: 4px;
    }
    a {
      font-size: 21px;
      line-height: 1.52;
      letter-spacing: 2.67px;
    }
    .phone {
      letter-spacing: 6px;
    }
    .content {
      font-size: 18px;
      letter-spacing: 1px;
    }
    .disclaimer {
      font-size: 13px;
      line-height: 1.38;
      max-width: 687px;
    }
  }
`;

export default Contact;
