import React, { Component } from 'react';
import styled from 'styled-components';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import SubTitle from 'components/SubTitle';
import Spacing from 'components/Spacing';

import projects from 'src/data/comingSoonProjects';

export default class ComingSoonProjects extends Component {
  render() {
    return (
      <Root mobileLeft={this.props.mobileLeft}>
        <SubTitle>Coming Soon</SubTitle>
        <Spacing value={20} mobile={30} />
        <Projects>
          {projects.map((project, index) => (
            <Project key={index}>
              <h3>{project.title}</h3>
              <p>{project.subtitle}</p>
            </Project>
          ))}
        </Projects>
      </Root>
    );
  }
}

const Root = styled.div`
  @media (max-width: ${media.md}) {
    text-align: ${props => (props.mobileLeft ? 'left' : 'center')};
  }
`;

const Projects = styled.div`
  display: flex;
  @media (max-width: ${media.md}) {
    display: block;
  }
`;

const Project = styled.div`
  width: ${vw(336)};
  margin-right: ${vw(47)};
  h3 {
    color: ${({ theme }) => theme.color.primary};
    font-family: 'SangBleuEmpire';
    font-weight: normal;
    font-size: ${vw(32)};
    @media (max-width: ${media.md}) {
      font-size: ${vwMobile(32)};
    }
  }
  p {
    font-size: ${vw(10)};
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: ${vw(3)};
    @media (max-width: ${media.md}) {
      font-size: ${vwMobile(10)};
      letter-spacing: ${vwMobile(3)};
    }
  }
  @media (max-width: ${media.md}) {
    width: auto;
    margin-bottom: ${vwMobile(50)};
    margin-right: 0;
  }
`;
