import React, { Component } from 'react';
import styled from 'styled-components';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

import Container from 'components/Container';
import SubTitle from 'components/SubTitle';
import Spacing from 'components/Spacing';
import Button from 'components/Button';
import ComingSoonProjects from 'components/ComingSoonProjects';

export default class Projects extends Component {
  render() {
    return (
      <Container>
        <div>
          <SubTitle border>Our Projects</SubTitle>
          <Spacing value={60} />
        </div>
        <Project margin>
          <Content smallLogo>
            <div>
              <SubTitle align="center">Final Release</SubTitle>
              <Spacing value={40} mobile={40} />
              <img
                src={require('src/assets/images/projects/gryphon-nova-logo.svg')}
                alt=""
                className="logo"
              />
              <Spacing value={0} mobile={40} tabletlg={40} />
              <Image
                src={require('src/assets/images/projects/gryphon-nova_projects-page.jpg')}
                mobile
              />
              <Spacing value={80} mobile={25} />
              <Button
                href="https://gryphonnova.com/"
                target="_blank"
                rel="noreferrer"
              >
                View Website
              </Button>
            </div>
          </Content>
          <Image
            src={require('src/assets/images/projects/gryphon-nova_projects-page.jpg')}
          />
        </Project>
        <Project margin>
          <Content smallLogo>
            <div>
              <SubTitle align="center">Now Selling, In Construction</SubTitle>
              <Spacing value={40} mobile={40} />
              <img
                src={require('src/assets/images/projects/gryphonhouse-logo-taupe.svg')}
                alt=""
                className="logo"
              />
              <Spacing value={0} mobile={40} tabletlg={40} />
              <Image
                src={require('src/assets/images/projects/gryphon-house-lobby.jpeg')}
                mobile
              />
              <Spacing value={80} mobile={25} />
              <Button
                href="https://gryphonhousevancouver.com/"
                target="_blank"
                rel="noreferrer"
              >
                View Website
              </Button>
            </div>
          </Content>
          <Image
            src={require('src/assets/images/projects/gryphon-house-lobby.jpeg')}
          />
        </Project>
        {/* <Project margin>
          <Content smallLogo>
            <div>
              <SubTitle align="center">Coming Soon</SubTitle>
              <Spacing value={40} mobile={40} />
              <img
                src={require('src/assets/images/projects/flora_logo.svg')}
                alt=""
                className="logo"
              />
              <Spacing value={0} mobile={40} tabletlg={40} />
              <Image
                src={require('src/assets/images/projects/flora_homepage.jpg')}
                mobile
              />
              <Spacing value={80} mobile={25} />
              <Button href="https://gryphonflora.com" target="_blank">
                View Website
              </Button>
            </div>
          </Content>
          <Image
            src={require('src/assets/images/projects/flora_homepage.jpg')}
          />
        </Project> */}
        <Project>
          <Content>
            <div>
              <SubTitle align="center" noWidth>
                COMPLETED
              </SubTitle>
              {/* <Spacing value={5} mobile={5} />
              <SubTitle align="center" noWidth>
                final release now selling
              </SubTitle> */}
              <Spacing value={40} mobile={40} />
              <img
                src={require('src/assets/images/home/westbury-logo.svg')}
                alt=""
                className="logo"
              />
              <Spacing value={0} mobile={40} tabletlg={40} />
              <Image
                src={require('src/assets/images/projects/westbury-kitchen.jpeg')}
                mobile
              />
              <Spacing value={80} mobile={25} />
              <Button
                href="http://westburyliving.ca/"
                target="_blank"
                rel="noreferrer"
              >
                View Website
              </Button>
            </div>
          </Content>
          <Image
            src={require('src/assets/images/projects/westbury-kitchen.jpeg')}
          />
        </Project>
        <Spacing value={100} mobile={75} tabletlg={200} />
        <ComingSoonProjects />
        <Spacing value={100} mobile={75} />
      </Container>
    );
  }
}

const Project = styled.div`
  display: flex;
  margin-bottom: ${props => (props.margin ? vwMobile(80) : 0)};
  @media (min-width: ${media.xsup}) {
    margin-bottom: ${props => (props.margin ? vw(200) : 0)};
  }
  @media (min-width: ${media.mdup}) {
    margin-bottom: ${props => (props.margin ? vw(100) : 0)};
  }
`;

const Content = styled.div`
  width: 40%;
  text-align: center;
  display: flex;
  position: relative;
  & > div {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
  .logo {
    width: ${props => (props.smallLogo ? vw(160) : vw(360))};
    @media (max-width: ${media.md}) {
      width: ${props => (props.smallLogo ? vwTablet(140) : vwTablet(200))};
    }
    @media (max-width: ${media.sm}) {
      width: ${props => (props.smallLogo ? vwMobile(140) : vwMobile(200))};
    }
  }
  @media (max-width: ${media.md}) {
    width: 100%;
    & > div {
      position: static;
      transform: none;
    }
  }
`;

const Image = styled.img`
  display: ${props => (props.mobile ? 'block' : 'none')};
  width: 100%;
  height: auto;
  @media (min-width: ${media.mdup}) {
    width: 60%;
    height: auto;
    display: ${props => (props.mobile ? 'none' : 'block')};
  }
`;

const Background = styled.div`
  width: 60%;
  position: relative;
  overflow: hidden;
  &::after {
    content: '';
    display: block;
    padding-top: 85%;
  }
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
    height: 100%;
    background: url(${props => props.image});
    background-position: 50% 50%;
    transform: translate(-50%, -50%) scale(1.1);
  }
  @media (max-width: ${media.md}) {
    display: none;
  }
`;

const BackgroundMobile = styled.div`
  overflow: hidden;
  display: none;
  &::after {
    content: '';
    display: block;
    padding-top: 100%;
    background: url(${props => props.image});
    transform: scale(1.35);
    background-size: cover;
  }
  @media (max-width: ${media.md}) {
    display: block;
  }
`;
