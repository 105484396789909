import React, { Component } from 'react';
import styled from 'styled-components';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import VideoPlayer from 'components/VideoPlayer';

export default class VideoSection extends Component {
  render() {
    return (
      <Root>
        <VideoPlayer
          url="https://player.vimeo.com/video/291003995"
          background={require('src/assets/images/about/video-bg.jpg')}
          aspectRatio={50}
        >
          <Content>
            A Story of Evolution
            <br />
            and Ambition
          </Content>
        </VideoPlayer>
      </Root>
    );
  }
}

const Root = styled.div``;

const Content = styled.h4`
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: ${vw(19)};
  line-height: 1.75;
  text-transform: uppercase;
  @media (max-width: ${media.md}) {
    font-size: ${vwMobile(19)};
  }
`;
