import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import scrollToElement from 'scroll-to-element';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import media from 'src/styles/media';

import Page from 'components/Page';
import Spacing from 'components/Spacing';
import WaypointTrigger from 'components/WaypointTrigger';
import FadeUp from 'components/animations/FadeUp';

import Video from './sections/Video';
import FormTop from './sections/FormTop';
import FormTopB from './sections/FormTopB';
import AtelierSection from 'src/pages/About/sections/Atelier';
import Artists from 'src/pages/About/sections/Artists';
import Feature from '../sections/Feature';
import Social from '../sections/Social';
import FormCTA from '../sections/FormCTA';

class AtelierB extends Component {
  state = {
    isFormModalSuccess: false,
  };

  componentDidMount() {
    if (this.props.location.search === '?_') {
      scrollToElement('#atelier-video-player');
    }
  }

  handleFormSuccess = () => {
    this.setState({ isFormModalSuccess: true });
  };

  render() {
    return (
      <Page>
        <Helmet>
          <meta
            property="og:image"
            content="https://gryphonliving.com/assets/share/atelier.jpg"
          />
        </Helmet>
        {[
          <Spacing value={100} tabletlg={200} />,
          <AtelierSection />,
          <Video abTest={this.props.abTest} />,
        ].map((section, index) => (
          <WaypointTrigger key={index} once>
            {trigger => <FadeUp animate={trigger}> {section} </FadeUp>}
          </WaypointTrigger>
        ))}
        <FormCTAWrapper>
          <FormCTA onFormSuccess={this.handleFormSuccess} />
        </FormCTAWrapper>
        {[
          !this.props.abTest && (
            <>
              <Social atelierB />
              <FormTop onFormSuccess={this.handleFormSuccess} />
            </>
          ),
          this.props.abTest && (
            <>
              <FormTopB onFormSuccess={this.handleFormSuccess} />
              <Social atelierB abTest={this.props.abTest} />
            </>
          ),

          <Spacing value={100} />,

          <Artists />,
          <>
            <Feature />
            <Spacing value={100} />
          </>,
          // <Form isFormModalSuccess={this.state.isFormModalSuccess} />,
        ].map((section, index) => (
          <WaypointTrigger key={index} once>
            {trigger => <FadeUp animate={trigger}> {section} </FadeUp>}
          </WaypointTrigger>
        ))}
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  abTest: state.abTest.abTest,
});

export default connect(
  mapStateToProps,
  {}
)(withRouter(AtelierB));

const FormCTAWrapper = styled.div`
  @media (min-width: ${media.mdup}) {
    display: none;
  }
`;
