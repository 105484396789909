import React from 'react';
import GTM from './elements/GTM';

const Analytics = () => {
  return !window.__PRERENDERING ? (
    <>
      <GTM />
    </>
  ) : null;
};

export default Analytics;
