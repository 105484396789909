import React from 'react';
import styled from 'styled-components';

import TaglineHero from '../elements/TaglineHero';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

const videos = [
  'https://player.vimeo.com/external/442194915.hd.mp4?s=a28d7cca62523328d3defe1ed24525a0f8c888ba&profile_id=175',
  'https://player.vimeo.com/external/442195249.hd.mp4?s=60a9be7a5fe84ab1843753fc8b98152fb3b2133a&profile_id=175',
  'https://player.vimeo.com/external/442201910.hd.mp4?s=ce7aa23b6232b907e316edda12d755092d6fdaa4&profile_id=175',
];

const Hero = () => {
  const handleSrc = () => {
    const random = Math.floor(Math.random() * Math.floor(3));
    return videos[random];
  };

  return (
    <Root>
      <Video src={handleSrc()} autoPlay muted playsInline />
      <Wrapper>
        <TaglineHero />
      </Wrapper>
    </Root>
  );
};

const Root = styled.div`
  height: 100vh;
  position: relative;
  padding: 0 ${vwMobile(60)};
  background-color: #1e1e1e;
  h1,
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  img {
    max-width: 100%;
  }
  @media (min-width: ${media.xsup}) {
    padding: 0 ${vwTablet(40)};
  }
  @media (min-width: ${media.mdup}) {
    padding: 0 ${vw(60)};
    min-height: ${vw(700)};
  }
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 ${vwMobile(12)};
  @media (min-width: ${media.xsup}) {
    padding: 0 ${vwTablet(32)};
  }
  @media (min-width: ${media.mdup}) {
    padding: 0;
  }
`;

export default Hero;
