import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

export default function Icon({ onClick, open, musee }) {
  return (
    <Root onClick={onClick} open={open} musee={musee}>
      <IconContainer>
        <MenuIcon
          src={
            musee
              ? require('src/assets/images/icons/menu-white.svg')
              : require('src/assets/images/icons/menu.svg')
          }
        />
        <CloseIcon src={require('src/assets/images/icons/close.svg')} />
      </IconContainer>
      <Text>Menu</Text>
    </Root>
  );
}

Icon.propTypes = {
  open: PropTypes.bool,
  musee: PropTypes.bool,
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  open: false,
};

const MenuIcon = styled.img`
  width: 100%;
`;
const CloseIcon = styled.img`
  width: 70%;
`;

const Root = styled.div`
  position: relative;
  font-size: ${vw(14)};
  font-weight: 500;
  letter-spacing: ${vw(3)};
  text-transform: uppercase;
  color: ${props => (props.musee ? 'white' : props.theme.color.primary)};
  padding-left: ${vw(10)};
  cursor: pointer;
  pointer-events: auto;
  @media (max-width: ${media.md}) {
    padding-left: 0;
  }
  ${MenuIcon} {
    transform: ${props =>
      props.open
        ? 'scaleX(0) translate(-50%, -50%)'
        : 'scaleX(1) translate(-50%, -50%)'};
    transform-origin: 0% 50%;
    opacity: ${props => (props.open ? 0 : 1)};
  }
  ${CloseIcon} {
    transform: ${props =>
      props.open
        ? 'scale(1) translate(-50%, -50%)'
        : 'scale(0) translate(-50%, -50%)'};
    transform-origin: 0% 0%;
    opacity: ${props => (props.open ? 1 : 0)};
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
  width: ${vw(30)};
  margin-top: 0;
  @media (max-width: ${media.md}) {
    margin-top: ${vwTablet(20)};
    width: ${vwTablet(30)};
  }
  @media (max-width: ${media.sm}) {
    margin-top: 0;
    width: ${vwMobile(30)};
  }
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.33s;
  }
`;

const Text = styled.span`
  @media (max-width: ${media.md}) {
    display: none;
  }
`;
