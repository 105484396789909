import React, { Component } from 'react';
import styled from 'styled-components';

import Spacing from 'components/Spacing';
import Container from 'components/Container';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

export default class Top extends Component {
  render() {
    return (
      <Root>
        <Container>
          <Spacing value={87} mobile={60} />
          <h3>Our Approach to Design Through Collaboration</h3>
          <Spacing value={80} mobile={30} />
        </Container>
      </Root>
    );
  }
}

const Root = styled.div`
  h3 {
    font-size: ${vw(14)};
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: ${vw(3)};
    line-height: 1.75;

    @media (max-width: ${media.md}),(max-width: ${media.lg}) {
      font-size: ${vw(17)};
    }
    @media (max-width: ${media.sm}) {
      font-size: ${vwMobile(10)};
    }
  }
`;
