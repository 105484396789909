import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';

import { vwTablet, vwMobile320, vw } from 'src/styles/utils';
import media from 'src/styles/media';

import DoubleGallery from '../elements/DoubleGallery';

const currentProjects = [
  {
    title: 'ChopValue',
    subtitle: 'Presenting: The CUBE',
    description: `Felix Böck knocks on wood for a living. He is the founder of the ChopValue microfactory concept where he developed and engineered an innovative material using under-utilized resources.`,
    link: 'https://www.chopvalue.com',
    largeImage: require('src/assets/images/musee/current/gryphon_musee_ids_chopvalue_2.jpg'),
    smallImage: require('src/assets/images/musee/current/gryphon_musee_ids_chopvalue_1.jpg'),
  },
  {
    title: 'Kurva Design',
    subtitle: `Presenting: Vesperum lit table and Bow sculptural floor lamp`,
    description: `Kurva is a Swedish word meaning bend, or arc. The name Kurva Design reflects Mike Randall’s love of both curved design and the simple, clean, minimalist lines of Scandinavian design.`,
    link: 'https://www.kurvadesign.ca',
    largeImage: require('src/assets/images/musee/current/gryphon_musee_ids_kurva_2.jpg'),
    smallImage: require('src/assets/images/musee/current/gryphon_musee_ids_kurva_1.jpg'),
  },
  {
    title: 'Ryspot Design',
    subtitle: 'Presenting: Nes Collection',
    description:
      'Ryspot Design explores the relationship between traditional craft, design, and art. The studio is run by Ryan Spotowski in Edmonton, Alberta. Goods are produced on a limited run basis in house based on the experience of three generations.',
    link: 'https://www.ryspot.design',
    largeImage: require('src/assets/images/musee/current/gryphon_musee_ids_ryspot_2.jpg'),
    smallImage: require('src/assets/images/musee/current/gryphon_musee_ids_ryspot_1.jpg'),
  },
  {
    title: 'Atelier Dimopoulos',
    subtitle: 'Presenting: D3 Coffee Tables',
    description:
      'Stéfane Dimopoulos is a graduate of the Fine Furniture and Joinery Program. He started Atelier Dimopoulos in 2017 with the aim of designing furniture that meets all the rigorous needs of daily life, and satisfies the desire for daily beauty.',
    link: 'https://www.atelierdimopoulos.com',
    largeImage: require('src/assets/images/musee/current/gryphon_musee_ids_dimopoulos_2.jpg'),
    smallImage: require('src/assets/images/musee/current/gryphon_musee_ids_dimopoulos_1.jpg'),
  },
  {
    title: 'Retrobound',
    subtitle: 'Presenting: Down and Inbound',
    description:
      'Dane Saunders is the founder of Retrobound, a Vancouver based design company specializing in Industrial Design and Art Furniture. His art furniture has been exhibited at IDS West, IDS Toronto and Crafted Vancouver.',
    link: 'https://www.retrobound.com',
    largeImage: require('src/assets/images/musee/current/gryphon_musee_ids_retrobound_2.jpg'),
    smallImage: require('src/assets/images/musee/current/gryphon_musee_ids_retrobound_1.jpg'),
  },
  {
    title: 'Loom',
    subtitle: 'Presenting: Stellar',
    description:
      'Loom Inc. was founded in 2016 with the mission to provide a superior experience in lighting – high-efficacy, high quality light – through novel light-guide technology in designs that can be described as “industrial art”.',
    link: 'http://www.loom.lighting',
    largeImage: require('src/assets/images/musee/current/gryphon_musee_ids_loom_2.jpg'),
    smallImage: require('src/assets/images/musee/current/gryphon_musee_ids_loom_1.jpg'),
  },
  {
    title: 'Ali Alamzadeh',
    subtitle: 'Presenting: Layer UP',
    description:
      'Ali Alamzadeh was inspired by traditional Persian arts which is still mostly hand-made. He  aspires to design based on these traditional designs and art pieces and translate them in a modern way.',
    mail: 'mailto:alamzadehali@gmail.com',
    largeImage: require('src/assets/images/musee/current/gryphon_musee_ids_ali_2.jpg'),
    smallImage: require('src/assets/images/musee/current/gryphon_musee_ids_ali_1.jpg'),
  },
];

const CurrentExhibition = props => {
  return (
    <Root>
      <Header>
        <Wrapper>
          <Logos>
            <img
              src={require('src/assets/images/musee/current/ids_logo.svg')}
              alt="Interior Design Show"
              className="ids-logo"
            />
            <img
              src={require('src/assets/images/musee/current/gryphon_x_ids_prototypeexhibition_logo.svg')}
              alt="Prototype"
              className="prototype-logo"
            />
          </Logos>
        </Wrapper>
        <Link
          to="information"
          smooth
          offset={-50}
          className="mobile-tablet-only"
        >
          <img
            src={require('src/assets/images/musee/current/arrow-down-copy.svg')}
            alt="Scroll Down"
            className="scroll"
          />
        </Link>
      </Header>
      <TextContainer>
        <Mobile>
          <h4>VERSION INFINITY</h4>
        </Mobile>
        <p name="information" className="description">
          A curated showcase of award-winning next generation designers. VERSION
          INFINITY explores the inspirations, challenges, intimate moments and
          working processes of designers to see their prototype pieces come to
          life. In collaboration with IDS Vancouver.
        </p>
        <p className="dates">
          From March 2020 <span className="tablet-desktop-only"> / </span>{' '}
          <br />
          Free Admission
        </p>
        <p className="hours">
          Monday to Friday
          <br /> 11AM - 5PM (except holidays)
        </p>
      </TextContainer>

      <Link to="information" smooth offset={-50} className="desktop-only">
        <img
          src={require('src/assets/images/musee/current/arrow-down-copy.svg')}
          alt="Scroll Down"
          className="scroll"
        />
      </Link>

      <DoubleGallery currentProjects={currentProjects} />
    </Root>
  );
};

export default CurrentExhibition;

const Root = styled.div`
  @media (min-width: ${media.mdup}) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  p {
    color: ${({ theme }) => theme.color.background};
    &.dates {
      margin-top: ${vwMobile320(20)};
      margin-bottom: ${vwMobile320(20)};
    }
    &.dates,
    &.hours {
      text-transform: uppercase;
    }
    span.tablet-desktop-only {
      display: none;
    }
    @media (min-width: ${media.xsup}) {
      max-width: ${vwTablet(617)};
      &.dates {
        margin-top: ${vwTablet(30)};
        margin-bottom: ${vwTablet(10)};
      }
      span.tablet-desktop-only {
        display: inline;
      }
      &.dates,
      &.hours {
        font-size: ${vwTablet(14)};
        letter-spacing: ${vwTablet(2)};
        br {
          display: none;
        }
      }
    }
    @media (min-width: ${media.mdup}) {
      max-width: ${vw(710)};
      &.dates {
        margin-top: ${vw(40)};
        margin-bottom: ${vw(10)};
      }

      &.dates,
      &.hours {
        font-size: ${vw(14)};
        letter-spacing: ${vw(2)};
      }
    }
  }
  .description {
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(16)};
    }
  }
  .mobile-tablet-only {
    @media (min-width: ${media.mdup}) {
      display: none;
    }
  }
  .desktop-only {
    display: none;
    @media (min-width: ${media.mdup}) {
      display: block;
      margin-left: auto;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media (min-width: ${media.xsup}) {
    align-items: center;
    margin-bottom: ${vwTablet(38)};
  }
  @media (min-width: ${media.mdup}) {
    align-items: flex-start;
    margin-bottom: ${vw(50)};
  }
  .logo {
    width: ${vwMobile320(100)};
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(135)};
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(135)};
    }
  }
  .scroll {
    cursor: pointer;
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(20)};
      height: ${vwTablet(45)};
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(20)};
      height: ${vw(45)};
    }
  }
`;

const Wrapper = styled.div`
  @media (min-width: ${media.xsup}) {
    display: flex;
  }
`;

const TextContainer = styled.div`
  @media (min-width: ${media.mdup}) {
    width: ${vw(710)};
    justify-content: space-between;
  }
`;

const Mobile = styled.div`
  margin: ${vwMobile320(20)} 0 ${vwMobile320(10)};
  h4 {
    font-family: 'SangBleuEmpire';
    font-size: ${vwMobile320(24)};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${({ theme }) => theme.color.background};
  }
  @media (min-width: ${media.xsup}) {
    margin-top: ${vwTablet(38)};
    margin-bottom: ${vwTablet(10)};
    h4 {
      font-size: ${vwTablet(32)};
      line-height: 1.56;
      br {
        display: none;
      }
    }
  }
  @media (min-width: ${media.mdup}) {
    margin-top: unset;
    margin-bottom: ${vw(20)};
    h4 {
      font-size: ${vw(35)};
      line-height: 1.43;
    }
  }
`;

const Logos = styled.div`
  display: flex;
  flex-direction: row;
  .ids-logo {
    display: block;
    margin-right: ${vwMobile320(17)};
    height: ${vwMobile320(44)};
    width: ${vwMobile320(87)};
    object-fit: contain;
  }
  .prototype-logo {
    display: block;
    height: auto;
    width: ${vwMobile320(100)};
    object-fit: contain;
  }
  @media (min-width: ${media.xsup}) {
    .ids-logo {
      height: ${vwTablet(70)};
      width: ${vwTablet(140)};
      margin-right: ${vwTablet(26)};
    }
    .prototype-logo {
      height: auto;
      width: ${vwTablet(143)};
    }
  }
  @media (min-width: ${media.mdup}) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: ${vw(44)};
    .ids-logo {
      height: ${vw(70)};
      width: ${vw(140)};
      margin-right: unset;
    }
    .prototype-logo {
      height: auto;
      width: ${vw(143)};
      margin-top: ${vw(40)};
    }
  }
`;
