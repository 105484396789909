import React, { Component } from 'react';
import styled from 'styled-components';

import { vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import Spacing from 'components/Spacing';

export default class FeaturedMobile extends Component {
  render() {
    const { featuredItems } = this.props;
    return (
      <Root>
        {featuredItems.map((item, index) => {
          return (
            <FeaturedItem key={index} background={item.background}>
              <MaskNumber>{item.mask}</MaskNumber>
              <Content>
                <span>Years</span>
                <Spacing value={10} />
                <p>{item.copy}</p>
              </Content>
            </FeaturedItem>
          );
        })}
      </Root>
    );
  }
}

const Root = styled.div``;

const FeaturedItem = styled.div`
  display: none;
  height: ${vwMobile(490)};
  background: url(${props => props.background});
  background-size: cover;
  background-position: center;
  position: relative;
  @media (max-width: ${media.md}) {
    display: block;
  }
`;

const MaskNumber = styled.div`
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100%);
    height: calc(100%);
    & > g > g {
      fill: rgba(0, 0, 0, 0.5);
    }
    path {
      stroke: transparent;
      transition: stroke 0.5s;
    }
  }
`;

const Content = styled.div`
  position: absolute;
  top: 75%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  width: 100%;
  span {
    display: block;
    font-size: ${vwMobile(10)};
    font-weight: 500;
    letter-spacing: ${vwMobile(3)};
    text-transform: uppercase;
  }
  p {
    line-height: 1.625;
  }
`;
