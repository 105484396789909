import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

const Spacing = props => {
  return <Root {...props} />;
};

Spacing.propTypes = {
  value: PropTypes.number,
  tabletlg: PropTypes.number,
  mobile: PropTypes.number,
};

Spacing.defaultProps = {
  value: 0,
};

export default Spacing;

const Root = styled.div`
  padding-top: ${props => vw(props.value)};
  @media (max-width: ${media.md}),(max-width: ${media.lg}) {
    padding-top: ${props =>
      (props.tabletlg === 0 && '0') ||
      (props.tabletlg && vw(props.tabletlg)) ||
      vw(props.value)};
  }
  @media (max-width: ${media.sm}) {
    padding-top: ${props =>
      (props.mobile === 0 && '0') ||
      (props.mobile && vwMobile(props.mobile)) ||
      vwMobile(props.value)};
  }
`;
