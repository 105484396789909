import React, { Component } from 'react';
import styled from 'styled-components';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

export default class Video extends Component {
  constructor(props) {
    super(props);
    this.$video = React.createRef();
  }

  componentDidMount() {
    this.$video.current.play();
  }

  render() {
    return (
      <Root>
        <video
          ref={this.$video}
          src="https://player.vimeo.com/external/338353605.hd.mp4?s=f79a74a4af2fc39ab1182197a457fbeed2bb4198&profile_id=175"
          autoPlay
          loop
          controls
          muted
        />
      </Root>
    );
  }
}

const Root = styled.div`
  height: ${vw(768)};
  background: #111;
  @media (max-width: ${media.md}) {
    height: calc(100vh - ${vwMobile(82)});
  }
`;
