import React, { Component } from 'react';
import styled from 'styled-components';

import Container from 'components/Container';
import GradientText from 'components/GradientText';
import Spacing from 'components/Spacing';

import Page from 'components/Page';

export default class Register extends Component {
  render() {
    return (
      <Page>
        <Root>
          <Spacing value={150} />
          <Container>
            <h2>
              <GradientText>
                Thank you for
                <br />
                registering with us.
              </GradientText>
            </h2>
            <Spacing value={35} />
            <p>
              We look forward to sharing exciting project updates with you as
              they become available.
            </p>
          </Container>
          <Spacing value={100} />
        </Root>
      </Page>
    );
  }
}

const Root = styled.div`
  min-height: 50vh;
  h2 {
    font-weight: normal;
  }
`;
