import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Gallery from '../elements/Gallery';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

const Services = () => {
  return (
    <Root>
      <Container>
        <h1 className="curator">Our Services</h1>
        <Gallery />
        <StyledLink to="/royalcurator/services">
          See Details
          <span />
        </StyledLink>
      </Container>
    </Root>
  );
};

const Root = styled.div`
  background-color: white;
  padding: ${vwMobile(10)} 0;
  h1 {
    color: black;
    text-align: center;
  }
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(10)} 0;
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(20)};
    height: 100vh;
    min-height: ${vw(700)};
  }
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.veryLightPink};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${vwMobile(88)} 0 ${vwMobile(20)};
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(228)} 0 ${vwTablet(60)};
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(104)} 0 0;
    justify-content: center;
    height: 100%;
  }
`;

const StyledLink = styled(Link)`
  font-family: ${({ theme }) => theme.font.curator};
  font-size: ${vwMobile(10.5)};
  letter-spacing: ${vwMobile(3)};
  text-transform: uppercase;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    width: ${vwMobile(60)};
    height: 1px;
    display: block;
    background-color: black;
    margin-top: ${vwMobile(5)};
  }
  @media (min-width: ${media.xsup}) {
    font-size: ${vwTablet(12)};
    letter-spacing: ${vwTablet(3)};
    line-height: 2;
    span {
      width: ${vwTablet(80)};
      margin-top: 0;
    }
  }
  @media (min-width: ${media.mdup}) {
    font-size: ${vw(12)};
    letter-spacing: ${vw(3)};
    span {
      width: ${vw(80)};
      transition: 0.5s ease-in-out;
    }
    &:hover span {
      width: 100%;
    }
  }
  @media (min-width: ${media.textbreak}) {
    font-size: 16px;
    line-height: 2;
    letter-spacing: 4.5px;
  }
`;

export default Services;
