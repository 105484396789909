import React from 'react';
import styled from 'styled-components';

import { vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

export default function Container(props) {
  return <Root {...props} />;
}

const Root = styled.div`
  max-width: 80%;
  margin: 0 auto;
  @media (max-width: ${media.md}) {
    max-width: calc(100% - ${vwMobile(40)});
  }
`;
