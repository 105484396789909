import React, { Component } from 'react';

import Page from 'components/Page';
import WaypointTrigger from 'components/WaypointTrigger';
import FadeUp from 'components/animations/FadeUp';
import Container from 'components/Container';
import ComingSoonProjects from 'components/ComingSoonProjects';
import Spacing from 'components/Spacing';

import Intro from './sections/Intro';
import Project from './sections/Project';

export default class Home extends Component {
  render() {
    return (
      <Page>
        <Intro />
        {[
          <Project
            title="Gryphon Nova"
            subtitle="South Oak | Final Release"
            text="Connected Space For Modern Living. Experience elevated living in the heart of an emerging neighbourhood. Gryphon Nova blends art and design to redefine the art of modern living, creating versatile spaces attuned to the future of modern work/life comfort and a healthy environmental lifestyle."
            link="https://gryphonnova.com/"
            image={require('src/assets/images/projects/gryphon-nova_projects-page.jpg')}
            margin
            centerImage
          />,
          <Project
            title="Gryphon House"
            subtitle="Kerrisdale | Now Selling, In Construction"
            text="Across the world’s greatest cities, condominium living has evolved into a high art form. Now, Vancouver joins the echelons of world-class residential offerings, where modern innovation and timeless grandeur meet. Gryphon House represents the culmination of world travels – an examination of architecture in major cities, and deep contemplation of the finest ways to live."
            link="https://gryphonhousevancouver.com/"
            image={require('src/assets/images/projects/gryphon-house-hero.jpeg')}
            margin
            centerImage
          />,
          // <Project
          //   title="Flora"
          //   subtitle="Scarborough | Coming Soon"
          //   text="Discover the transcendent beauty of Flora, a collection of thoughtfully-designed boutique residences on Danforth. Inspired by Vancouver’s lush flower gardens, this vibrant 10-storey community brings a unique fusion of nature and art to Toronto that blooms with modern energy and timeless grandeur."
          //   link="https://gryphonflora.com"
          //   image={require('src/assets/images/projects/flora_projects.jpg')}
          //   margin
          //   centerImage
          // />,
          <Project
            title="Westbury"
            subtitle="Arbutus Ridge | Completed"
            text="Nestled in the heart of Vancouver’s prestigious West Side, Westbury
            offers you the opportunity to be immersed in one of Vancouver’s
            finest neighbourhoods. Only minutes away from the province’s
            top-ranked schools, the city’s best parks, and an abundance of
            shops, recreation, and restaurants, Westbury’s central location
            provides all the conveniences of urban living in an idyllic,
            tree-lined setting."
            link="http://westburyliving.ca/"
            image={require('src/assets/images/projects/bg.jpg')}
          />,
          <Spacing value={100} mobile={85} />,
          <Container>
            <ComingSoonProjects mobileLeft />
          </Container>,
        ].map((section, index) => (
          <WaypointTrigger key={index} once>
            {trigger => <FadeUp animate={trigger}> {section} </FadeUp>}
          </WaypointTrigger>
        ))}
        <Spacing value={100} />
      </Page>
    );
  }
}
