import React from 'react';
import styled from 'styled-components';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import Container from 'components/Container';
import GradientText from 'components/GradientText';
import Spacing from 'components/Spacing';

export default function Intro() {
  return (
    <Root>
      <Container>
        <IntroCopy>
          At Gryphon Development, we believe that building better living
          experiences begins with great collaboration. through our atelier,
          we've challenged ourselves and the artists we work with to create
          inspired living art forms through artistry and storytelling.
        </IntroCopy>
        <Spacing value={0} mobile={25} />
        <Presenting>
          <img
            src={require('src/assets/images/logo/gryphon-text.svg')}
            alt=""
          />
          <p>
            <GradientText>A Story of Evolution and Ambition</GradientText>
          </p>
        </Presenting>
      </Container>
      <Spacing value={50} />
    </Root>
  );
}

const Root = styled.div``;

const IntroCopy = styled.p`
  color: ${({ theme }) => theme.color.primary};
  padding: 5% 0 6%;
  max-width: ${vw(960)};
  margin: 5% auto;
  font-size: ${vw(21)};
  line-height: 1.57;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: ${vw(3.5)};
  @media (max-width: ${media.md}) {
    max-width: 100%;
    margin: 5% 2.5%;
    font-size: ${vwMobile(16)};
    letter-spacing: ${vwMobile(3.5)};
  }
`;

const Presenting = styled.div`
  text-align: center;
  img {
    width: ${vw(360)};
    @media (max-width: ${media.md}) {
      width: ${vwMobile(330)};
    }
  }
  p {
    margin: ${vw(40)} ${vw(50)};
    color: ${({ theme }) => theme.color.primary};
    font-size: ${vw(13)};
    font-weight: 500;
    letter-spacing: ${vw(3)};
    line-height: 1.5;
    text-transform: uppercase;
    @media (max-width: ${media.md}) {
      font-size: ${vwMobile(13)};
      letter-spacing: ${vwMobile(3)};
      margin: ${vwMobile(40)} ${vwMobile(50)};
    }
  }
`;
