import React, { Component } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import Page from 'components/Page';
import Spacing from 'components/Spacing';
import WaypointTrigger from 'components/WaypointTrigger';
import FadeUp from 'components/animations/FadeUp';

import FormCTA from '../sections/FormCTA';

import Top from '../sections/Top';
import AtelierSection from 'src/pages/About/sections/Atelier';
import Artists from 'src/pages/About/sections/Artists';
import Feature from '../sections/Feature';
import Social from '../sections/Social';
import Form from '../sections/Form';
import VideoSection from './sections/Video';

export default class AtelierA extends Component {
  state = {
    isFormModalSuccess: false,
  };

  handleFormSuccess = () => {
    this.setState({ isFormModalSuccess: true });
  };

  render() {
    return (
      <Page>
        <Helmet>
          <meta
            property="og:image"
            content="https://gryphonliving.com/share/atelier.jpg"
          />
        </Helmet>
        <Spacing value={0} mobile={82} />
        <VideoContainer>
          <VideoSection />
          <FormCTA onFormSuccess={this.handleFormSuccess} />
        </VideoContainer>
        {[
          <Top />,
          <AtelierSection />,
          <Artists />,
          <>
            <Feature />
            <Spacing value={100} />
            <Social />
          </>,
          <Form isFormModalSuccess={this.state.isFormModalSuccess} />,
        ].map((section, index) => (
          <WaypointTrigger key={index} once>
            {trigger => <FadeUp animate={trigger}> {section} </FadeUp>}
          </WaypointTrigger>
        ))}
      </Page>
    );
  }
}

const VideoContainer = styled.div`
  position: relative;
`;
