import React, { Component } from 'react';
import styled from 'styled-components';
import { Swipeable } from 'react-swipeable';

import { vw, vwMobile, vwMobile320 } from 'src/styles/utils';
import media from 'src/styles/media';

import Container from 'components/Container';

const slides = [
  {
    src: require('src/assets/images/musee/gallery/gryphon_musee_abovethetrees_photogallery_1.jpg'),
    title: `Above the Trees Exhibition`,
  },
  {
    src: require('src/assets/images/musee/gallery/gryphon_musee_abovethetrees_photogallery_2.jpg'),
    title: 'Above the Trees Exhibition',
  },
  {
    src: require('src/assets/images/musee/gallery/gryphon_musee_abovethetrees_photogallery_3.jpg'),
    title: 'Above the Trees Exhibition',
  },
  {
    src: require('src/assets/images/musee/gallery/1.jpg'),
    title: `"Let's Fly" Sculpture`,
  },
];

export default class Gallery extends Component {
  state = {
    activeSlide: 0,
  };

  handleNext = () => {
    let { activeSlide } = this.state;
    this.setState({
      activeSlide: activeSlide === slides.length - 1 ? 0 : activeSlide + 1,
    });
  };

  handlePrev = () => {
    let { activeSlide } = this.state;
    this.setState({
      activeSlide: activeSlide === 0 ? slides.length - 1 : activeSlide - 1,
    });
  };

  render() {
    const { activeSlide } = this.state;
    return (
      <Root>
        <Swipeable
          onSwipedRight={this.handleNext}
          onSwipedLeft={this.handlePrev}
          trackMouse={true}
        >
          <Slider>
            <Slides count={slides.length} active={activeSlide}>
              {slides.map((slide, index) => (
                <Slide image={slide.src} key={index} />
              ))}
            </Slides>
            <Next
              src={require('src/assets/images/icons/arrow.svg')}
              onClick={this.handleNext}
            />
            <Prev
              src={require('src/assets/images/icons/arrow.svg')}
              onClick={this.handlePrev}
            />
          </Slider>
        </Swipeable>
        <Wrapper>
          <Caption>{slides[activeSlide].title}</Caption>
          <Pagination>
            0{activeSlide + 1} | 0{slides.length}
          </Pagination>
        </Wrapper>
      </Root>
    );
  }
}

const Root = styled.div``;

const Slider = styled.div`
  width: ${vw(1200)};
  height: ${vw(670)};
  overflow: hidden;
  position: relative;
  @media (max-width: ${media.md}) {
    width: 100%;
    height: ${vwMobile(209.374999875)};
  }
`;

const Slides = styled.div`
  position: relative;
  display: flex;
  width: ${props => props.count * 100}%;
  height: 100%;
  transform: translateX(${props => (-100 / slides.length) * props.active}%);
  transition: transform 1s ease-in-out;
`;

const Slide = styled.div`
  width: 100%;
  height: 100%;
  background: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const Caption = styled.p``;

const Pagination = styled.div`
  padding-right: ${vw(100)};
  @media (max-width: ${media.md}) {
    padding-right: 0;
  }
`;

const Wrapper = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding: ${vw(20)} 0;
  color: ${({ theme }) => theme.color.primary};
  font-size: ${vw(10)};
  font-weight: 500;
  letter-spacing: ${vw(3)};
  text-transform: uppercase;
  padding-bottom: ${vw(140)};
  @media (max-width: ${media.md}) {
    padding: ${vwMobile(10)} 0;
    font-size: ${vwMobile(10)};
    letter-spacing: ${vwMobile(3)};
    padding-bottom: ${vwMobile320(40)};
  }
`;

const Next = styled.img`
  position: absolute;
  top: 50%;
  right: 2.5%;
  transform: translateY(-50%);
  width: ${vw(60)};
  cursor: pointer;
  @media (max-width: ${media.md}) {
    width: ${vwMobile(20)};
  }
`;

const Prev = styled.img`
  position: absolute;
  top: 50%;
  left: 2.5%;
  transform: translateY(-50%) scaleX(-1);
  width: ${vw(60)};
  display: none;
  cursor: pointer;
  @media (max-width: ${media.md}) {
    display: block;
    width: ${vwMobile(20)};
  }
`;
