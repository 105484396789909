module.exports = {
  defaultLocale: 'en',
  locales: ['en'],
  prerender: true,
  dbProject: 'Gryphon Corporate',
  gtag: 'UA-105583299-1',
  analytics: {
    gtag: '',
    gtmId: 'GTM-K49HV39',
    optimizeId: '',
    hotjarId: '',
  },
};
