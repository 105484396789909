import React, { Component } from 'react';
import styled from 'styled-components';
import scrollToElement from 'scroll-to-element';

import Spacing from 'components/Spacing';
import SubTitle from 'components/SubTitle';

import Container from 'components/Container';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

export default class Feature extends Component {
  handleClickButton = () => {
    scrollToElement('#atelier-form');
  };

  render() {
    return (
      <Root>
        <Spacing value={30} />
        <Wrapper>
          <div>
            <FeaturedImage
              src={require('src/assets/images/atelier/brent_comber.jpg')}
              alt="Brent Comber"
            />
          </div>
          <Content>
            <SubTitle>Artist &amp; Designer</SubTitle>
            <Spacing value={30} mobile={10} />
            <h1>
              Brent 
              Comber
            </h1>
            <Spacing value={0} mobile={10} />
            <FeaturedImageMobile
              src={require('src/assets/images/atelier/brent_comber.jpg')}
              alt="Brent Comber"
            />
            <Spacing value={40} mobile={15} />
            <Copy>
              <div>
                <p>
                  Brent Comber Originals is an art and design studio that
                  creates sculpted objects, functional pieces and design
                  environments. Brent’s work is deeply rooted in a knowledge of,
                  and respect for, place.
                </p>
                <p>
                  Materials are sustainably sourced from the surrounding area,
                  and designed and milled at his oceanfront workshop and
                  gallery. Through the exploration of materials; wood, stone,
                  glass, resources are taken at their purest form and
                  transformed into objects of recognition yet the fundamental
                  elements remain. Brent takes care to ensure that every piece
                  retains its story, its relationship with the greater system,
                  and that this story remains visible throughout his body of
                  work.
                </p>
              </div>
              <div>
                <p>
                  Brent’s own story is similarly rooted, he is the fourth
                  generation of his family to be raised within the Pacific
                  Northwest. Ranging from residential to commercial
                  installations, the spirit of the coast is embedded into his
                  work. This aesthetic interpretation has been recognized both
                  locally and globally, his work has been shown as far as Paris,
                  London, Japan, Bora Bora and beyond. Brent continues to
                  explore themes unique to the Pacific Northwest that connect
                  people to this unparalleled setting and the history and
                  dynamism of the landscape.
                </p>
              </div>
            </Copy>
          </Content>
        </Wrapper>
      </Root>
    );
  }
}

const Root = styled.div``;

const Wrapper = styled(Container)`
  display: flex;
  & > div {
    width: 100%;
  }
  @media (max-width: ${media.md}) {
    display: block;
  }
`;

const Content = styled.div`
  h1 {
    color: ${({ theme }) => theme.color.primary};
    font-size: ${vw(50)};
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1;
    max-width:50%;
    @media (max-width: ${media.lg}) {
      max-width:50%;
    }
    @media (max-width: ${media.md}) {
      max-width: 100%;
    }
    @media (max-width: ${media.sm}) {
      max-width:100%;
      font-size: ${vwMobile(45)};
    }
  
  }
  @media (max-width: ${media.md}) {
    text-align: center;
   
  }
`;

const Copy = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 1.56;
  text-align: left;
  & > * {
    width: 47.5%;
  }
  p {
    margin-bottom: ${vw(20)};
  }
  @media (max-width: ${media.md}) {
    display: block;
    & > * {
      width: 90%;
      margin:0 auto;
    }
    p{
      font-size: ${vw(30)};;
    }
  }
  @media (max-width: ${media.sm}) {
    display: block;
    & > * {
      width: 90%;
      margin:0 auto;
    }
    p {
      margin-bottom: ${vwMobile(20)};
      font-size: ${vwMobile(14)};
      
      
    }
  }
`;

const featuredImageWidth = 513;

const FeaturedImage = styled.img`
  width: ${vw(featuredImageWidth)};
  height: ${vw(featuredImageWidth * 1.461988304)};
  background: #111;
  @media (max-width: ${media.md}) {
    display: none;
  }
`;

const FeaturedImageMobile = styled.img`
  display: none;
  background: #111;
  width: 90%;
 
  margin: ${vw(50)} auto 0;
  @media (max-width: ${media.md}) {
    display: block;
  }
`;

// .827419355
