import React from 'react';
import styled from 'styled-components';

import { vw } from 'src/styles/utils';
import media from 'src/styles/media';

const Frame = () => {
  return (
    <Root>
      <Corner
        src={require('src/assets/images/curator/frame_corner.png')}
        alt="Frame"
        topLeft
      />
      <Line top image={require('src/assets/images/curator/frame_line.png')} />
      <Corner
        src={require('src/assets/images/curator/frame_corner.png')}
        alt="Frame"
        topRight
      />
      <Line
        right
        image={require('src/assets/images/curator/frame_line_right.png')}
      />
      <Corner
        src={require('src/assets/images/curator/frame_corner.png')}
        alt="Frame"
        bottomRight
      />
      <Line
        bottom
        image={require('src/assets/images/curator/frame_line_bottom.png')}
      />
      <Corner
        src={require('src/assets/images/curator/frame_corner.png')}
        alt="Frame"
        bottomLeft
      />
      <Line
        left
        image={require('src/assets/images/curator/frame_line_left.png')}
      />
    </Root>
  );
};

const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
  }
`;

const Corner = styled.img`
  width: ${vw(120)};
  height: ${vw(120)};
  position: absolute;
  top: ${props => (!props.bottomRight && !props.bottomLeft ? 0 : 'auto')};
  bottom: ${props => (!props.topRight && !props.topLeft ? 0 : 'auto')};
  left: ${props => (!props.topRight && !props.bottomRight ? 0 : 'auto')};
  right: ${props => (!props.topLeft && !props.bottomLeft ? 0 : 'auto')};
  transform: ${props => {
    if (props.topRight) {
      return 'rotate(90deg)';
    } else if (props.bottomRight) {
      return 'rotate(180deg)';
    } else if (props.bottomLeft) {
      return 'rotate(270deg)';
    } else {
      return;
    }
  }};
`;

const Line = styled.div`
  position: absolute;
  top: ${props => {
    if (props.top) {
      return 0;
    } else if (props.left || props.right) {
      return '50%';
    } else {
      return 'auto';
    }
  }};
  bottom: ${props => (props.bottom ? 0 : 'auto')};
  left: ${props => {
    if (props.left) {
      return 0;
    } else if (props.bottom || props.top) {
      return '50%';
    } else {
      return 'auto';
    }
  }};
  right: ${props => (props.right ? 0 : 'auto')};
  width: ${props =>
    !props.left && !props.right ? `calc(100% - ${vw(240)})` : vw(120)};
  height: ${props =>
    !props.top && !props.bottom ? `calc(100% - ${vw(240)})` : vw(120)};
  transform: ${props =>
    props.top || props.bottom ? 'translateX(-50%)' : 'translateY(-50%)'};
  background-image: url(${props => props.image});
  background-repeat: repeat;
  background-size: contain;
`;

export default Frame;
