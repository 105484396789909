import React, { Component } from 'react';
import styled from 'styled-components';

import PageContentBanner from 'components/PageContentBanner';

export default class Intro extends Component {
  render() {
    return (
      <Root>
        <PageContentBanner
          title="Great Art Demands the Perfect Display"
          subtitle="Our Developments"
        >
          <Content>
            Every Gryphon home is a showpiece and every location is a gallery.
            For that reason, we choose our neighbourhoods with specific
            expectations. They must be stimulating and welcoming. They must have
            an artistic, creative energy. And, like our homes, they must be
            unique in character.
          </Content>
        </PageContentBanner>
      </Root>
    );
  }
}

const Root = styled.div``;

const Content = styled.p`
  line-height: 1.625;
`;
