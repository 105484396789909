export default [
  {
    title: 'Dunbar & W. 41st',
    subtitle: 'Dunbar - Southlands',
  },
  {
    title: 'Cambie & W. 49th',
    subtitle: 'Cambie Corridor',
  },
];
