import React from 'react';
import styled from 'styled-components';

import { vwTablet, vwMobile320, vw } from 'src/styles/utils';
import media from 'src/styles/media';

const Hero = props => {
  const {
    image,
    component,
    title,
    cover,
    categories,
    activeSection,
    handleActiveSection,
    handleDropdown,
    isDropdownOpen,
    heroGradient,
  } = props;
  return (
    <Root image={image} cover={cover}>
      <GradientLayer isVisible={heroGradient} />
      <Logo
        src={require('src/assets/images/musee/gryphon_musee_logo.svg')}
        alt="Gryphon Musee"
      />
      <Content>
        <div>
          <h1>
            Art lifts the spirits.
            <br />
            It inspires innovation.
          </h1>
          <p>
            Gryphon Musée is an intimate gallery with rotating exhibitions
            featuring both established and aspiring artists. It is a platform we
            created to support and encourage community conversations inspired by
            art. We believe that art elevates the world in countless exciting
            ways.
          </p>
        </div>
      </Content>
      <Menu>
        <Selected onClick={handleDropdown} isDropdownOpen={isDropdownOpen}>
          <div className="content">
            <p>{title}</p>
          </div>
          <div className="down">
            <img
              src={require('src/assets/images/musee/current/arrow-down-yellow.svg')}
              alt="Select"
            />
          </div>
          <div className="background" />
        </Selected>
        <Dropdown active={isDropdownOpen}>
          {categories.map((item, index) => (
            <Category
              active={item.id === activeSection}
              onClick={() => handleActiveSection(item.id)}
              key={index}
            >
              <p className="description">{item.title}</p>
            </Category>
          ))}
        </Dropdown>
      </Menu>
      <Container>
        <div className="content" id="content">
          {component}
        </div>
        <div className="background" />
      </Container>
    </Root>
  );
};

export default Hero;

const Root = styled.div`
  width: 100vw;
  background-image: url(${props => props.image});
  background-color: white;
  background-size: ${props =>
    props.cover ? 'cover' : `auto ${vwMobile320(821)}`};
  background-position: ${props => (props.cover ? 'center' : 'top')};
  background-repeat: no-repeat;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${vwMobile320(24)} 0 0;
  position: relative;
  > div:not(:first-child) {
    z-index: 2;
  }
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(44)} 0 0;
    background-size: ${props =>
      props.cover ? 'cover' : `auto ${vwTablet(1027)}`};
    background-position: ${props => (props.cover ? 'center' : 'top')};
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(44)} 0 0;
    background-size: ${props => (props.cover ? 'cover' : `100% ${vw(1343)}`)};
    background-position: ${props => (props.cover ? 'center' : 'top')};
  }
  .description {
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(16)};
    }
  }
`;

const GradientLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: ${vwMobile320(972)};
  width: 100%;
  background-blend-mode: multiply;
  background-image: ${props => {
    switch (props.isVisible) {
      case 1:
        return `linear-gradient(to bottom, #101E27, #ffffff);`;
      case 2:
        return `linear-gradient(to bottom, #444242 33%, rgba(110, 110, 110, 0) 79%);
 `;
      default:
        return null;
    }
  }};
  opacity: ${props => (props.isVisible ? 0.3 : 0)};

  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(1215)};
    opacity: 0;
  }
  @media (min-width: ${media.mdup}) {
    height: 100%;
  }
`;

const Content = styled.div`
  margin: ${vwMobile320(60)} ${vwMobile320(12)};
  pointer-events: none;
  @media (min-width: ${media.xsup}) {
    margin: ${vwTablet(103)} ${vwTablet(30)} ${vwTablet(120)};
  }
  @media (min-width: ${media.mdup}) {
    /* margin: ${vw(200)} auto; */
    display: flex;
    align-items: center;
    width: ${vw(1063)};
    height: ${vw(100)};
    max-height: 20vh;
    div {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
  h1 {
    font-family: ${({ theme }) => theme.font.header};
    font-size: ${vwMobile320(25)};
    font-weight: 500;
    line-height: 1.4;
    text-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
    margin-bottom: ${vwMobile320(10)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(45)};
      line-height: 1.22;
      margin-bottom: ${vwTablet(40)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(45)};
      margin-bottom: 0;
    }
  }
  p {
    text-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
    margin-left: ${vwMobile320(50)};
    @media (min-width: ${media.xsup}) {
      margin-left: ${vwTablet(303)};
    }
    @media (min-width: ${media.mdup}) {
      margin-left: 0;
      width: ${vw(406)};
    }
  }
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  @media (min-width: ${media.xsup}) {
    margin: 0 auto;
    width: ${vwTablet(709)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(1061)};
  }
  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: #dfc001;
    opacity: 0.7;
    z-index: 1;
    @media (min-width: ${media.mdup}) {
    }
  }
  .content {
    z-index: 2;
    position: relative;
    padding: ${vwMobile320(20)} ${vwMobile320(22)};
    @media (min-width: ${media.xsup}) {
      padding: ${vwTablet(60)} ${vwTablet(30)};
    }
    @media (min-width: ${media.mdup}) {
      padding: ${vw(60)} ${vw(40)};
    }
  }
`;

const Logo = styled.img`
  width: ${vwMobile320(100)};
  height: auto;
  z-index: 2;
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(161)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(161)};
  }
`;

const Menu = styled.div`
  padding: 0 ${vwMobile320(12)};
  width: 100%;
  @media (min-width: ${media.xsup}) {
    padding: 0;
  }
  @media (min-width: ${media.mdup}) {
    padding: 0;
  }
`;

const Selected = styled.div`
  width: 100%;
  height: ${vwMobile320(40)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  @media (min-width: ${media.xsup}) {
    display: none;
  }
  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background-color: #dfc001;
    z-index: 1;
  }
  .content {
    z-index: 2;
    position: relative;
    padding-left: ${vwMobile320(15)};
  }
  p {
    font-size: ${vwMobile320(12)};
    font-weight: 500;
    letter-spacing: ${vwMobile320(2)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.background};
  }
  .down {
    width: ${vwMobile320(60)};
    height: 100%;
    background-blend-mode: multiply;
    z-index: 2;
    background-color: rgb(2, 45, 60);
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      transform: ${props =>
        props.isDropdownOpen ? 'scaleY(-1)' : 'scaleY(1)'};
      transition: 0.3s ease;
    }
  }
`;

const Category = styled.div`
  padding: ${vwMobile320(14)} ${vwMobile320(15)};
  border-bottom: ${vwMobile320(1)} solid
    ${({ theme }) => theme.color.background};
  font-family: ${({ theme }) => theme.font.body};
  font-size: ${vwMobile320(12)};
  font-weight: 500;
  letter-spacing: ${vwMobile320(2)};
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.background};
  cursor: pointer;
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(11)} ${vwTablet(20)};
    border-bottom: 0;
    font-size: ${vwTablet(14)};
    letter-spacing: ${vwTablet(3)};
    background-color: ${props =>
      props.active ? 'rgba(223, 192, 1,0.7)' : 'transparent'};
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(10)} ${vw(20)};
    border-bottom: 0;
    font-size: ${vw(14)};
    letter-spacing: ${vw(3)};
  }
  p {
    opacity: ${props => (props.active ? 0.5 : 1)};
    transition: 0.3s ease;
    @media (min-width: ${media.xsup}) {
      opacity: 1;
    }
  }
  &:last-of-type {
    border-bottom: 0;
  }
`;

const Dropdown = styled.div`
  max-height: ${props => (props.active ? '500px' : 0)};
  transition: 0.3s ease;
  overflow: hidden;
  @media (min-width: ${media.xsup}) {
    max-height: none;
    display: flex;
    width: ${vwTablet(709)};
    margin: 0 auto;
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(1061)};
  }
`;
