import React, { Component } from 'react';
import styled from 'styled-components';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import Container from 'components/Container';
import Spacing from 'components/Spacing';
import SubTitle from 'components/SubTitle';
import Button from 'components/Button';

const creatives = [
  {
    logo: require('src/assets/images/about/gryphon-musee.svg'),
    copy:
      'A platform and gallery space that celebrates contemporary artists and their respective work.',
    website: '/musee',
  },
  {
    logo: require('src/assets/images/about/gryphon-atelier.svg'),
    copy: 'A collaborative, artist-driven approach to real estate development.',
  },
];

export default class CreativeSection extends Component {
  render() {
    return (
      <Container>
        <Root>
          <Intro>
            Gryphon Development creates "Living Art" by nurturing and supporting
            visionary artists committed to shaping the future of living, both
            locally and globally.
          </Intro>
          <Spacing value={120} mobile={90} />
          <Content>
            <SubTitle>Driven By Creative Spirit</SubTitle>
            <Spacing value={60} />
            <Creatives>
              {creatives.map((creative, index) => (
                <Creative key={index}>
                  <img src={creative.logo} alt="" />
                  <Spacing value={30} />
                  <p>{creative.copy}</p>
                  <Spacing value={40} />
                  {creative.website && (
                    <Button to={creative.website}>Visit Website</Button>
                  )}
                  <Spacing value={90} mobile={50} />
                </Creative>
              ))}
            </Creatives>
            <Spacing value={40} />
          </Content>
        </Root>
      </Container>
    );
  }
}

const Root = styled.div``;

const Intro = styled.p`
  max-width: ${vw(960)};
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.primary};
  line-height: 1.57;
  letter-spacing: ${vw(3.5)};
  @media (max-width: ${media.md}) {
    max-width: 100%;
    letter-spacing: ${vwMobile(3.5)};
  }
`;

const Content = styled.div`
  text-align: center;
`;

const Creatives = styled.div`
  display: flex;
  @media (max-width: ${media.md}) {
    display: block;
  }
`;

const Creative = styled.div`
  width: 100%;
  img {
    display: block;
    margin: 0 auto;
    height: ${vw(100)};
  }
  p {
    max-width: ${vw(450)};
    margin: 0 auto;
    line-height: 1.25;
  }
  @media (max-width: ${media.md}) {
    img {
      height: ${vwMobile(60)};
      max-height: ${vwMobile(60)};
    }
    p {
      max-width: 100%;
    }
  }
`;
