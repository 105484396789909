import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link, withRouter } from 'react-router-dom';

import GradientText from 'components/GradientText';
import MenuBackground from 'components/Header/elements/Background';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

const Menu = props => {
  const { sticky, history } = props;

  const [hasScrolled, setHasScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (sticky) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (sticky) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleScroll = e => {
    if (e.target.scrollingElement.scrollTop > window.innerHeight) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  };

  return (
    <>
      <Root sticky={sticky} hasScrolled={hasScrolled}>
        <Main onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <StyledLink to="/" active={history.location.pathname === '/'} second>
            <GradientText>Home</GradientText>
            <img
              src={require('src/assets/images/curator/down_arrow.svg')}
              alt=""
            />
          </StyledLink>
          <Line left />
          <StyledLink
            to="/services"
            active={history.location.pathname.includes('/services')}
          >
            <GradientText>Services</GradientText>
            <img
              src={require('src/assets/images/curator/down_arrow.svg')}
              alt=""
            />
          </StyledLink>
          <Line />
          <StyledLink
            to="/contact"
            active={history.location.pathname.includes('/contact')}
            second
          >
            <GradientText>Contact</GradientText>
            <img
              src={require('src/assets/images/curator/down_arrow.svg')}
              alt=""
            />
          </StyledLink>
        </Main>
        <Submenu
          active={isMenuOpen}
          style={{
            transform: isMenuOpen
              ? 'translateX(-50%) scaleY(1)'
              : 'translateX(-50%) scaleY(0)',
          }}
        >
          <SubLink
            to="/"
            disabled={history.location.pathname === '/'}
            active={history.location.pathname === '/'}
          >
            Home
          </SubLink>
          <Line />
          <SubLink
            to="/services"
            disabled={history.location.pathname.includes('/services')}
            active={history.location.pathname.includes('/services')}
          >
            Services
          </SubLink>
          <Line />
          <SubLink
            to="/contact"
            disabled={history.location.pathname.includes('/contact')}
            active={history.location.pathname.includes('/contact')}
          >
            Contact
          </SubLink>
        </Submenu>
      </Root>
      <Background style={{ opacity: isMenuOpen ? 1 : 0 }}>
        <div />
        <div />
      </Background>
      <MenuBackground />
    </>
  );
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const grow = keyframes`
  0 {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
`;

const Root = styled.div`
  position: fixed;
  top: ${vwMobile(30)};
  z-index: 7999;
  width: ${vwMobile(102)};
  height: ${vwMobile(16)};
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  a {
    font-family: ${({ theme }) => theme.font.curator};
    text-transform: uppercase;
    font-weight: 500;
    font-size: ${vwMobile(12)};
    letter-spacing: ${vwMobile(3.5)};
  }
  @media (min-width: ${media.xsup}) {
    position: ${props => (props.sticky ? 'sticky' : 'fixed')};
    margin-top: ${props => (props.sticky ? vwTablet(-40) : 0)};
    top: ${vwTablet(40)};
    left: auto;
    transform: none;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease;
    opacity: ${props => (props.sticky && !props.hasScrolled ? 0.6 : 1)};
    a {
      font-size: ${vwTablet(12)};
      letter-spacing: ${vwTablet(3.5)};
    }
    a:first-of-type {
      margin-left: ${vwTablet(30)};
    }
  }
  @media (min-width: ${media.mdup}) {
    margin-top: ${props => (props.sticky ? vw(-60) : 0)};
    top: ${vw(60)};
    a {
      font-size: ${vw(12)};
      letter-spacing: ${vw(3.5)};
      position: relative;
      &::after {
        content: '';
        width: 100%;
        display: block;
        position: absolute;
        min-height: 1px;
        height: ${vw(1)};
        bottom: ${vw(-7)};
        background-color: ${({ theme }) => theme.color.primary};
        transform: scaleX(0);
        transition: all 0.5s;
      }
      &:hover::after {
        transform: scaleX(1);
      }
    }
    a:first-of-type {
      margin-left: ${vw(30)};
    }
  }
  @media (min-width: ${media.textbreak}) {
    a {
      font-size: 16px;
      letter-spacing: 4.67px;
    }
  }
`;

const Main = styled.div`
  position: relative;
  height: 100%;
  @media (min-width: ${media.xsup}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Line = styled.div`
  background-image: linear-gradient(
    to right,
    #d29379 0%,
    #efc8a2 51%,
    #ecc39e 62%,
    #e5b593 74%,
    #d89e81 88%,
    #d29379 100%
  );
  display: block;
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(40)};
    height: 1px;
    margin: 0 ${vwTablet(20)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(80)};
    margin: 0 ${vw(40)};
    transform: scaleX(0);
    animation: ${grow} 0.5s ease-in-out forwards 1.5s;
    transform-origin: ${props => (props.left ? 'right' : 'left')};
  }
`;

const Submenu = styled.div`
  position: absolute;
  top: ${`calc(100% + ${vwMobile(7)})`};
  left: 50%;
  transform: translateX(-50%);
  width: ${vwMobile(92)};
  overflow: hidden;
  transform-origin: top;
  transition: 0.5s ease;
  a {
    display: block;
    height: ${vwMobile(30)};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${vwMobile(8)} 0;
    position: relative;
    color: white;
  }
  a::before {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.6;
    background-image: linear-gradient(
      to right,
      #d29379 0%,
      #efc8a2 51%,
      #ecc39e 62%,
      #e5b593 74%,
      #d89e81 88%,
      #d29379 100%
    );
  }
  @media (min-width: ${media.xsup}) {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: ${props => (props.active ? 'all' : 'none')};
  transition: 0.5s ease;
  pointer-events: none;
  img {
    margin-left: ${vwMobile(4)};
  }
  @media (min-width: ${media.xsup}) {
    position: static;
    width: auto;
    height: auto;
    opacity: 0;
    pointer-events: all;
    animation: ${fadeIn} 0.4s ease-in-out forwards;
    animation-delay: ${props => (props.second ? '2s' : '1s')};
    img {
      display: none;
    }
  }
`;

const SubLink = styled(Link)`
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};
  color: ${props =>
    props.disabled
      ? 'rgba(255, 255, 255, 0.3)'
      : 'rgba(255, 255, 255, 1)'} !important;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  z-index: 999;
  transition: 0.5s ease;
  > div:first-of-type {
    width: 100%;
    height: ${vwMobile(150)};
    background-blend-mode: multiply;
    background-image: linear-gradient(to bottom, #101e27, #101e27);
  }
  > div:last-of-type {
    width: 100%;
    height: ${vwMobile(240)};
    background-blend-mode: multiply;
    background-image: linear-gradient(
      to bottom,
      #101e27 23%,
      rgba(16, 30, 39, 0.7) 68%,
      rgba(16, 30, 39, 0)
    );
  }
  @media (min-width: ${media.xsup}) {
    display: none;
  }
`;

export default withRouter(Menu);
