import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import Spacing from 'components/Spacing';
import GradientText from 'components/GradientText';

export default class VideoPlayer extends Component {
  state = {
    showVideo: false,
  };

  static propTypes = {
    url: PropTypes.string,
    background: PropTypes.string,
    aspectRatio: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.$player = React.createRef();
  }

  handleClickPlay = () => {
    this.setState({
      showVideo: true,
    });
    this.play();
  };

  play = () => {
    if (this.props.videoSrc) {
      this.$player.current.play();
    } else {
      this.$player.current.getInternalPlayer().play();
    }
  };

  render() {
    return (
      <Root aspectRatio={this.props.aspectRatio} ref={this.props.ref}>
        <VideoContainer>
          {this.props.videoSrc ? (
            <video
              src={this.props.url}
              width="100%"
              height="100%"
              ref={this.$player}
              playsInline
              controls
            />
          ) : (
            <ReactPlayer
              url={this.props.url}
              playing={this.props.playing}
              width="100%"
              height="100%"
              ref={this.$player}
              playsinline
            />
          )}
        </VideoContainer>
        <VideoThumbnail
          show={!this.state.showVideo}
          background={this.props.background}
        />
        <Content show={!this.state.showVideo}>{this.props.children}</Content>
        <CTA show={!this.state.showVideo}>
          <PlayButton
            src={require('src/assets/images/icons/play.svg')}
            onClick={this.handleClickPlay}
          />
          <Spacing value={40} mobile={28} />
          {this.props.atelier ? (
            <>
              <p>Now Featuring</p>
              <Spacing value={10} mobile={5} />
              <p>
                <GradientText>Brent Comber</GradientText>
              </p>
            </>
          ) : (
            <>
              <p>
                <GradientText>Play Video</GradientText>
              </p>
            </>
          )}
        </CTA>
      </Root>
    );
  }
}

const Root = styled.div`
  position: relative;
  &::after {
    content: '';
    display: block;
    padding-top: ${props => (props.aspectRatio ? props.aspectRatio : 56.25)}%;
    background: #ccc;
  }
`;

const VideoThumbnail = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => `url(${props.background})`};
  background-size: cover;
  background-position: center;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: all 1s;
  pointer-events: ${props => (props.show ? 'all' : 'none')};
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }
`;

const CTA = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${props => (props.show ? 1 : 0)};
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
  transition: all 1s;
  p {
    font-size: ${vw(13)};
    font-weight: 500;
    letter-spacing: ${vw(3)};
    text-transform: uppercase;
    @media (max-width: ${media.md}), (max-width: ${media.lg}) {
      font-size: ${vw(13)};
    }
    @media (max-width: ${media.sm}) {
      font-size: ${vwMobile(10)};
      letter-spacing: ${vwMobile(3)};
    }
    span {
      font-size: ${vw(30)};
      margin-top: ${vw(0)};
      @media (max-width: ${media.sm}) {
        font-size: ${vwMobile(17)};
      }
    }
  }
`;

const PlayButton = styled.img`
  width: ${vw(95)};
  cursor: pointer;
  @media (max-width: ${media.md}) {
    width: ${vwMobile(45)};
  }
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  video {
    width: 100%;
    height: 100%;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: all 1s;
`;
