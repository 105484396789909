import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import SubTitle from 'components/SubTitle';
import Container from 'components/Container';
import Spacing from 'components/Spacing';

export default class Intro extends Component {
  render() {
    return (
      <Root>
        <Background
          src={require('src/assets/images/about/art-lives-here.svg')}
        />
        <Container>
          <Spacing value={90} />
          <StyledSubTitle style={{ position: 'relative' }}>
            About
          </StyledSubTitle>
          <Spacing value={50} />
          <Title>
            Our Story <br />
            Begins <br />
            with Art
          </Title>
          <Spacing value={20} />
          <Copy>
            At Gryphon Development, art is at the core of everything we do. It
            challenges our expectations and reflects the way we see the world.
            For art to make a significant impact on the way we shape community,
            we must first re-imagine its role in the way we craft our homes. Our
            Story Begins with Art. Art Lives Here.
          </Copy>
        </Container>
      </Root>
    );
  }
}

const animateFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Root = styled.div`
  text-align: center;
  position: relative;
`;

const Title = styled.h1`
  position: relative;
  color: ${({ theme }) => theme.color.primary};
  font-size: ${vw(86)};
  font-weight: 400;
  line-height: 1.1;
  opacity: 0;
  animation: ${animateFadeIn} 2s 1.75s forwards;
  @media (max-width: ${media.md}) {
    font-size: ${vwMobile(44)};
  }
`;

const StyledSubTitle = styled(SubTitle)`
  opacity: 0;
  animation: ${animateFadeIn} 2s 1s forwards;
`;

const Copy = styled.p`
  position: relative;
  max-width: ${vw(645.12)};
  margin: 0 auto;
  font-weight: 200;
  letter-spacing: ${vw(0.75)};
  line-height: 1.25;
  opacity: 0;
  animation: ${animateFadeIn} 2s 2.5s forwards;
  @media (max-width: ${media.md}) {
    line-height: 1.75;
    max-width: 90%;
  }
`;

const Background = styled.img`
  position: absolute;
  top: 67.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${vw(625)};
  animation: ${animateFadeIn} 3s 0s;
  @media (max-width: ${media.md}) {
    top: 45%;
    width: 95%;
  }
`;
