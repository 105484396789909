import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import media from 'src/styles/media';
import { vw, vwTablet, vwMobile320 } from 'src/styles/utils';

const Popup = () => {
  const [visible, setVisible] = useState(true);

  return (
    <Root active={visible}>
      <Wrapper>
        <Close
          src={require('src/assets/images/musee/close.svg')}
          alt="Close Popup"
          onClick={() => setVisible(false)}
        />
        <Collage
          src={require('src/assets/images/musee/popup/gryphon_x_ids_imagecollage.png')}
          alt="Image Collage"
        />
        <Container>
          <Version
            src={require('src/assets/images/musee/popup/gryphon_version_infinity_logo.svg')}
            alt="Version Infinity"
          />
        </Container>
        <Artist>
          <h5>
            The Story
            <br /> of Creative Expression
            <br /> to Realized Creations
            <br /> in Design
          </h5>
        </Artist>
        <Container>
          <Logos>
            <img
              src={require('src/assets/images/musee/popup/gryphon_x_ids_prototypeexhibition_logo.svg')}
              alt="Gryphon x IDS"
            />
            <img
              src={require('src/assets/images/musee/popup/gryphon_x_ids_prototypeexhibition_logos_white.svg')}
              alt="Gryphon x IDS"
            />
          </Logos>
        </Container>
        <Button to="/musee" onClick={() => setVisible(false)}>
          Learn More
        </Button>
      </Wrapper>
    </Root>
  );
};

export default Popup;

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  padding: ${vwMobile320(13)} ${vwMobile320(13)} ${vwMobile320(10)};
  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: ${props => (props.active ? 'all' : 'none')};
  transition: 0.3s ease;
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(105)} ${vwTablet(90)} ${vwTablet(98)};
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(55)} 0;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.backgroundDark};
  position: relative;
  padding: ${vwMobile320(159)} 0 ${vwMobile320(22)};
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(300)} ${vwTablet(40)} ${vwTablet(37)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(1070)};
    height: ${vw(650)};
    padding: ${vw(80)} ${vw(80)} ${vw(40)};
    margin: 0 auto;
  }
`;

const Collage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: ${vwMobile320(172)};
  z-index: 1;
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(300)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(375)};
  }
`;

const Container = styled.div`
  padding: 0 ${vwMobile320(15)};
  @media (min-width: ${media.xsup}) {
    padding: 0;
  }
`;

const Version = styled.img`
  width: ${vwMobile320(150)};
  height: auto;
  display: block;
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(300)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(375)};
    height: ${vw(134)};
  }
`;

const Close = styled.img`
  position: absolute;
  width: ${vwMobile320(20)};
  height: ${vwMobile320(20)};
  top: ${vwMobile320(15)};
  right: ${vwMobile320(15)};
  cursor: pointer;
  z-index: 3;
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(20)};
    height: ${vwTablet(20)};
    top: ${vwTablet(43)};
    right: ${vwTablet(40)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(20)};
    height: ${vw(20)};
    top: ${vw(32)};
    right: ${vw(25)};
  }
`;

const Artist = styled.div`
  background-color: #e0c612;
  padding: ${vwMobile320(35)} ${vwMobile320(15)};
  margin-top: ${vwMobile320(15)};
  position: relative;
  z-index: 2;
  h5 {
    font-family: ${({ theme }) => theme.font.body};
    font-size: ${vwMobile320(14)};
    font-weight: 500;
    line-height: 1.71;
    letter-spacing: ${vwMobile320(2)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.backgroundDark};
  }
  p {
    color: ${({ theme }) => theme.color.backgroundDark};
    font-family: ${({ theme }) => theme.font.body};
    font-size: ${vwMobile320(12)};
    letter-spacing: ${vwMobile320(2)};
    text-transform: uppercase;
  }
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(40)};
    margin-top: ${vwTablet(20)};
    h5 {
      font-size: ${vwTablet(16)};
      line-height: 1.75;
      letter-spacing: ${vwTablet(3)};
    }
    p {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(2)};
    }
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(40)};
    width: ${vw(520)};
    h5 {
      font-size: ${vw(16)};
      letter-spacing: ${vw(3)};
    }
    p {
      font-size: ${vw(14)};
      letter-spacing: ${vw(2)};
    }
  }
`;

const Logos = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${vwMobile320(20)};
  img:first-of-type {
    width: ${vwMobile320(100)};
  }
  img:last-of-type {
    height: ${vwMobile320(24)};
  }
  @media (min-width: ${media.xsup}) {
    margin-top: ${vwTablet(20)};
    img:first-of-type {
      width: ${vwTablet(143)};
    }
    img:last-of-type {
      height: ${vwTablet(40)};
      margin-right: ${vwTablet(20)};
    }
  }
  @media (min-width: ${media.mdup}) {
    margin-top: ${vw(22)};
    justify-content: flex-start;
    img:first-of-type {
      width: ${vw(143)};
      margin-right: ${vw(40)};
    }
    img:last-of-type {
      height: ${vw(40)};
    }
  }
`;

const Button = styled(Link)`
  width: ${vwMobile320(160)};
  height: ${vwMobile320(28)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${vwMobile320(40)} auto 0;
  border-radius: ${vwMobile320(50)};
  background-image: linear-gradient(to top, #e0bd12 67%, #e0c612);
  font-family: ${({ theme }) => theme.font.body};
  font-size: ${vwMobile320(11)};
  font-weight: bold;
  letter-spacing: ${vwMobile320(2.75)};
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.background};
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(222)};
    height: ${vwTablet(41)};
    margin: ${vwTablet(40)} 0 0;
    border-radius: ${vwTablet(50)};
    font-size: ${vwTablet(11)};
    letter-spacing: ${vwTablet(2.75)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(222)};
    height: ${vw(41)};
    margin: ${vw(40)} 0 0;
    border-radius: ${vw(50)};
    font-size: ${vw(11)};
    letter-spacing: ${vw(2.75)};
  }
`;
