import React from 'react';

import CurrentExhbition from 'src/pages/Musee/sections/CurrentExhibition';
import IDS from 'src/pages/Musee/sections/IDS';
import PastEvents from 'src/pages/Musee/sections/PastEvents';
import Visit from 'src/pages/Musee/sections/Visit';
import Join from 'src/pages/Musee/sections/Join';

export default {
  'current-exhibition': {
    id: 'current-exhibition',
    title: 'Current Exhibition',
    hero: [
      require('src/assets/images/musee/current/gryphon_musee_hero_currentexhibition_1.jpg'),
      require('src/assets/images/musee/current/gryphon_musee_hero_currentexhibition_2.jpg'),
      require('src/assets/images/musee/current/gryphon_musee_hero_currentexhibition_3.jpg'),
    ],
    cover: false,
    component: <CurrentExhbition />,
    extraContent: <IDS />,
    heroGradient: 1 /*variant 1 for Current Exhibition*/,
  },
  'past-events': {
    id: 'past-events',
    title: 'Past Events',
    hero: require('src/assets/images/musee/past/gryphon_musee_hero_pastevent.jpg'),
    cover: false,
    component: <PastEvents />,
    heroGradient: 2 /*variant 2 for Past Events*/,
  },
  visit: {
    id: 'visit',
    title: 'Visit',
    hero: require('src/assets/images/musee/visit/gryphon_musee_hero_visit.jpg'),
    cover: true,
    component: <Visit />,
  },
  join: {
    id: 'join',
    title: 'Join',
    hero: require('src/assets/images/musee/join/gryphon_musee_hero_join.jpg'),
    cover: true,
    component: <Join />,
  },
};
