const CONTAINER_WIDTH = 1440;
const MOBILE_WIDTH = 375;
const TABLET_WIDTH = 768;

export const vw = width => {
  return `${(width / CONTAINER_WIDTH) * 100}vw`;
};

export const vwMobile = width => {
  return `${(width / MOBILE_WIDTH) * 100}vw`;
};

export const vwMobile320 = width => {
  return `${(width / 320) * 100}vw`;
};

export const vwTablet = width => {
  return `${(width / TABLET_WIDTH) * 100}vw`;
};
