import React from 'react';
import styled from 'styled-components';

import { vw, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

export default function Select(props) {
  return <Root {...props} />;
}

const Root = styled.select`
  -webkit-appearance: none;
  outline: none;
  display: block;
  padding: ${vw(5)};
  border: none;
  border-bottom: ${vw(1)} solid ${({ theme }) => theme.color.primary};
  background: transparent;
  font-size: ${vw(14)};
  color: #fff;
  border-radius: 0px;
  @media (max-width: ${media.md}) {
    padding: ${vwMobile(5)};
    font-size: ${vwMobile(14)};
  }
`;
