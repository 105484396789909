import React from 'react';
import styled from 'styled-components';

export default function GradientText(props) {
  return (
    <Root
      {...props}
      style={{
        WebkitTextFillColor: 'transparent',
        WebkitBackgroundClip: 'text',
      }}
    />
  );
}

const Root = styled.span`
  background-image: linear-gradient(
    90deg,
    rgb(210, 147, 121) 0px,
    rgb(239, 199, 161) 20%,
    rgb(236, 194, 157) 50%,
    rgb(228, 180, 146) 65%,
    rgb(215, 157, 129) 85%,
    rgb(210, 147, 121)
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
`;
