import React from 'react';
import styled from 'styled-components';

import { vw, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

const ExtraContent = () => {
  return <Root />;
};

export default ExtraContent;

const Root = styled.div`
  padding-top: ${vw(460)};
  width: ${vw(1063)};
  margin: 0 auto;
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(708)};
    padding-top: ${vwTablet(282)};
    background-color: ${({ theme }) => theme.color.backgroundDark};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(1063)};
    padding-top: ${vw(460)};
  }
`;
