import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import anime from 'animejs';
import { Waypoint } from 'react-waypoint';

const TaglineFade = props => {
  const { data, delay, autoplay, title } = props;
  const { width, height, viewBox, transform, fill, letters } = data;

  const [target, setTarget] = useState(null);
  const [hasEntered, setHasEntered] = useState(false);

  useEffect(() => {
    let animation = anime.timeline({ loop: false }).add(
      {
        targets: `#${`tagline-${title}`} .letter`,
        translateY: [-40, 0],
        translateZ: 0,
        opacity: [0, 1],
        easing: 'easeOutExpo',
        duration: 1500,
        autoplay: autoplay ? true : false,
        delay: (el, i) => 500 + 30 * i,
      },
      delay
    );
    setTarget(animation);
  }, []);

  const handlePlay = () => {
    if (!hasEntered) {
      target.play();
    }
    setHasEntered(true);
  };

  return (
    <Waypoint onEnter={autoplay ? f => f : handlePlay}>
      <Root
        width={width}
        height={height}
        viewBox={viewBox}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id={`tagline-${title}`} transform={transform} fill={fill}>
          {letters.map((item, index) => item.element)}
        </g>
      </Root>
    </Waypoint>
  );
};

TaglineFade.defaultProps = {
  delay: 0,
};

const Root = styled.svg`
  width: 100%;
  .letter {
    opacity: 0;
  }
`;

export default TaglineFade;
