import React from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwMobile320 } from 'src/styles/utils';
import media from 'src/styles/media';

const Visit = () => {
  return (
    <Root>
      <Section>
        <h1>Hours</h1>
        <div>
          <p>Monday to Friday (except holidays)</p>
          <p>11AM - 4PM</p>
        </div>
      </Section>
      <Section>
        <h1>Location</h1>
        <div>
          <p>6111 West Boulevard</p>
          <p>Vancouver, BC</p>
          <p>V6M 3X3</p>
          <a
            href="https://www.google.com/maps/place/6111+West+Blvd,+Vancouver,+BC+V6M+3X3/@49.230598,-123.1581158,17z/data=!3m1!4b1!4m5!3m4!1s0x5486737cc83ee6cb:0x92b40f899b73156c!8m2!3d49.230598!4d-123.1559218"
            target="_blank"
            rel="noopener noreferrer"
            className="map"
          >
            Map
          </a>
        </div>
      </Section>
      <Section>
        <h1>Contact Us</h1>
        <div>
          <a href="tel:604-620-8296">604.620.8296</a>
          <a href="mailto:musee@gryphonliving.com">musee@gryphonliving.com</a>
          <p className="italic">
            For artist inquires, please include a link to your website or
            portfolio.{' '}
          </p>
        </div>
      </Section>
      <img
        src={require('src/assets/images/musee/visit/visit_bottom_photo.jpg')}
        alt="Gryphon Musee"
        className="bottom-photo"
      />
    </Root>
  );
};

export default Visit;

const Root = styled.div`
  .bottom-photo {
    width: 100%;
    height: ${vwMobile320(141)};
    margin-bottom: ${vwMobile320(-130)};
    @media (min-width: ${media.xsup}) {
      height: ${vwTablet(332)};
      margin-bottom: ${vwTablet(-325)};
    }
    @media (min-width: ${media.mdup}) {
      height: ${vw(500)};
      margin-bottom: ${vw(-480)};
    }
  }
`;

const Section = styled.div`
  border-bottom: 1px solid rgba(2, 45, 60, 0.5);
  padding-bottom: ${vwMobile320(30)};
  padding-top: ${vwMobile320(30)};
  color: ${({ theme }) => theme.color.background};
  margin-left: ${vwMobile320(18)};
  &:last-of-type {
    border-bottom: 0;
    padding-bottom: ${vwMobile320(60)};
  }
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(585)};
    display: flex;
    justify-content: space-between;
    padding-bottom: ${vwTablet(40)};
    padding-top: ${vwTablet(40)};
    margin-left: 0;
    &:last-of-type {
      border-bottom: 0;
      padding-bottom: ${vwTablet(120)};
    }
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(585)};
    padding-bottom: ${vw(40)};
    padding-top: ${vw(40)};
    &:last-of-type {
      border-bottom: 0;
      padding-bottom: ${vw(120)};
    }
  }
  &:first-of-type {
    padding-top: 0;
  }
  > div {
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(298)};
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(300)};
    }
  }
  a {
    display: block;
    color: ${({ theme }) => theme.color.background};
    line-height: 1.56;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(16)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(16)};
    }
  }
  .map {
    font-weight: 500;
    line-height: normal;
    letter-spacing: ${vwMobile320(2)};
    text-transform: uppercase;
    margin-top: ${vwMobile320(24)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(2)};
      margin-top: ${vwTablet(20)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(14)};
      letter-spacing: ${vw(2)};
      margin-top: ${vw(20)};
      position: relative;
      width: 100%;
      &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        width: ${vw(38.58)};
        height: 2px;
        background-color: ${({ theme }) => theme.color.background};
        transition: 0.3s ease;
        opacity: 0;
      }
      &:hover {
        &::after {
          opacity: 1;
        }
      }
    }
  }
  h1 {
    font-family: ${({ theme }) => theme.font.header};
    font-size: ${vwMobile320(18)};
    font-weight: 500;
    line-height: 1.67;
    color: #003345;
    margin-bottom: ${vwMobile320(30)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(35)};
      line-height: 1.43;
      margin-bottom: 0;
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(35)};
    }
  }
  .italic {
    font-size: ${vwMobile320(12)};
    line-height: 1.5;
    font-style: italic;
    margin-top: ${vwMobile320(16)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
      margin-top: ${vwTablet(16)};
      line-height: 1.79;
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(14)};
      margin-top: ${vw(16)};
    }
  }
`;
